let initialState = {
    userData: null,
    isLoggedIn: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USERDATA":
            return {
                ...state,
                userData:action.payload.userData
            };
        case "SET_LOGIN_STATUS":
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn
            };
        default:
            return state;
    }
    }
