import React from "react";
import "./index.scss";

const Spinner = React.forwardRef((props, ref) => {
    return <div {...props} ref={ref} className={`customComponents__spinner ${props.className ? props.className : ""}`}>
        <img src='/images/Spinner.png' alt="" style={{
            width: props.spinnerWidth ?? null,
            height: props.spinnerHeight ?? null,
            position: props.spinnerPosition ?? null,
            margin: props.spinnerMargin ?? null
        }}/>
    </div>
});

export default Spinner