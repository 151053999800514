import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import { countries, countries as CountryList } from '../../modules/countryModule';
import * as timestampActions from "../../actions/timestampActions";

import CustomInput from '../../components/customComponents/CustomInput'
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";
import DropBox from "../../components/customComponents/DropBox";
import Spinner from "../../components/customComponents/Spinner";

const Profile = () => {
    const parentRef = React.useRef();
    const [selectedSetting, setSelectedSetting] = React.useState("3-1");

    const advertiserInfo = useSelector(state => state?.userData?.userData?.AdvertiserInfo);
    const preferredPaymentMethod = useSelector(state => state?.userData?.userData?.UserInfo?.PaymentMethod);
    const settingsDispatch = useDispatch();

    const animateSettingsChange = (change) => {
        if (change === selectedSetting) return;
        if (!parentRef?.current) {
            setSelectedSetting(change);
        } else {
            let parentComputed = getComputedStyle(parentRef.current);
            parentRef.current.animate([
                {maxHeight: parentComputed.maxHeight},
                {maxHeight: "0px"}
            ],{
                duration: 300,
                iterations: 1,
                fill: "both", 
                easing: "ease-in-out"
            }).onfinish = () => {
                setSelectedSetting(change);
                if (change === "0") {
                    settingsDispatch(timestampActions.updateTimestamp());
                };
            };
        };
    };

    const toggleMobileOpen = (inp) => {
        if (inp === selectedSetting) return;
        let tmp = document.querySelector(".routes__profile__settingsSelectorMobile");
        if (tmp) tmp.classList.toggle("routes__profile__settingsSelectorMobile--active");
    };

    const selectPaymentMethod = () => {
        if (preferredPaymentMethod === "Wire") return animateSettingsChange("3-1")
        if (preferredPaymentMethod === "Paypal") return animateSettingsChange("3-2")
        return animateSettingsChange("3-1");
    };

    return <div className="routes__profile">
        <div className="routes__profile__settingsSelector">
            <p className="routes__profile__settingsSelector__setting">
                <div className="routes__profile__settingsSelector__setting__wrap" onClick={() => animateSettingsChange("1")}>
                    <img src="/images/settingsWhite.png" />
                    <span className={`routes__profile__settingsSelector__setting__wrap__activeText ${selectedSetting === "1" ? "routes__profile__settingsSelector__setting__wrap__activeText--active" : ""}`}>General settings</span>
                </div>
            </p>
            <p className="routes__profile__settingsSelector__setting">
                <div className="routes__profile__settingsSelector__setting__wrap" onClick={() => animateSettingsChange("2")}>
                    <img src="/images/passwordWhite.png" />
                    <span className={`routes__profile__settingsSelector__setting__wrap__activeText ${selectedSetting === "2" ? "routes__profile__settingsSelector__setting__wrap__activeText--active" : ""}`}>Change password</span>
                </div>
            </p>
            {advertiserInfo && <p className="routes__profile__settingsSelector__setting">
                <div className="routes__profile__settingsSelector__setting__wrap" onClick={() => animateSettingsChange("4")}>
                    <img src="/images/usericondark.png" />
                    <span className={`routes__profile__settingsSelector__setting__wrap__activeText ${selectedSetting === "4" ? "routes__profile__settingsSelector__setting__wrap__activeText--active" : ""}`}>Advertiser settings</span>
                </div>
            </p>}
            <p className="routes__profile__settingsSelector__setting" data-expandable={true} data-expanded={selectedSetting.startsWith("3-")}>
                <div className={"routes__profile__settingsSelector__setting__wrap"} onClick={() => !selectedSetting.startsWith("3-") && selectPaymentMethod()}>
                    <img src="/images/paymentWhite.png" />
                    <span>Payment</span>
                    <img src="/images/settingsArrowWhite.png" />
                </div>
                <div className={"routes__profile__settingsSelector__setting__expand"}>
                    <p className={`routes__profile__settingsSelector__setting__wrap__activeText ${selectedSetting === "3-1" ? "routes__profile__settingsSelector__setting__wrap__activeText--active" : ""}`} onClick={() => animateSettingsChange("3-1")}>Wire</p>
                    <p className={`routes__profile__settingsSelector__setting__wrap__activeText ${selectedSetting === "3-2" ? "routes__profile__settingsSelector__setting__wrap__activeText--active" : ""}`} onClick={() => animateSettingsChange("3-2")}>Paypal</p>
                </div>
            </p>
        </div>
        <div className="routes__profile__settingsSelectorMobile" onClick={e => e.target.classList.toggle("routes__profile__settingsSelectorMobile--active")}>
            <p>{(()=>{
                switch (selectedSetting) {
                    case "1": return <>General settings</>;
                    case "2": return <>Change password</>;
                    case "4": return <>Advertiser settings</>;
                    case "3-1": return <>Payment &gt; <span>Wire</span></>;
                    case "3-2": return <>Payment &gt; <span>Paypal</span></>;
                    default: return "Unknown";
                };
            })()}</p><img src="/images/settingsArrowWhite.png" />
        </div>
        <div className="routes__profile__settingsSelectorMobile__settings">
            <p onClick={() => {animateSettingsChange("1") || toggleMobileOpen("1")}} className={`${selectedSetting === "1" ? "routes__profile__settingsSelectorMobile__settings--active" : ""}`}>General settings</p>
            <p onClick={() => animateSettingsChange("2") || toggleMobileOpen("2")} className={`${selectedSetting === "2" ? "routes__profile__settingsSelectorMobile__settings--active" : ""}`}>Change password</p>
            <p onClick={() => animateSettingsChange("4") || toggleMobileOpen("4")} className={`${selectedSetting === "4" ? "routes__profile__settingsSelectorMobile__settings--active" : ""}`}>Advertiser settings</p>
            <p onClick={() => animateSettingsChange("3-1") || toggleMobileOpen("3-1")} className={`${selectedSetting === "3-1" ? "routes__profile__settingsSelectorMobile__settings--active" : ""}`}>Payment &gt; <span>Wire</span></p>
            <p onClick={() => animateSettingsChange("3-2") || toggleMobileOpen("3-2")} className={`${selectedSetting === "3-2" ? "routes__profile__settingsSelectorMobile__settings--active" : ""}`}>Payment &gt; <span>Paypal</span></p>
        </div>
        <div className="routes__profile__settingInfo" ref={parentRef}>
            {(() => {
                switch (selectedSetting) {
                    case "0": return <SelectSetting key="0" parent={parentRef} onFinish={() => null} />
                    case "1": return <GeneralSettings key="1" parent={parentRef} onFinish={() => animateSettingsChange("0")} />
                    case "2": return <ChangePasswordSettings key="2" parent={parentRef} onFinish={() => animateSettingsChange("0")} />
                    case "3-1": return <WireSettings key="3-2" parent={parentRef} onFinish={() => animateSettingsChange("0")} />
                    case "3-2": return <PaypalSettings key="3-3" parent={parentRef} onFinish={() => animateSettingsChange("0")} />
                    case "4": return <AdvertiserSettings key="4" parent={parentRef} onFinish={() => animateSettingsChange("0")} advertiserInfo={advertiserInfo} />
                    default: return null;
                };
            })()}
        </div>
    </div>
};

export default Profile;


const SelectSetting = (props) => {
    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__passwordSettings" style={{
        fontFamily: "Montserrat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    }}>
        <p>Select a setting to get started!</p>
    </div>
};

const GeneralSettings = (props) => {
    const [userData, setUserData] = React.useState(null);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [selectedChatService, setSelectedChatService] = React.useState(0);
    const [curChatService, setCurChatService] = React.useState({});
    const [infoP, setInfoP] = React.useState("");

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID);

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const zipRef = React.useRef();
    const contactRef = React.useRef();

    const dp = DropBox();

    React.useEffect(() => {
        if (!userID) return setUserData({status: "error", data: "NO_ID"});
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSingleUser`,
            data: {
                ID: userID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setUserData(res.data);
        }).catch(() => {
            setUserData({status: "error", data: "SERVER_ERROR"});
        });
    }, []);

    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    React.useEffect(() => {
        if (!userData?.data) return;
        let final = {
            ContactSkype: "",
            ContactWhatsapp: "",
            ContactTelegram: ""
        };
        let selected = false;

        if (userData.data.ContactSkype) {
            if (!selected) {
                setSelectedChatService(1);
                selected = true;
            };
            final.ContactSkype = userData.data.ContactSkype;
        };
        if (userData.data.ContactTelegram) {
            if (!selected) {
                setSelectedChatService(2);
                selected = true;
            };
            final.ContactTelegram = userData.data.ContactTelegram;
        };
        if (userData.data.ContactWhatsapp) {
            if (!selected) {
                setSelectedChatService(3);
                selected = true;
            };
            final.ContactWhatsapp = userData.data.ContactWhatsapp;
        };
        setCurChatService(final);
    }, [userData]);

    React.useEffect(() => {
        if (!contactRef.current) return;
        switch (selectedChatService) {
            case 1: return contactRef.current.value = curChatService.ContactSkype;
            case 2: return contactRef.current.value = curChatService.ContactTelegram;
            case 3: return contactRef.current.value = curChatService.ContactWhatsapp;
            default: return contactRef.current.value = "";
        };
    }, [selectedChatService]);

    const editUser = () => {
        setInfoP("");
        const curCountry = dp.getSelectedItem().value;

        if (!firstNameRef.current.value) {
            return setInfoP("First name can't be empty!");
        };
        if (!lastNameRef.current.value) {
            return setInfoP("Last name can't be empty!");
        };
        if (!zipRef.current.value) {
            return setInfoP("ZIP code can't be empty!");
        };
        if (isNaN(Number(zipRef.current.value))) {
            return setInfoP("ZIP must be a number!");
        };

        setShowSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: userID,
                FirstName: firstNameRef.current.value,
                LastName: lastNameRef.current.value,
                ZIP: Number(zipRef.current.value),
                Country: curCountry,
                ...curChatService
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("There was an error while editing user!");
            } else if (res.data.status === "ok") {
                if (props.onFinish) props.onFinish();
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setShowSpinner(false);
        });
    };

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__generalSettings">
        <Spinner style={{
            opacity: (!userData || showSpinner === true) ? 1 : 0
        }} />
        {userData && userData?.status === "ok" ? <>
            <CustomInput ref={firstNameRef} defaultValue={userData.data.FirstName} style={{width: "100%"}} placeholder1="First name" />    
            <CustomInput ref={lastNameRef} defaultValue={userData.data.LastName} style={{width: "100%"}} placeholder1="Last name" />    
            <div className="routes__profile__settingInfo__generalSettings__country">
                <span>Country</span>
                <dp.DropBox data={countries.map(elem => {
                    return {name: elem.name, value: elem.code, image: `/images/flags/${elem.code.toLowerCase()}.png`}
                })} selected={countries.map(item => item.code).indexOf(userData.data.Country)} />
            </div>
            <CustomInput ref={zipRef} defaultValue={userData.data.ZIP} style={{width: "100%"}} placeholder1="ZIP code" />    
            <div className="routes__profile__settingInfo__generalSettings__images">
                <img onClick={() => setSelectedChatService(1)} src="/images/headerManager_skype.png" className={`routes__profile__settingInfo__generalSettings__images__image ${selectedChatService === 1 ? "routes__profile__settingInfo__generalSettings__images__image--active" : ""}`} />
                <img onClick={() => setSelectedChatService(2)} src="/images/headerManager_telegram.png" className={`routes__profile__settingInfo__generalSettings__images__image ${selectedChatService === 2 ? "routes__profile__settingInfo__generalSettings__images__image--active" : ""}`} />
                <img onClick={() => setSelectedChatService(3)} src="/images/headerManager_whatsapp.png" className={`routes__profile__settingInfo__generalSettings__images__image ${selectedChatService === 3 ? "routes__profile__settingInfo__generalSettings__images__image--active" : ""}`} />
            </div>
            {selectedChatService > 0 && <CustomInput onChange={(e) => {
                let curContact = "";
                if (selectedChatService === 1) {
                    curContact = "ContactSkype";
                } else if (selectedChatService === 2) {
                    curContact = "ContactTelegram";
                } else if (selectedChatService === 3) {
                    curContact = "ContactWhatsapp";
                };
                if (curContact) {
                    setCurChatService({
                        ...curChatService,
                        [curContact]: e.target.value
                    });
                };
            }} ref={contactRef} style={{width: "100%"}} placeholder1="Username (optional)" />}
            <CustomButtonSmall style={{
                backgroundColor: "#00A3FF",
                color: "white",
                width: "170px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "16px"
            }} onClick={() => {
                editUser();
            }} className="routes__profile__settingInfo__generalSettings__saveBtn" value="Save changes" />
            {infoP && <p className="routes__profile__settingInfo__generalSettings__infoP">{infoP}</p>}
        </> : <>
            <p>There was an error while getting data</p>
        </>}
    </div>
};

const ChangePasswordSettings = (props) => {
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID);

    const passwordRef = React.useRef();
    const newPasswordRef = React.useRef();
    const confirmNewPasswordRef = React.useRef();

    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    const changePassword = () => {
        setInfoP("");
        if (!passwordRef.current || !newPasswordRef.current || !confirmNewPasswordRef.current) {
            return setInfoP("An unknown error occured!");
        };
        if (passwordRef.current.value.length === 0) {
            return setInfoP("Old password not entered!");
        }
        if (newPasswordRef.current.value.length < 8 || newPasswordRef.current.value.length > 64) {
            return setInfoP("New password must be between 8 and 64 characters!");
        };
        if (newPasswordRef.current.value !== confirmNewPasswordRef.current.value) {
            return setInfoP("New password doesn't match with confirm password!");
        };

        setShowSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                id: userID,
                oldPassword: passwordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Old password was incorrect!");
            } else if (res.data.status === "ok") {
                if (props.onFinish) props.onFinish();
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setShowSpinner(false);
        });
    };

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__passwordSettings">
        <Spinner style={{
            opacity: showSpinner === true ? 1 : 0
        }} />
        <CustomInput type="password" ref={passwordRef} placeholder1="Old password" style={{width: "100%"}} />
        <CustomInput type="password" ref={newPasswordRef} placeholder1="New password" style={{width: "100%"}} />
        <CustomInput type="password" ref={confirmNewPasswordRef} placeholder1="Confirm new password" style={{width: "100%"}} />
        <CustomButtonSmall value="Change password" style={{
            backgroundColor: "#00A3FF",
            color: "white",
            width: "170px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            margin: "0 auto"
        }} onClick={() => changePassword()} />
        {infoP && <p className="routes__profile__settingInfo__passwordSettings__infoP">{infoP}</p>}
    </div>
};

const AdvertiserSettings = (props) => {
    const [userData, setUserData] = React.useState({});
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const companyNameRef = React.useRef();
    const companyAddressRef = React.useRef();
    const companyCityRef = React.useRef();
    const companyZipCodeRef = React.useRef();
    const companyTaxRef = React.useRef();
    const companyVatRef = React.useRef();

    React.useEffect(() => {
        if (!props.advertiserInfo) return;
        let taxVatObj = {
            Tax: "",
            Vat: ""
        };
        try {
            taxVatObj = JSON.parse(props.advertiserInfo?.TaxVatNumber);
        } catch {};
        setUserData({
            CompanyName: props.advertiserInfo.Company,
            CompanyAddress: props.advertiserInfo.CompanyAddress,
            CompanyCity: props.advertiserInfo.CompanyCity,
            CompanyZipCode: props.advertiserInfo.CompanyZipCode,
            TaxVatNumber: taxVatObj
        });
    }, [props.advertiserInfo]);

    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    const editAdvertiser = () => {
        setInfoP("");

        if (!companyNameRef.current.value) {
            return setInfoP("Company name can't be empty");
        };
        if (!companyAddressRef.current.value) {
            return setInfoP("Company address can't be empty!");
        };
        if (!companyCityRef.current.value) {
            return setInfoP("Company city can't be empty!");
        };
        if (isNaN(Number(companyZipCodeRef.current.value))) {
            return setInfoP("Company ZIP code can't be empty!");
        };
        if (!companyTaxRef.current.value) {
            return setInfoP("Company TAX number can't be empty!");
        };
        if (!companyVatRef.current.value) {
            return setInfoP("Company VAT number can't be empty!");
        };

        setShowSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/editAdvertiser`,
            data: {
                ID: props.advertiserInfo.ID,
                CompanyName: companyNameRef.current.value,
                CompanyAddress: companyAddressRef.current.value,
                CompanyCity: companyCityRef.current.value,
                CompanyZipCode: Number(companyZipCodeRef.current.value),
                TaxVatNumber: JSON.stringify({Tax: companyTaxRef.current.value, Vat: companyVatRef.current.value})
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Failed to edit advertiser, check your info and try again.");
            } else if (res.data.status === "ok") {
                if (props.onFinish) props.onFinish();
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setShowSpinner(false);
        });
    };

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__advertiserSettings">
        <Spinner style={{
            opacity: showSpinner === true ? 1 : 0
        }} />
        <CustomInput ref={companyNameRef} placeholder1="Company name" defaultValue={userData.CompanyName ?? ""} style={{width: "100%"}} />
        <CustomInput ref={companyAddressRef} placeholder1="Company address" defaultValue={userData.CompanyAddress ?? ""} style={{width: "100%"}} />
        <CustomInput ref={companyCityRef} placeholder1="Company city" defaultValue={userData.CompanyCity ?? ""} style={{width: "100%"}} />
        <CustomInput ref={companyZipCodeRef} placeholder1="Company ZIP code"defaultValue={userData.CompanyZipCode ?? ""} style={{width: "100%"}} />
        <CustomInput ref={companyTaxRef} placeholder1="Company TAX number" defaultValue={userData.TaxVatNumber?.Tax ?? ""} style={{width: "100%"}} />
        <CustomInput ref={companyVatRef} placeholder1="Company VAT nuber" defaultValue={userData.TaxVatNumber?.Vat ?? ""} style={{width: "100%"}} />
        <CustomButtonSmall value="Save changes" style={{
            backgroundColor: "#00A3FF",
            color: "white",
            width: "170px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            margin: "0 auto"
        }} onClick={() => editAdvertiser()} />
        {infoP && <p className="routes__profile__settingInfo__advertiserSettings__infoP">{infoP}</p>}
    </div>
};

const PaypalSettings = (props) => {
    const [userData, setUserData] = React.useState();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID);

    const ownerRef = React.useRef();
    const emailRef = React.useRef();

    React.useEffect(() => {
        if (!userID) return setUserData({status: "error", data: "NO_ID"});
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSingleUser`,
            data: {
                ID: userID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            let pm = {};
            try {
                pm = JSON.parse(res.data.data?.PaymentMethod);
            } catch {};
            if (pm.type !== "Paypal") pm.type = {};
            setUserData({status: "ok", data: pm});
        }).catch(() => {
            setUserData({status: "error", data: "SERVER_ERROR"});
        });
    }, []);

    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    const setPaymentInfo = () => {
        setInfoP("");
        if (!ownerRef.current.value) {
            return setInfoP("Owner name can't be empty");
        };
        if (!emailRef.current.value) {
            return setInfoP("Email can't be empty!");
        };

        setShowSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: userID,
                PaymentMethod: JSON.stringify({
                    type: "Paypal",
                    ["Owner name"]: ownerRef.current.value,
                    ["E-Mail"]: emailRef.current.value
                })
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Failed to change payment method, check your info and try again.");
            } else if (res.data.status === "ok") {
                if (props.onFinish) props.onFinish();
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setShowSpinner(false);
        });
    };

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__paypalSettings">
        <Spinner style={{
            opacity: (showSpinner || !userData) === true ? 1 : 0
        }} />
        {userData && userData.status === "ok" ? <>
            <CustomInput ref={ownerRef} placeholder1="Owner name" defaultValue={userData.data["Owner name"]} style={{width: "100%"}} />
            <CustomInput ref={emailRef} placeholder1="E-Mail" defaultValue={userData.data["E-Mail"]} style={{width: "100%"}} />
            <CustomButtonSmall value="Change payment method" style={{
                backgroundColor: "#00A3FF",
                color: "white",
                width: "250px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "16px",
                margin: "0 auto"
            }} onClick={() => setPaymentInfo()} />
            {infoP && <p className="routes__profile__settingInfo__paypalSettings__infoP">{infoP}</p>}
        </> : null}
    </div>
};

const WireSettings = (props) => {
    const [userData, setUserData] = React.useState();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const userID = useSelector(state => state?.userData?.userData?.UserInfo?.ID);
    const dp = DropBox();

    const bankNameRef = React.useRef();
    const accountHolderRef = React.useRef();
    const ibanRef = React.useRef();
    const switftRef = React.useRef();

    React.useEffect(() => {
        if (!userID) return setUserData({status: "error", data: "NO_ID"});
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSingleUser`,
            data: {
                ID: userID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            let pm = {};
            try {
                pm = JSON.parse(res.data.data?.PaymentMethod);
            } catch {};
            if (pm.type !== "Wire") pm.type = {};
            setUserData({status: "ok", data: pm});
        }).catch(() => {
            setUserData({status: "error", data: "SERVER_ERROR"});
        });
    }, []);

    React.useEffect(() => {
        if (!props.parent?.current) return;
        let parentComputed = getComputedStyle(props.parent.current);
        props.parent.current.animate([
            {maxHeight: parentComputed.maxHeight},
            {maxHeight: "600px"}
        ],{
            duration: 300,
            iterations: 1,
            fill: "both", 
            easing: "ease-in-out"
        });
    }, [props.parent]);

    const setPaymentInfo = () => {
        let curCountry = dp.getSelectedItem()?.value;
        setInfoP("");
        if (!bankNameRef.current.value) {
            return setInfoP("Bank name can't be empty!");
        };
        if (!accountHolderRef.current.value) {
            return setInfoP("Account holder can't be empty!");
        };
        if (!ibanRef.current.value) {
            return setInfoP("IBAN can't be empty!");
        };
        if (!switftRef.current.value) {
            return setInfoP("SWIFT can't be empty!");
        };
        if (!curCountry) {
            return setInfoP("Country can't be empty!");
        };

        setShowSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: userID,
                PaymentMethod: JSON.stringify({
                    type: "Wire",
                    ["Bank name"]: bankNameRef.current.value,
                    ["Account holder"]: accountHolderRef.current.value,
                    ["IBAN"]: ibanRef.current.value,
                    ["SWIFT"]: switftRef.current.value,
                    ["Country"]: curCountry,
                })
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Failed to change payment method, check your info and try again.");
            } else if (res.data.status === "ok") {
                if (props.onFinish) props.onFinish();
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setShowSpinner(false);
        });
    };

    return <div className="routes__profile__settingInfo__wrap routes__profile__settingInfo__wireSettings">
        <Spinner style={{
            opacity: (showSpinner || !userData) === true ? 1 : 0
        }} />
        {userData && userData.status === "ok" ? <>
            <CustomInput ref={bankNameRef} placeholder1="Bank name" defaultValue={userData.data["Bank name"]} style={{width: "100%"}} />
            <CustomInput ref={accountHolderRef} placeholder1="Account holder" defaultValue={userData.data["Account holder"]} style={{width: "100%"}} />
            <div className="routes__profile__settingInfo__wireSettings__country">
                <span>Country</span>
                <dp.DropBox data={countries.map(elem => {
                    return {name: elem.name, value: elem.code, image: `/images/flags/${elem.code.toLowerCase()}.png`}
                })} selected={countries.map(item => item.code).indexOf(userData.data["Country"])} />
            </div>
            <CustomInput ref={ibanRef} placeholder1="IBAN" defaultValue={userData.data["IBAN"]} style={{width: "100%"}} />
            <CustomInput ref={switftRef} placeholder1="SWIFT" defaultValue={userData.data["SWIFT"]} style={{width: "100%"}} />
            <CustomButtonSmall value="Change payment method" style={{
                backgroundColor: "#00A3FF",
                color: "white",
                width: "250px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "16px",
                margin: "0 auto"
            }} onClick={() => setPaymentInfo()} />
            {infoP && <p className="routes__profile__settingInfo__wireSettings__infoP">{infoP}</p>}
        </> : null}
    </div>
};