import React from "react";
import "./index.scss";

const Hamburger = React.forwardRef((props, ref) => {
    let hamby = true
    const change = () => {
        let div1 = document.querySelector('.div1')
        let div2 = document.querySelector('.div2')
        let div3 = document.querySelector('.div3')
        let hamburger = document.querySelector('.customComponents__hamburger')
        let sidebar = document.querySelector('.component__sidebar')
        let dashboard = document.querySelector('.dashboard')
        let header = document.querySelector('.component__headerLoged')
        if (hamby === true) {
            div2.style.opacity = '0'
            div1.style.transform = 'rotate(40deg)'
            div3.style.transform = 'rotate(-40deg)'
            div1.style.height = '3px'
            div3.style.height = '3px'
            div1.style.top = '9px'
            div3.style.bottom = '8px'
            sidebar.style.width = '250px'
            sidebar.style.padding = '40px'
            
            if (window.innerWidth > 1024) {
                hamburger.style.left = '290px'
                dashboard.style.paddingLeft = '270px'
                header.style.paddingLeft = '270px'
            }
            hamby = false
        } else {
            div2.style.opacity = '1'
            div1.style.transform = 'rotate(0)'
            div3.style.transform = 'rotate(0)'
            div1.style.height = '2px'
            div3.style.height = '2px'
            div1.style.top = '0'
            div3.style.bottom = '0'
            sidebar.style.width = '0'
            sidebar.style.padding = '0'

            if (window.innerWidth > 1024) {
                dashboard.style.paddingLeft = '10px'
                hamburger.style.left = '20px'
                header.style.paddingLeft = '20px'
            }
            hamby = true
        }
    }
    return <div className={`customComponents__hamburger ${props.className ? props.className : ""}`} {...props} onClick={() => { change() }}>
        <div className="customComponents__hamburger__div div1"></div>
        <div className="customComponents__hamburger__div div2"></div>
        <div className="customComponents__hamburger__div div3"></div>
    </div>
});

export default Hamburger