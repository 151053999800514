export const setUserData = (userData) => {
    return{
        type:"SET_USERDATA",
        payload:{
            userData
        }
    }
}

export const setLoginStatus = (isLoggedIn) => {
    return{
        type:"SET_LOGIN_STATUS",
        payload:{
            isLoggedIn
        }
    }
}