
import "./index.scss";
import Button from '../../../../components/customComponents/Button'
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import { CustomTable, FilteredCustomTable } from "../../../../components/customComponents/Table";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from '../../../../modules/backendModule'
import CustomDropBox from '../../../../components/customComponents/DropBox'
import AnimateModule from '../../../../modules/animateModule'
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../../../components/customComponents/CustomInput";
import FilterPanel from "../../../../components/customComponents/FilterPanel";
import { countries } from "../../../../modules/countryModule";
import moment from "moment";

const OffersPublisher = () => {
    const [filters, updateFilters] = React.useState([]);
    const [filters2, updateFilters2] = React.useState([]);
    const [addFilter, setAddFilter] = React.useState(() => null);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    const navigate = useNavigate();
    let timestamp = useSelector((state) => state.timeStamp);
    let userData = useSelector((state) => state.userData)
    const [takenOffers, setTakenOffers] = React.useState(null)
    const [allOffers, setAllOffers] = React.useState(null)
    const [visible, setVisible] = React.useState()
    const [domains, setDomains] = React.useState()
    const [offerForDelete, setOfferForDelete] = React.useState([])
    const [showPromotedOffers, setShowPromotedOffers] = React.useState(true);
    

    let curPagination = React.useRef();
    React.useEffect(() => {
        if (localStorage.getItem('taken') === '1') {
            setVisible('taken')
        } else {
            setVisible('all')
        }
        setTimeout(() => {
            localStorage.setItem('taken', 0)
        })
    }, [])
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const getTakenOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/getAllTakenOffers`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID,
                Filters: filters2
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok')
                setTakenOffers({ status: "ok", data: e.data.data, timestamp: Date.now() });

        }).catch((e) => {
        })
    }
    const getDomains = () => {
        let domainsTemp = []
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getDomains`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID,
                showInternal: true
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            e.data.data.map(item => {
                domainsTemp.push({ name: item.DomainName, value: item.ID })
            })
            setDomains(domainsTemp)

        }).catch((e) => {
        })
    }
    const continueOffer = () => {
        if (curPagination.current === -1) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllSelectableOffers`,
            data: {
                paginationOffset: curPagination.current - (allOffers?.data?.reduce((acc, val) => {
                    return acc + (val?.isPromoted ? 1 : 0)
                }, 0)) ?? 0,
                BoundUser: userData.userData?.UserInfo?.ID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setAllOffers({ status: "ok", data: [...allOffers.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        })
    };

    const getAllOffers = () => {
        setAllOffers(null);
        curPagination.current = 0
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllSelectableOffers`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters,
                showNewOffers: showPromotedOffers
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok')
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
            setAllOffers({ status: "ok", data: res.data.data.DBData });

        }).catch(() => {
        })
    }
    const PaginationOffer = () => {
        let tmpRef = React.useRef();
        let gTimeout = React.useRef();
        let [firstTime, setFirstTime] = React.useState(true);
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        continueOffer()
                    };
                });
            }, {
                threshold: [1.0],
                trackVisibiity: true,
                delay: 500
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        React.useEffect(() => {
            clearTimeout(gTimeout.current);
            if (firstTime === true) gTimeout.current = setTimeout(() => {
                setFirstTime(false);
            }, 500);
        });

        return <div ref={tmpRef} style={{
            display: firstTime ? "none" : null,
            position: "absolute",
            bottom: "0"
        }}></div>
    };
    React.useEffect(() => {
        getAllOffers()
        getDomains()
    }, [timestamp, filters, showPromotedOffers])
    React.useEffect(() => {
        getTakenOffers();
    }, [timestamp, filters2]);
    const changeVisible = () => {
        if (visible === 'taken') {
            setVisible('all')
        } else {
            setVisible('taken')
        }
    }
    const openDeleteModal = (name, ID) => {
        let modal = document.querySelector('.publisherOffers__deleteModal')
        if (name === undefined) {
            modal.style.opacity = 0
            modal.style.zIndex = -50
            return
        }
        setOfferForDelete([name, ID])
        modal.style.opacity = 1
        modal.style.zIndex = 50

    }
    const deleteOffer = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/deleteTakenOffer`,
            data: {
                authUser: userData.userData?.UserInfo?.ID,
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                getTakenOffers()
            }
        }).catch((e) => {
        })
    }
    const generateLink = (ID, index) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/generateOfferLink`,
            data: {
                authUser: userData.userData?.UserInfo?.ID,
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                document.getElementById(`linkContainer${index}`).innerHTML = e.data.data
            }
        }).catch((e) => {
        })
    }


    return <>{userData.userData?.UserInfo?.Flags?.isVerified === true ? <div className="publisherOffers">
        <p className="publisherOffers__copymsg">Link succesfully copied to clipboard</p>
        <button className="publisherOffers__button" onClick={() => changeVisible()} style={{ marginBottom: '40px' }} >{visible === 'taken' ? 'Take offer' : 'Taken offers'}</button>

        {visible === 'taken' ? <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters2(data)}
            headers={["Custom name", "Offer name", "Date taken"]}
            style={{ marginTop: '20px' }}
            data={(()=>{
                let alltakenOffers = takenOffers?.data?.map((item, index) => {
                    return [
                        { keyID: item.ID, type: "text", text: item.FriendlyName ?? "?" },
                        { keyID: item.ID, type: "text", text: item.TakenOfferName },
                        { keyID: item.ID, type: "text", text: new Date(item.createdAt).toLocaleDateString() },
                        { keyID: item.ID, type: "groupNewline", group: [
                            {
                                keyID: item.ID, type: "button", text: 'Generate link', triggerDropdown: true, triggerData: (c) => {
                                    return <div>
                                        {generateLink(item.ID, index)}
                                        <p id={`linkContainer${index}`} ></p>
                                    </div>
                                }
                            },
                            {
                                keyID: item.ID, type: "button", text: 'Delete', triggerDropdown: true, triggerData: (c) => {
                                    return <div className="offersDelete">
                                        <p>Are you sure you want to delete this offer?</p>
                                        <div>
                                            <CustomButtonSmall value='Confirm' style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} onClick={() => deleteOffer(item.ID)} />
                                            <CustomButtonSmall value='Cancel' onClick={() => c()} />
                                        </div>
                                    </div>
                                }
                            }
                        ]}
                    ]
                });
                if (!Array.isArray(alltakenOffers)) alltakenOffers = [];
                if (alltakenOffers.length === 0) alltakenOffers.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])
                return alltakenOffers
            })()}
            filters={[
                { name: "TakenOfferName", friendlyName: "Offer name", type: "string" },
                { name: "createdAt", type: "date", friendlyName: "Date" }
            ]}
        /> :
            <div className="publisherOffers__offersContainerInline" >
                <FilterPanel filterCB={data => updateFilters(data)} addFilter={cb => setAddFilter(cb)} key="filterpanelKey" filters={[
                    { name: "OfferName", type: "string", friendlyName: "Offer name" },
                    { name: "OfferProductName", type: "string", friendlyName: "Product name" },
                    { name: "OfferMonthlyConversionRatio", type: "string", friendlyName: "Approve rate" },
                    {
                        name: "OfferCountry", type: "custom", data: countries.map(elem => {
                            return { value: elem.code, text: elem.name }
                        }), varType: "string", friendlyName: "Country"
                    },
                    { name: " OfferPayout", type: "number", friendlyName: "Payout" },
                    {
                        name: "OfferType", type: "custom", varType: "string", data: [
                            { text: "Cost per action (CPA)", value: "CPA" },
                            { text: "Cost per lead (CPL)", value: "CPL" }
                        ], friendlyName: "Offer type"
                    },
                    { name: "OfferDescription", type: "string", friendlyName: "Description" },
                    { name: "OfferProductPrice", type: "number", friendlyName: "Product price" },
                    { name: "OfferProductBrand", type: "string", friendlyName: "Product brand" },
                    { name: "createdAt", type: "date", friendlyName: "Date" }
                ]} className="dashboardPublisher--spanAll" />
                <div className="dashboardPublisher--spanAll" style={{ width: "100%" }}>
                    <CustomButtonSmall style={{ width: "auto", padding: "0px 10px" }} value={showPromotedOffers ? "Hide promoted offers" : "Show promoted offers"} onClick={() => setShowPromotedOffers(!showPromotedOffers)} />
                </div>
                <div className="publisherOffers__offersContainer">
                    {allOffers !== null ? allOffers?.data?.map(item => {
                        return <SingleOfferItem key={item.ID} item={item} animateNavigate={animateNavigate} />
                    }) : null}
                </div>
                {curPagination.current !== -1 ? <PaginationOffer /> : null}

            </div>
        }
    </div> : <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
        <p>Your account has not been verified</p>
    </div></div>}
    </>
};


const SingleOfferItem = (props) => {
    let singleOfferRef = React.useRef();

    React.useEffect(() => {
        if (!singleOfferRef.current) return;
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    try { observer.unobserve(singleOfferRef.current); } catch { };
                    entry.target.animate([
                        {opacity: getComputedStyle(entry.target).opacity},
                        {opacity: 1}
                    ], {
                        duration: 500,
                        iterations: 1,
                        fill: "both",
                        easing: "ease-in-out"
                    });
                };
            });
        }, {
            threshold: [0.5],
            trackVisibiity: true
        });
        observer.observe(singleOfferRef.current);
        return () => {
            if (singleOfferRef?.current) {
                try { observer.unobserve(singleOfferRef.current); } catch { };
            };
        };
    }, [singleOfferRef.current]);

    // props.item.OfferMonthlyConversionRatio === -1
    return <div ref={singleOfferRef} style={{opacity: 0}} key={props.item.ID} className="publisherOffers__offersContainer__singleOffer">
        <div className="publisherOffers__offersContainer__singleOffer__firstContainer">
            <div className="publisherOffers__offersContainer__singleOffer__firstContainer__img">
                <img src={props.item.OfferImages} alt='' onError={(e) => { e.target.onerror = null; e.target.src = "/images/slika.png" }} />
                <div>
                    <p style={{textAlign: "start", alignItems: "flex-start"}} className="publisherOffers__offersContainer__singleOffer__firstContainer__name">
                        {props.item.isPromoted && <span className="publisherOffers__offersContainer__singleOffer__firstContainer__name__promoted">Promoted</span>}
                        <span>{props.item.OfferProductName}</span>
                    </p>
                </div>
                <p className="publisherOffers__offersContainer__singleOffer__firstContainer__img__brand">{props.item.OfferProductBrand}</p>
                {moment().add(-7, "days").isBefore(props.item.createdAt) && <img src="/images/newOffer.png" className="publisherOffers__offersContainer__singleOffer__firstContainer__img__newOffer" />}
            </div>
            <div className="publisherOffers__offersContainer__singleOffer__firstContainer__info">

                <p> <span>Price</span> <br /> {props.item.OfferProductPrice}</p>
                <p> <span>Payout</span><br /> {props.item.OfferPayout.toFixed(2)}$</p>
                {/* <p><span>Approve rate</span><br /> {props.item.OfferMonthlyConversionRatio === -1 ? '?' : `${Number(props.item.OfferMonthlyConversionRatio).toFixed(0)} %`}</p> */}
                <p><span>Offer type</span><br /> ({props.item.OfferType === 'CPA' ? 'Cost per action' : props.item.OfferType === 'CPM' ? 'Cost per mile' : 'Cost per click'})</p>
                <p><span>Country</span><br /><div className="publisherOffers__offersContainer__singleOffer__firstContainer__info__tooltip">
                    <img src={`/images/flags/${props.item.OfferCountry[0].replace(/"/g, '').toLowerCase()}.png`} style={{ position: 'relative', top: '3px' }} alt='Country' />
                    <span style={{ marginLeft: "5px" }}>{props.item.OfferCountry[0].replace(/"/g, "")}</span>
                    <div className="publisherOffers__offersContainer__singleOffer__firstContainer__info__tooltip__container">
                        {(() => {
                            let curCountry = countries.find(c => c.code === props.item.OfferCountry[0].replace(/"/g, ''));
                            if (!curCountry) return "Unknown";
                            return curCountry.name;
                        })()}
                    </div>
                </div>&nbsp;</p>
                <button onClick={() => props.animateNavigate(`/dashboard/article/${props.item.ID}`)} >Info</button>
            </div>
        </div>
    </div>
};

export default OffersPublisher;