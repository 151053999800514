import React from "react";
import "./index.scss";

import * as backendModule from "../../../../modules/backendModule";
import axios from "axios";
import { animateBox } from "../../../../modules/componentAnimation";

import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";

const Logs = props => {
    const [addFilters, setAddFilters] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();
    const firstTime = React.useRef(true);

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/logs/getLogs`,
            data: {
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 100) {
                    paginationOffset.current += 100;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/logs/getLogs`,
            data: {
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 100) {
                    paginationOffset.current += 100;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch {};

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    const processLeadStatusCode = (logLevel) => {
        const stages = [
            "#39a8ef",
            "#FFF467",
            "#FF5752"
        ];
        let selected = 0;
        let fillLines = 0;
        logLevel = Number(logLevel);

        switch (logLevel) {
            case 0:
                selected = 0;
                break;
            case 1:
                selected = 1;
                fillLines = 1;
                break;
            case 2:
                selected = 2;
                fillLines = 2;
                break;
            case 3:
                selected = 3;
                fillLines = 3;
                break;
            default: break;
        }
        
        return <div className="route__adminPanel__logs__statusCodes__lines">
            {[1,2,3].map(elem => {
                return <span key={`leadStatusLine${elem}`} style={{
                    backgroundColor: fillLines >= elem ? (selected > 0 ? stages[selected-1] : "gray") : "gray"
                }}></span>
            })}
        </div>
    };

    React.useEffect(() => {
        if (!addFilters) return;
        
        if (firstTime.current) {
            firstTime.current = false;
            if (Array.isArray(props.filters)) {
                setTimeout(() => props.filters.forEach(addFilters), 0);
            } else {
                getData();
            };
        } else {
            getData();
        };

    }, [filters, addFilters]);

    return <div className="route__adminPanel__logs">
        <div className="route__adminPanel__logs__buttons">
            <CustomButtonSmall accent="#6664E5" value="Obriši sve logove" onClick={(e) => {
                animateBox(e, <RemoveLogs onChange={() => getData()} />)
            }} style={{width: "150px"}} />
            <CustomButtonSmall accent="#6664E5" value="Osvježi" onClick={getData} />
        </div>
        <FilteredCustomTable
            theme="dark"
            accent="#6664E5"
            headers={["ID", "Korisnik", "Log", "Datum", "Tip"]}
            filterCB={fi => setFilters(fi)}
            addFilter={addFn => setAddFilters(addFn)}
            filters={[
                {name: "Context", friendlyName: "Kontekst", type: "string"},
                {name: "UserID", friendlyName: "Korisnik", type: "string"},
                {name: "LogLevel", friendlyName: "Tip", type: "custom", varType: "number", data: [
                    {text: "info", value: 0},
                    {text: "warning", value: 1},
                    {text: "error", value: 2},
                    {text: "critical", value: 3}
                ]},
                {name: "LogDumpPresent", friendlyName: "Ima debug log", type: "boolean"}
            ]}
            data={(()=>{
                if (!data) return [[{keyID: "dataSpinner", type: "spinner"}]];
                if (data.status === "error") return [[{key: "dataInfoText", type: "custom", data: <p style={{textAlign: "center"}}>Došlo je do greške</p>}]];
                if (data.data.length === 0) return [[{key: "noDatatext", type: "custom", data: <p style={{textAlign: "center"}}>Nema podataka za prikaz</p>}]]

                let tmp = data.data.map(item => {
                    let additionalInfo = [];
                    let params = [];
                    let testData = item.Log.match(/(\(.*?\))/g);
                    if (testData) {
                        testData = testData.map(ts => {
                            return ts.substring(1, ts.length-1);
                        }).forEach(item => {
                            let workingItem = item.trim().split(",");
                            for (let paramData of workingItem) {
                                let arr = paramData.split(":");
                                if (arr.length <= 1) {
                                    additionalInfo.push(...workingItem);
                                    continue;
                                };
                                let name = arr.shift();
                                arr = arr.join(":");
                                params.push({name, value: arr});
                            };
                        });
                    };
                    return [
                        {keyID: String(item.ID), type: "text", text: item.ID},
                        {keyID: String(item.ID), type: "text", text: item.User, style:{width:"100%"}},
                        {keyID: String(item.ID), type: "text", text: item.Log.replace(/(\(.*?\))/g, ""), style:{width:"100%"}},
                        {keyID: String(item.ID), type: "text", text: new Date(item.createdAt).toLocaleString()},
                        {keyID: String(item.ID), type: "custom", data: <div className="route__adminPanel__logs__statusCodes">
                            <p>{item.LogLevelName}</p>
                            {processLeadStatusCode(item.LogLevel)}
                        </div>, style:{
                            width: "200px"
                        }},
                        {keyID: String(item.ID), type: "groupNewline", group: [
                            {keyID: String(item.ID), type: "custom", data: <div className={`route__adminPanel__logs__additionalInfo ${additionalInfo.length === 0 ? "route__adminPanel__logs__paramInfo--noData" : ""}`}>
                            <p>Dodatne infomacije</p>
                            {additionalInfo.length > 0 ? additionalInfo.map((pm, pi) => {
                                return <span key={`logsData-${pi}`}>{pm}</span>
                            }) : <span>Nema informacija</span>}
                        </div>}
                        ]},
                        {keyID: String(item.ID), type: "groupNewline", group: [
                            {keyID: String(item.ID), type: "custom", data: <div className={`route__adminPanel__logs__paramInfo ${params.length === 0 ? "route__adminPanel__logs__paramInfo--noData" : ""}`}>
                            <p>Parametri</p>
                            {params.length > 0 ? params.map((pm, pi) => {
                                return <span key={`logsData-${pi}`}>{pm.name}: {pm.value}</span>
                            }) : <span>Nema parametara</span>}
                        </div>}
                        ]},
                        {keyID: String(item.ID), type: "groupNewline", group: [
                            {keyID: String(item.ID), type: "custom", data: <div className="route__adminPanel__logs__additionalInfo route__adminPanel__logs__additionalInfo--context">
                                <p>Kontekst</p>
                                {item.Context.split("/").map((context, contextIndex) => {
                                    return <span onClick={e => {
                                        e.stopPropagation();
                                        const tmpContext = item.Context.split("/").filter((_,ti) => ti <= contextIndex).join("/");
                                        addFilters({
                                            ID: Date.now(),
                                            name: "Context",
                                            friendlyName: "Kontekst",
                                            type: "startsWith",
                                            value: `${tmpContext}${contextIndex === item.Context.split("/").length - 1 ? "" : "/"}`,
                                            varType: "string",
                                            filterType: "string"
                                        });
                                    }}>{context}</span>
                                })}
                            </div>}
                        ]},
                        {keyID: String(item.ID), type: "groupNewline", group: [
                            (item.LogDumpPresent && {keyID: String(item.ID), type: "button", text: "Prikaži debug log", onClick: e => {
                                animateBox(e, <LogDump ID={item.ID} />);
                            }})
                        ]}
                    ];
                });

                if (secondarySpinner) tmp.push([{keyID: "paginationSpinner", type: "spinner"}]);
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);

                return tmp;
            })()}
        />
    </div>
};

const LogDump = (props) => {
    const [data, setData] = React.useState();

    function syntaxHighlight(json) {
        try {
            if (typeof json != 'string') {
                json = JSON.stringify(json, undefined, 2);
           }
           json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
           return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
               var cls = 'number';
               if (/^"/.test(match)) {
                   if (/:$/.test(match)) {
                       cls = 'key';
                   } else {
                       cls = 'string';
                   }
               } else if (/true|false/.test(match)) {
                   cls = 'boolean';
               } else if (/null/.test(match)) {
                   cls = 'null';
               }
               return '<span class="' + cls + '">' + match + '</span>';
           });
        } catch {
            return json;
        };
    }

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/logs/getLogDump`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData({status: "ok", data: syntaxHighlight(res.data.data)});
            } else {
                setData({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    }, []);

    return <div className="route__adminPanel__logs__logDump">
        <CustomButtonSmall accent="#d82626" value="Nazad" onClick={() => props.onClose()} />

        <div className="route__adminPanel__logs__logDump__data">
            <div className="route__adminPanel__logs__logDump__data__spinner" style={{
                opacity: data ? 0 : 1,
                pointerEvents: data ? "none":  "all"
            }}>
                <Spinner style={{width:"64px", height:"64px"}} color="#6664E5" />
            </div>
            {data?.status === "ok" ? (data.data.includes("<span") ? <pre dangerouslySetInnerHTML={{__html: data.data}}></pre> : <pre>{data.data}</pre>) : <p>Došlo je do greške prilikom dohvatanja loga</p>}
        </div>
    </div>
};

const RemoveLogs = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeLogs = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/logs/removeAllLogs`,
            ...backendModule.axiosConfig
        }).then(() => {
            props.onClose();
            props.onChange();
        }).catch(() => {
            setInfoP("Generična greška prilikom brisanja logova");
        }).finally(() => {
            setSpinner(false);
        });
    };

    if (spinner) return <div className="route__adminPanel__logs__logDump" style={{
        width: "400px",
        height: "auto",
        minHeight: "150px",
        display: "block"
    }}>
        <Spinner style={{width:"64px", height:"64px"}} color="#6664E5" />
    </div>
    if (infoP) return <div className="route__adminPanel__logs__logDump" style={{
        width: "400px",
        height: "auto",
        minHeight: "150px",
        display: "block"
    }}>
        <h2 style={{marginBottom: "10px"}}>Došlo je do greške</h2>
        <p style={{marginBottom: "30px"}}>{infoP}</p>
        <CustomButtonSmall style={{margin: "0px 10px"}} accent="#d82626" value="Nazad" onClick={() => props.onClose()} />
    </div>
    return <div className="route__adminPanel__logs__logDump" style={{
        width: "400px",
        height: "auto",
        minHeight: "150px",
        display: "block"
    }}>
        <h2 style={{marginBottom: "30px"}}>Da li ste sigurni?</h2>
        <CustomButtonSmall style={{margin: "0px 10px"}} accent="#6664E5" value="Obriši" onClick={() => removeLogs()} />
        <CustomButtonSmall style={{margin: "0px 10px"}} accent="#d82626" value="Nazad" onClick={() => props.onClose()} />
    </div>
}

export default Logs;