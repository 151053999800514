import React from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { countries } from "../../../../modules/countryModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import { useSelector } from "react-redux";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropbox from "../../../../components/customComponents/DropBox";
import CustomRadio from "../../../../components/customComponents/Radio";
import Spinner from "../../../../components/customComponents/Spinner";

const AdminUsers = () => {
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [canPaginate, setCanPaginate] = React.useState(false);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();

    let isAdmin = useSelector(state => state?.userData?.userData?.UserInfo?.Flags?.isAdmin);

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const continueUsers = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const getUsers = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData });
            } else {
                setUserData({ status: "error", data: "There was an error while getting users" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const DisplayUserInfo = (props) => {
        let [aInfo, setAInfo] = React.useState(null);

        React.useEffect(() => {
            if (!props.user.AssignedManagerID) {
                setAInfo("No manager assigned");
            } else {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/users/getSingleUser`,
                    data: {
                        ID: props.user.AssignedManagerID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "error") {
                        setAInfo("Assigned manager not found");
                    } else {
                        setAInfo(`${res.data.data.FirstName} ${res.data.data.LastName}`);
                    }
                }).catch(() => {
                    setAInfo("Can't fetch assigned manager");
                });
            };
        }, []);
        let lastLocation = JSON.parse(props.user.LastKnownLocation);
        return <div className="route__adminUsers__userInfo">
            <p>UserID: {props.user.ID}</p>
            <p>Username: {props.user.Username}</p>
            <p>Email: {props.user.Email}</p>
            <p>First name: {props.user.FirstName}</p>
            <p>Last name: {props.user.LastName}</p>
            <p>Country: {props.user.Country} <img src={`/images/flags/${props.user.Country.toLowerCase()}.png`} /></p>
            <p>ZIP: {props.user.ZIP}</p>
            <p>Experience: {props.user.Experience} years</p>
            <p>Payment method: {props.user.PaymentMethod}</p>
            <p>Contact Whatsapp: {props.user.ContactWhatsapp}</p>
            <p>Contact Skype: {props.user.ContactSkype}</p>
            <p>Contact Telegram: {props.user.ContactTelegram}</p>
            <p>Assigned manager: {(() => {
                if (!aInfo) return <span><Spinner style={{ display: "inline" }} spinnerWidth="16px" spinnerHeight="16px" spinnerPosition="initial" /></span>
                if (aInfo) return <span>{aInfo}</span>
            })()}</p>
            <p>
                <span>Last known location: </span>
                <span>City: {lastLocation.city}, Country: {countries.find(item => item.code === lastLocation.country)?.name ?? "?"}</span>
            </p>
            <br />
            <p>Created at: {new Date(props.user.createdAt).toLocaleString()}</p>
            <br />
            <p>Flags:<hr /> {(() => {
                return Object.keys(props.user.Flags).map(elem => {
                    if (typeof props.user.Flags[elem] === "object") return null;
                    return <><span>
                        {elem}: <span style={{
                            color: (props.user.Flags[elem] === true || props.user.Flags[elem] === false) ? (props.user.Flags[elem] ? "green" : "red") : "white"
                        }}>{(() => {
                            if (props.user.Flags[elem] === true || props.user.Flags[elem] === false) {
                                return props.user.Flags[elem] ? "Yes" : "No"
                            } else return props.user.Flags[elem];
                        })()}</span>
                    </span><br /></>
                });
            })()}</p>
        </div>
    };

    const VerifyPublisher = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/verifyPublisher`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to verify publisher!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminUsers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminUsers__verifyPublisher">
            <p>This will verify the publisher and allow it to take offers</p>
            <p>Are you sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const EditUser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        let [curCountry, setCountry] = React.useState(null);
        let [autoPayoutInterval, setAutoPayoutInterval] = React.useState(props.user.AutoPayoutInterval ?? 0);
        let [fReferralEnabled, fSetReferralEnabled] = React.useState(props.user.Flags.isRefferalEnabled);
        let [fAdmin, setFAdmin] = React.useState(props.user.Flags.isAdmin);
        let [fManager, setFManager] = React.useState(props.user.Flags.isManager);
        let [pManager, setPManager] = React.useState(props.user.Flags.isPublisherManager);
        let [aManager, setAManager] = React.useState(props.user.Flags.isAdvertiserManager)
        let [fVerified, setFVerified] = React.useState(props.user.Flags.isVerified);
        let [fPublisherHidden, setFPublisherHidden] = React.useState(props.user.Flags.isPublisherHidden ?? false);
        let [fcrmInfoAllowed, setFCrmInfoAllowed] = React.useState(props.user.Flags.isCRMInfoAllowed ?? false);

        let firstNameRef = React.useRef();
        let lastNameRef = React.useRef();
        let emailRef = React.useRef();
        let PasswordRef = React.useRef();
        let ZIPRef = React.useRef();
        let ExperienceRef = React.useRef();
        let ContactWhatsappRef = React.useRef();
        let ContactSkypeRef = React.useRef();
        let ContactTelegramRef = React.useRef();
        let PaymentMethodRef = React.useRef();
        let ReferralPercentEarningsRef = React.useRef();
        let CountryDropbox = CustomDropbox();

        const getData = () => {
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/getSingleUser`,
                data: {
                    ID: props.ID,
                    showPayment: true
                },
                ...backendModule.axiosConfig
            }).then(async res => {
                if (res.data.status === "ok") {
                    setSpinner(false);
                    while (!firstNameRef.current) {
                        await new Promise(r2 => setTimeout(() => r2(), 50));
                    };
                    firstNameRef.current.value = res.data.data.FirstName;
                    lastNameRef.current.value = res.data.data.LastName;
                    emailRef.current.value = res.data.data.Email;
                    ZIPRef.current.value = res.data.data.ZIP;
                    ExperienceRef.current.value = res.data.data.Experience;
                    ContactWhatsappRef.current.value = res.data.data.ContactWhatsapp;
                    ContactSkypeRef.current.value = res.data.data.ContactSkype;
                    ContactTelegramRef.current.value = res.data.data.ContactTelegram;
                    PaymentMethodRef.current.value = res.data.data.PaymentMethod;
                    ReferralPercentEarningsRef.current.value = res.data.data.ReferralPercentEarnings

                    let cIndex = -1;
                    countries.forEach((elem, index) => {
                        if (elem.code === res.data.data.Country) cIndex = index;
                    });
                    if (cIndex !== -1) setCountry(cIndex);
                } else {
                    setFinalData("Failed to get publisher info!");
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
            }).finally(() => {
                setSpinner(false);
            });
        };


        const performVerify = () => {
            let FirstName = firstNameRef.current.value;
            let LastName = lastNameRef.current.value;
            let newEmail = emailRef.current.value;
            let newPassword = PasswordRef.current.value;
            let ZIP = ZIPRef.current.value;
            let Experience = ExperienceRef.current.value;
            let ContactWhatsapp = ContactWhatsappRef.current.value;
            let ContactSkype = ContactSkypeRef.current.value;
            let ContactTelegram = ContactTelegramRef.current.value;
            let PaymentMethod = PaymentMethodRef.current.value;
            let Country = CountryDropbox.getSelectedItem().value;
            let ReferralPercentEarnings = Number(ReferralPercentEarningsRef.current.value);

            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/editUser`,
                data: {
                    id: props.ID,
                    FirstName: FirstName ? FirstName : null,
                    LastName: LastName ? LastName : null,
                    newEmail: newEmail ? newEmail : null,
                    newPassword: newPassword ? newPassword : null,
                    ZIP: ZIP ? Number(ZIP) : null,
                    Experience: Number(Experience) ? Experience : null,
                    ContactWhatsapp: ContactWhatsapp ? ContactWhatsapp : null,
                    ContactSkype: ContactSkype ? ContactSkype : null,
                    ContactTelegram: ContactTelegram ? ContactTelegram : null,
                    PaymentMethod: PaymentMethod ? PaymentMethod : null,
                    Country: Country ? Country : null,
                    ReferralPercentEarnings,
                    AutoPayoutInterval: autoPayoutInterval ? autoPayoutInterval : null,
                    newFlags: isAdmin ? JSON.stringify({ isAdmin: fAdmin, isManager: fManager, isPublisherManager: pManager, isAdvertiserManager: aManager, isVerified: fVerified, isRefferalEnabled: fReferralEnabled, isPublisherHidden: fPublisherHidden, isCRMInfoAllowed: fcrmInfoAllowed }) : null
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/users/getSingleUser`,
                        data: {
                            ID: props.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.data.status === "error") {
                            setFinalData("Failed to get the new edited user!");
                            setSpinner(false);
                        } else {
                            props.c().then(() => {
                                if (props.onUpdate) props.onUpdate(final.data.data);
                            });
                        };
                    }).catch(() => {
                        setFinalData("Failed to get the new user");
                        setSpinner(false);
                    })
                } else {
                    setFinalData("Failed to edit user!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        React.useEffect(() => {
            setImmediate(getData);
        }, [firstNameRef]);

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminUsers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(props.user);
                    });
                }} />
            </div>
        }
        return <div className="route__adminUsers__addUser">
            <div className="route__adminUsers__addUser__userInfo">
                <CountryDropbox.DropBox selected={curCountry !== null ? curCountry : null} selectText="Country" data={countries.map(item => {
                    return {
                        name: item.name,
                        value: item.code
                    };
                })} />
                <CustomInput placeholder1="First name" ref={firstNameRef} />
                <CustomInput placeholder1="Last name" ref={lastNameRef} />
                <CustomInput placeholder1="Email" ref={emailRef} />
                <CustomInput placeholder1="Password" ref={PasswordRef} />
                <CustomInput placeholder1="ZIP" ref={ZIPRef} />
                <CustomInput placeholder1="Experience" ref={ExperienceRef} />
                <CustomInput placeholder1="Contact Whatsapp" ref={ContactWhatsappRef} />
                <CustomInput placeholder1="Contact Skype" ref={ContactSkypeRef} />
                <CustomInput placeholder1="Contact Telegram" ref={ContactTelegramRef} />
                <CustomInput placeholder1="Payment method" ref={PaymentMethodRef} />
                <CustomInput placeholder1="Referral earnings (in %)" ref={ReferralPercentEarningsRef} />

                {isAdmin && <div className="route__adminUsers__addUser__userInfo__autoPayouts" style={{ width: "100%" }}>
                    <span>Automatic payout inverval:</span>
                    <div onClick={() => setAutoPayoutInterval(0)} className={`route__adminUsers__addUser__userInfo__autoPayouts__payout ${autoPayoutInterval === 0 ? "route__adminUsers__addUser__userInfo__autoPayouts__payout--active" : ""}`}>None</div>
                    <div onClick={() => setAutoPayoutInterval(7)} className={`route__adminUsers__addUser__userInfo__autoPayouts__payout ${autoPayoutInterval === 7 ? "route__adminUsers__addUser__userInfo__autoPayouts__payout--active" : ""}`}>7 days</div>
                    <div onClick={() => setAutoPayoutInterval(14)} className={`route__adminUsers__addUser__userInfo__autoPayouts__payout ${autoPayoutInterval === 14 ? "route__adminUsers__addUser__userInfo__autoPayouts__payout--active" : ""}`}>14 days</div>
                    <div onClick={() => setAutoPayoutInterval(30)} className={`route__adminUsers__addUser__userInfo__autoPayouts__payout ${autoPayoutInterval === 30 ? "route__adminUsers__addUser__userInfo__autoPayouts__payout--active" : ""}`}>30 days</div>
                </div>}
                {isAdmin && <div className="route__adminUsers__addUser__userInfo__flags">
                    <p>Flags:</p>
                    <p>Admin <CustomRadio selected={fAdmin} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFAdmin(true)} functionNo={() => setFAdmin(false)} /></p>
                    <p>Manager <CustomRadio selected={fManager} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFManager(true)} functionNo={() => setFManager(false)} /></p>
                    <p>Publisher manager <CustomRadio selected={pManager} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setPManager(true)} functionNo={() => setPManager(false)} /></p>
                    <p>Advertiser manager <CustomRadio selected={aManager} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setAManager(true)} functionNo={() => setAManager(false)} /></p>
                    <p>Verified <CustomRadio selected={fVerified} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFVerified(true)} functionNo={() => setFVerified(false)} /></p>
                    <p>Referral <CustomRadio selected={fReferralEnabled} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => fSetReferralEnabled(true)} functionNo={() => fSetReferralEnabled(false)} /></p>
                    <p>Publisher hidden <CustomRadio selected={fPublisherHidden} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFPublisherHidden(true)} functionNo={() => setFPublisherHidden(false)} /></p>
                    <p>Can see CRM info <CustomRadio selected={fcrmInfoAllowed} className="route__adminUsers__addUser__userInfo__flags__radio" valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setFCrmInfoAllowed(true)} functionNo={() => setFCrmInfoAllowed(false)} /></p>
                </div>}
            </div>
            <ButtonSmall value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
            <ButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };

    const DeleteUser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/deleteUser`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to delete user!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminUsers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminUsers__verifyPublisher">
            <p>This will delete the user and ALL OF HIS DATA</p>
            <p>(Advertiser account, offers, taken offers, leads....etc)</p>
            <p>Are you REALLY sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    }
    const CreatePayoutForUser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        const [canCreatePayout, setCanCreatePayout] = React.useState(false);



        const getCanCreatePayout = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/payouts/checkPayoutPending`,

                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setCanCreatePayout(true)
                } else {
                    setCanCreatePayout(false)
                }
            }).catch(() => {
                setCanCreatePayout(false)
            })
        }
        React.useEffect(() => {
            if (props.paymentMethod === null) return
            getCanCreatePayout()
        }, [])

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/payouts/createPlatformPayout`,
                data: {
                    BoundUser: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to create payout!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminUsers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        if (props.paymentMethod === null) {
            return <div className="route__adminUsers__verifyPublisher">
                <p>Payout can't be created.</p>
                <p>User need to add payment method!</p>
                <ButtonSmall value="Close" onClick={props.c} />
            </div>
        }
        if (!canCreatePayout) return <div className="route__adminUsers__verifyPublisher">
            <p>Payout can't be created.</p>
            <p>There are payouts that still haven't been processed!</p>
            <ButtonSmall value="Close" onClick={props.c} />
        </div>
       
        return <div className="route__adminUsers__verifyPublisher">
            <p>This will create a payout for this user.</p>
            <p>NOTE: You still need to go to 'Payments' and process the payout</p>
            <p>Are you REALLY sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>


    };

    const PaginationUser = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continueUsers, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                } else if (elem.name === "Username") {
                    addFilterItem({
                        name: "Username",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "Username",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    React.useEffect(() => {
        getUsers();
    }, [filters]);

    return <div className="route__adminUsers">
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return <p>{userData.data}</p>
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Username", "Email", "Verified"]} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.Username },
                            { keyID: item.ID, type: "text", text: item.Email },
                            {
                                keyID: item.ID, type: "group", group: [
                                    {
                                        keyID: item.ID, type: "text", text: (() => {
                                            let final = [];
                                            final.push(<p><span style={{ color: item.Flags.isVerified ? "green" : "red" }}>{item.Flags.isVerified ? "Yes" : "No"}</span></p>);
                                            return final;
                                        })()
                                    },
                                ]
                            },
                            {
                                keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                            return <EditUser ID={item.ID} user={item} c={c} onUpdate={(usr) => {
                                                if (usr) {
                                                    usr.Flags = JSON.parse(usr.Flags);
                                                    setUserData({
                                                        status: "ok", data: [
                                                            ...userData.data.filter((_, index2) => index2 < index),
                                                            usr,
                                                            ...userData.data.filter((_, index2) => index2 > index)
                                                        ]
                                                    });
                                                };
                                            }} />
                                        }
                                    },
                                    isAdmin ? {
                                        keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => {
                                            return <DeleteUser ID={item.ID} c={c} onUpdate={() => {
                                                setUserData({
                                                    status: "ok", data: [
                                                        ...userData.data.filter((_, index2) => index2 < index),
                                                        ...userData.data.filter((_, index2) => index2 > index)
                                                    ]
                                                });
                                            }} />
                                        }
                                    } : null,
                                    isAdmin ? {
                                        keyID: item.ID, type: "button", text: "Create payout", triggerDropdown: true, triggerData: c => {
                                            return <CreatePayoutForUser paymentMethod={item.PaymentMethod} ID={item.ID} c={c} onUpdate={() => {

                                            }} />
                                        }
                                    } : null,
                                    {
                                        keyID: item.ID, type: "button", text: "More info", triggerDropdown: true, triggerData: () => {
                                            return <DisplayUserInfo user={item} />
                                        }
                                    },
                                    item.AdvertiserID ? { keyID: item.ID, type: "button", text: "Go to advertiser account", onClick: () => animateNavigate(`/dashboard/admin-advertisers?ID=${encodeURI(item.AdvertiserID)}`) } : null,
                                    (item.Flags.isVerified) ? null : {
                                        keyID: item.ID, type: "button", text: "Verify publisher", triggerDropdown: true, triggerData: c => {
                                            return <VerifyPublisher ID={item.ID} c={c} onUpdate={() => {
                                                item.Flags.isVerified = true;
                                                setUserData({
                                                    status: "ok", data: [
                                                        ...userData.data.filter((_, index2) => index2 < index),
                                                        item,
                                                        ...userData.data.filter((_, index2) => index2 > index)
                                                    ]
                                                });
                                            }} />
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <PaginationUser /> }])

                    return tmp;
                })()} filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "Email", friendlyName: "E-mail", type: "string" },
                    { name: "FirstName", friendlyName: "First name", type: "string" },
                    { name: "LastName", friendlyName: "Last name", type: "string" },
                    {
                        name: "Country", friendlyName: "Country", type: "custom", varType: "string", data: countries.map(country => {
                            return {
                                text: country.name,
                                value: country.code
                            };
                        })
                    },
                    { name: "ZIP", friendlyName: "ZIP", type: "number" },
                    { name: "Experience", friendlyName: "Experience", type: "number" },
                    { name: "Flags:isAdmin", friendlyName: "Is admin", type: "boolean" },
                    { name: "Flags:isManager", friendlyName: "Is manager", type: "boolean" },
                    { name: "Flags:isVerified", friendlyName: "Is verified", type: "boolean" },
                    { name: "Flags:isCRMInfoAllowed", friendlyName: "Is CRM info visible", type: "boolean" },
                    { name: "AdvertiserID", friendlyName: "Is advertiser", type: "custom", data: [{ value: null, text: "No" }], varType: "custom" }
                ]} />
            };
        })()}
    </div>
};

export default AdminUsers;