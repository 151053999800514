export const lightTheme = () => {
    return {
      type: "LIGHT",
    };
  };
  
  export const darkTheme = () => {
      return {
        type: "DARK",
      };
    };