import './index.scss';
import React from 'react';
import CustomInput from '../../../../components/customComponents/CustomInput'
import Button from '../../../../components/customComponents/Button'
import Spinner from '../../../../components/customComponents/Spinner'
import axios from "axios";
import * as backendModule from '../../../../modules/backendModule'
import AnimateModule from '../../../../modules/animateModule'
import { useNavigate } from 'react-router-dom';
import { countries as CountryList } from '../../../../modules/countryModule';
import { useDispatch, useSelector } from 'react-redux';
import * as timestampActions from '../../../../actions/timestampActions';

const RegisterAdvertiser = () => {
    let userDataDispatch = useDispatch();
    let userData = useSelector((state) => state.userData)
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const [spinner, setSpinner] = React.useState(false)
    //slanje podataka ?
    const sendData = (e) => {
        e.preventDefault()
        setSpinner(true)
        let inputs = e.target.querySelectorAll('input')
        let companyName = inputs[0].value
        let companyAdress = inputs[1].value
        let companyCity = inputs[2].value
        let ZIP = inputs[3].value
        let tax = inputs[4].value
        let vat = inputs[5].value
        if (companyName.length === '') {
            inputs[0].parentNode.style.border = '1px solid red'
            e.target.querySelector('.register__form__msg').innerHTML = 'Please enter your  company name'
            setTimeout(() => {
                inputs[0].parentNode.style.border = 'none'
            }, 3000)
            return;
        }
        if (companyAdress === '') {
            inputs[1].parentNode.style.border = '1px solid red'
            e.target.querySelector('.register__form__msg').innerHTML = 'Please enter your company adress'
            setTimeout(() => {
                inputs[1].parentNode.style.border = 'none'
            }, 3000)
            return;
        }
        let register = document.querySelector('.register')
        let spiner = register.querySelector('.customComponents__spinner')
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/addAdvertiser`,
            data: {
                CompanyName: companyName,
                CompanyAddress: companyAdress,
                CompanyCity: companyCity,
                CompanyZipCode: ZIP,
                TaxVatNumber: 'Tax: ' + tax + ', VAT: ' + vat,
                id: userData.userData?.UserInfo?.ID

            },
            ...backendModule.axiosConfig
        }).then((e) => {
            userDataDispatch(timestampActions.updateTimestamp());
            
        }).catch(() => {
        }).finally(() => {
            setSpinner(false)
        })
    }

    React.useEffect(() => {
        if (userData?.userData?.AdvertiserInfo?.ID) animateNavigate("/dashboard/advertiser");
    }, [userData]);

    return <div className="register registerAdvertiser"  >
        {spinner ? <Spinner /> : null}
        <h1 className='register__head'>Advertiser Sign up</h1>
        <form className='register__form' onSubmit={(e) => { sendData(e) }}>
            <CustomInput placeholder1={'Company name'} type='text' required name='Username' />
            <CustomInput placeholder1={'Company adress'} type='text' required name='password' />
            <CustomInput placeholder1={'Company city'} type='text' required name='password' />
            <CustomInput placeholder1={'Company ZIP code'} type='number' required name='password' />
            <CustomInput placeholder1={'Tax number'} type='text' required name='First name' />
            <CustomInput placeholder1={'VAT number'} type='text' required name='Last name' />

            <p className='register__form__msg' ></p>
            <Button value='Sing up' style={{ margin: 'auto', marginTop: '20px' }} type='submit' />
        </form>
    </div>
};

export default RegisterAdvertiser;