
import "./index.scss";
import CustomInput from '../../components/customComponents/CustomInput'
import Spinner from '../../components/customComponents/Spinner'
import Button from '../../components/customComponents/Button'
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampActions";
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from "react-router-dom";
import React from "react";


const Login = () => {
    let userDataDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const [spinner, setSpinner] = React.useState(false)
    const sendData = (e) => {
        setSpinner(true)
        e.preventDefault()
        let inputs = e.target.querySelectorAll('input')
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data: {
                username: inputs[0].value,
                password: inputs[1].value,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                userDataDispatch(timestampActions.updateTimestamp());
                animateNavigate('/dashboard/publisher')
            } else {
                document.querySelector('.login__form__errorMsg').style.display = 'block'
                setSpinner(false)
            }
        }).catch((e) => {
            document.querySelector('.login__form__errorMsg').style.display = 'block'
                setSpinner(false)
        });
    }

    React.useEffect(() => {
        if (userData.isLoggedIn === true) animateNavigate('/dashboard/publisher');
    }, [userData]);
    React.useEffect(()=>{
        document.querySelector('body').classList.remove('dark')
    }, [])
    return <div className="login">
        {spinner ? <Spinner style={{marginTop:'50px'}} /> : ''}
        <img className="login__image" src='/images/landing/logibimage.png' />
        <img className="login__maskMobile" src="/images/landing/mobileimageup.png" />
        <img className="login__imageDown" src='/images/landing/Footer.png' />
        <img className="login__imageDownTablet" src='/images/landing/footerTablety.png' />
        <img className="login__imageDownMobile" src='/images/landing/FooterMobile.png' />
        <div className="login__container">
            <div className="login__container__left" >
                <img src="/images/landing/containerImage.png" />
            </div>
            <div className="login__container__right" >
                <h1 className="login__head">
                    Log in
                </h1>
                <form className="login__form" onSubmit={(e) => { sendData(e) }} autoComplete="off">
                    <input placeholder='Enter your username' type='text' required />
                    <input placeholder='Enter your password' type='password' required />
                    <p className="login__form__p" onClick={() => { animateNavigate('/resetPassword') }}>Forgot password?</p>
                    <p className="login__form__errorMsg">Username or password is incorrect</p>
                    <button type='submit' >Log in</button>
                </form>
                <p className="login__form__p" style={{ textAlign: 'center', marginTop: '20px' }} >Don’t have an account?  <spam onClick={() => { animateNavigate('/register') }}>Sign up</spam></p>
            </div>
        </div>
        <div className="component__footer__logos" style={{ position: 'relative', bottom: '50px' }} >
            <img src="/images/logoNewWhite.svg" style={{'width':'190px'}} ></img>
            <div className="component__footer__logos__social" style={{ marginTop: '20px' }}>
                <a href="https://www.facebook.com/scalelead" target='_blank' ><img src="/images/landing/fb.png" /></a>
                <a href="https://www.linkedin.com/company/scalelead" target='_blank'><img src="/images/landing/ln.png" /></a>
                <a href="https://www.instagram.com/scalelead/" target='_blank'><img src="/images/landing/insta.png" /></a>

            </div>
        </div>

    </div>
};

export default Login;