import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import { countries } from "../../../../modules/countryModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomButton from "../../../../components/customComponents/Button";
import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropBox from "../../../../components/customComponents/DropBox";
import CustomRadio from "../../../../components/customComponents/Radio";
import Spinner from "../../../../components/customComponents/Spinner";
import AddOffer from './AddOffer';

const AdminOffers = () => {
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [canPaginate, setCanPaginate] = React.useState(false);

    let [addOfferC, setAddOfferC] = React.useState(false);

    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const continueOffer = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData], timestamp: Date.now() });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const getOffers = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData, timestamp: Date.now() });
            } else {
                setUserData({ status: "error", data: "There was an error while getting offers" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const PaginationOffer = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continueOffer, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    const DeleteOffer = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/offers/deleteOffer`,
                data: {
                    ID: props.ID,
                    BoundAdvertiser: props.user.BoundAdvertiser
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to delete offer!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminOffers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminOffers__verifyPublisher">
            <p>This will delete the offer</p>
            <p>Any taken offers will be permanently deleted!</p>
            <p>Leads will stay</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const EditOffer = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        let [oldData, setOldData] = React.useState({});
        let [advertiserData, setAdvertiserData] = React.useState(null);

        let [countryList, setCountryList] = React.useState([]);
        let [countryListVisible, setCountryListVisible] = React.useState(false);
        let curCountryList = CustomDropBox();
        let [offerActive, setOfferActive] = React.useState(false);

        let [oldImage, setOldImage] = React.useState(null);
        let [curImage, setCurImage] = React.useState(null);

        let [offerDescription, setOfferDescription] = React.useState("");

        let offerNameRef = React.useRef();
        let offerProductBrandRef = React.useRef();
        let offerProductNameRef = React.useRef();
        let offerPayoutRef = React.useRef();
        let platformTaxRef = React.useRef();
        let offerLimitRef = React.useRef();
        let offerProductPriceRef = React.useRef();
        let refferalPayoutRef = React.useRef();
        let offerType = CustomDropBox();
        let selectedCRM = CustomDropBox();
        let crmIDRef = React.useRef();
        let finalCalculationsRef = React.useRef();

        const modules = {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
        };

        const formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ];

        const getData = () => {
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/offers/getOfferByID`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(async res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/partners/getAdvertiserByID`,
                        data: {
                            ID: res.data.data.BoundAdvertiser
                        },
                        ...backendModule.axiosConfig
                    }).then(async res2 => {
                        if (res2.data.status === "ok") {
                            setOldData(res.data.data);
                            setAdvertiserData(res2.data.data);
                            setCountryList(res.data.data.OfferCountry.split("~").filter(tmp => tmp));
                            setOfferActive(res.data.data.OfferActive);
                            setOldImage(res.data.data.OfferImages);
                            setCurImage(res.data.data.OfferImages);
                            setOfferDescription(res.data.data.OfferDescription);
                            setSpinner(false);
                            while (!offerNameRef.current) {
                                await new Promise(r2 => setTimeout(() => r2(), 50));
                            };

                            offerNameRef.current.value = res.data.data.OfferName;
                            offerProductNameRef.current.value = res.data.data.OfferProductName;
                            offerPayoutRef.current.value = res.data.data.OfferPayout;
                            platformTaxRef.current.value = res.data.data.PlatformTax;
                            offerLimitRef.current.value = res.data.data.OfferLimit;
                            offerProductPriceRef.current.value = res.data.data.OfferProductPrice;
                            offerProductBrandRef.current.value = res.data.data.OfferProductBrand;
                            refferalPayoutRef.current.value = res.data.data.ReferralPayout ?? 0;
                            crmIDRef.current.value = res.data.data.CRMID;
                            performFinalCalculations();
                        } else {
                            setFinalData("Failed to get advertiser data!");
                            setSpinner(false);
                        };
                    }).catch(() => {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                    })
                } else {
                    setFinalData("Failed to get offer data!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };


        const performVerify = async () => {
            let OfferName = offerNameRef.current.value;
            let OfferProductName = offerProductNameRef.current.value;
            let OfferPayout = offerPayoutRef.current.value;
            let PlatformTax = platformTaxRef.current.value;
            let OfferLimit = offerLimitRef.current.value;
            let OfferProductPrice = offerProductPriceRef.current.value;
            let OfferImages = "";
            let OfferType = offerType.getSelectedItem().value;
            let SelectedCRM = selectedCRM.getSelectedItem().value;
            let OfferProductBrand = offerProductBrandRef.current.value;
            let ReferralPayout = refferalPayoutRef.current.value;
            let CRMID = crmIDRef.current.value;

            setSpinner(true);

            if (oldImage !== curImage) {
                if (curImage instanceof File) {
                    let fd = new FormData();
                    fd.append("image", curImage);
                    fd.append("ImageName", OfferName);
                    fd.append("tag", "Offer")
                    try {
                        let finalImage = await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/images/uploadImage`,
                            data: fd,
                            ...backendModule.axiosConfig
                        });
                        if (finalImage.data.status === "error") {
                            setFinalData("Error while uploading image");
                            setSpinner(false);
                            return;
                        } else {
                            OfferImages = finalImage.data.data;
                            let toDelete = oldImage.split("/");
                            try {
                                let deletedFile = await axios({
                                    method: "POST",
                                    url: `${backendModule}/images/deleteImageByFilename`,
                                    data: {
                                        FileName: toDelete[toDelete.length - 1]
                                    },
                                    ...backendModule.axiosConfig
                                });
                            } catch { };
                        };
                    } catch {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                        return;
                    };
                };
            } else {
                OfferImages = oldImage;
            };

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/offers/editOffer`,
                data: {
                    ...oldData,
                    OfferCountry: countryList,
                    OfferName,
                    OfferImages,
                    OfferDescription: offerDescription,
                    OfferPayout: Number(OfferPayout),
                    OfferType,
                    OfferActive: offerActive,
                    PlatformTax,
                    SelectedCRM,
                    OfferLimit,
                    OfferProductPrice,
                    OfferProductName,
                    OfferProductBrand,
                    ReferralPayout: Number(ReferralPayout) > 0 ? Number(ReferralPayout) : 0,
                    CRMID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/offers/getOfferByID`,
                        data: {
                            ID: props.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.status === "error") {
                            setFinalData("There was an error while getting the new offer!");
                            setSpinner(false);
                        } else {
                            props.c().then(() => {
                                final.data.data.OfferCountry = final.data.data.OfferCountry.split("~").filter(tmp => tmp);
                                if (props.onUpdate) props.onUpdate(final.data.data);
                                setSpinner(false);
                            });
                        }
                    }).catch(() => {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to edit offer!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        const performFinalCalculations = () => {
            if (!finalCalculationsRef?.current) return;
            if (!offerPayoutRef?.current) return;
            if (!platformTaxRef?.current) return;
    
            let tax = Number(platformTaxRef.current.value);
            let payout = Number(offerPayoutRef.current.value);
    
            if (isNaN(tax) || isNaN(payout)) return;
    
            let platformTakes = payout * (tax / 100);
            let publisherGets = payout - platformTakes;
    
            finalCalculationsRef.current.innerHTML = `
                <p>Advertiser pays: ${Number(payout).toFixed(2)} $</p>
                <p>Platform takes: ${Number(platformTakes).toFixed(2)} $</p>
                <p>Publisher gets: ${Number(publisherGets).toFixed(2)} $</p>
            `;
        };

        React.useEffect(() => {
            setImmediate(getData);
        }, [offerNameRef]);

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminDomains__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(props.user);
                    });
                }} />
            </div>
        }
        return <div className="route__adminOffers__addUser">
            <div className="route__adminOffers__addUser__userInfo">
                <div className="route__adminOffers__addUser__addCountries">
                    <p>Countries</p>
                    {countryList.map(cItem => <div className="route__adminOffers__addUser__addCountries__pill" onClick={() => {
                        setCountryList(countryList.filter(cItem2 => cItem2 !== cItem));
                    }}>
                        <img src={`/images/flags/${cItem.replace(/"/g, "").toLowerCase()}.png`} />
                        <span>✖</span>
                    </div>)}
                    {countryListVisible && <curCountryList.DropBox reset={true} data={countries.map(item => {
                        return { name: item.name, value: item.code }
                    })} onChange={() => {
                        setCountryList([...countryList, curCountryList.getSelectedItem().value]);
                        setCountryListVisible(false);
                    }} />}
                    {<div onClick={() => setCountryListVisible(!countryListVisible)} className="route__adminOffers__addUser__addCountries__pill">
                        <span>{countryListVisible ? "Close" : "Add"}</span>
                    </div>}
                </div>
                <div className="route__adminOffers__addUser__getCRM">
                    <p>Selected CRM</p>
                    {advertiserData && <selectedCRM.DropBox selected={(() => {
                        let info = JSON.parse(advertiserData.CRMInfo);
                        let curIndex = -1;
                        info.forEach((eC, eI) => {
                            if (eC.ID === oldData.SelectedCRM) curIndex = eI;
                        });
                        if (curIndex !== -1) return curIndex;
                    })()} data={JSON.parse(advertiserData.CRMInfo).map(crm => {
                        return {
                            name: crm.friendlyName,
                            value: crm.ID
                        }
                    })} />}
                </div>
                <div className="route__adminOffers__addUser__getCRM">
                    <p>Offer type</p>
                    <offerType.DropBox selected={(() => {
                        switch (oldData.OfferType) {
                            case "CPL": return 0;
                            case "CPA": return 1;
                        };
                    })()} data={[
                        { name: "Cost per lead (CPL)", value: "CPL" },
                        { name: "Cost per action (CPA)", value: "CPA" }
                    ]} />
                </div>
                <CustomInput placeholder1="Offer name" ref={offerNameRef} />
                <CustomInput placeholder1="Product brand" ref={offerProductBrandRef} />
                <CustomInput placeholder1="Product name" ref={offerProductNameRef} />
                <CustomInput onChange={performFinalCalculations} placeholder1="Offer payout" ref={offerPayoutRef} />
                <CustomInput onChange={performFinalCalculations} placeholder1="Platform tax (%)" ref={platformTaxRef} />
                <CustomInput placeholder1="Product price + currency" ref={offerProductPriceRef} />
                <CustomInput placeholder1="Offer lead limit" ref={offerLimitRef} />
                <CustomInput placeholder1="CRM Article ID" ref={crmIDRef} />
                <CustomInput ref={refferalPayoutRef} placeholder1="Refferal payout (in $)" value="0" />
                <div className="route__adminOffers__addUser__getCRM">
                    Offer active: <CustomRadio selected={offerActive} className="route__adminOffers__addUser__getCRM__radio" valueyes={<p>Active</p>} valueno={<p>Inactive</p>} functionYes={() => setOfferActive(true)} functionNo={() => setOfferActive(false)} />
                </div>
                <div className="route__adminOffers__addUser__getCRMImage" onClick={(eClick) => {
                    eClick?.target?.querySelector("input[type=file]")?.click();
                }}>
                    <img src={curImage} />
                    <div className="route__adminOffers__addUser__getCRMImage__text">
                        <p>Press to change image</p>
                        <input onChange={eFile => {
                            let fr = new FileReader();
                            fr.onload = eR => {
                                eFile.target.parentNode.parentNode.querySelector("img").src = eR.target.result;
                            };
                            fr.readAsDataURL(eFile.target.files[0]);
                            setCurImage(eFile.target.files[0]);
                        }} type="file" accept="image/*" style={{ display: "none" }} />
                    </div>
                </div>

                <br />
                <h3 style={{marginTop: "20px", marginBottom: "20px", width: "100%"}}>Final calculations:</h3>
                <div style={{width: "100%"}} ref={finalCalculationsRef} className="route__adminOffers__addOffer__finalCalc"></div>

                <div className="route__adminOffers__addUser__offerDesc">
                    <ReactQuill value={offerDescription} onChange={setOfferDescription} style={{ width: "100%", height: "100%" }} theme='snow' modules={modules} formats={formats} />
                </div>
            </div>
            <ButtonSmall value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
            <ButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };

    

    React.useEffect(() => {
        getOffers();
    }, [filters]);

    React.useEffect(() => {
        if (!firstTime) return;
        if (!userData) return;
        if (!addFilterItem) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    return <div className="route__adminOffers">
        <button  className="route__adminDomains__addDomainBtn" onClick={() => setAddOfferC(!addOfferC)} >Add offer <img src="/images/plusImageWhite.png"/></button>

        <div className='route__adminOffers__addOfferWrap'>
            <AddOffer key={userData?.timestamp} active={addOfferC} setActive={setAddOfferC} onUpdate={() => {
                setAddOfferC(false);
                getOffers();
            }} />
        </div>
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return <p>{userData.data}</p>
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)}  headers={["Name", "Product", "Type"]} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.OfferName },
                            {
                                keyID: item.ID, type: "group", group: [
                                    { type: "text", text: item.OfferProductBrand },
                                    { type: "text", text: item.OfferProductName }
                                ]
                            },
                            { keyID: item.ID, type: "text", text: item.OfferType },

                            {
                                keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: 'button', text: 'More info', triggerDropdown: true, triggerData: c => {
                                            return <div style={{ marginLeft: '10px' }} className='moreInfoContainer'>                    
                                                <p><span>Offer ID:</span><br />{item.ID}</p>
                                                <p><span>Platform tax:</span><br />{item.PlatformTax} %</p>
                                                <p><span>Countries:</span><br />
                                                    {item.OfferCountry.map(elem => {
                                                        return <spam> <img style={{marginLeft: '10px', position: 'relative', top:'2px'}} src={`/images/flags/${decodeURI(elem.replace(/"/g, "").toLowerCase())}.png`} />&nbsp; {decodeURI(elem.replace(/"/g, ""))}</spam>
                                                    })}
                                                </p>
                                                <p><span>Active:</span><br />{item.OfferActive ? <span style={{color: "green"}}>Yes</span> : <span style={{color: "red"}}>No</span>}</p>
                                                <p><span>Limit:</span><br />{item.OfferLimit}</p>
                                                <p><span>Coversion ration:</span><br />{item.OfferMonthlyConversionRatio === -1 ? "?" : item.OfferMonthlyConversionRatio}</p>
                                                <p><span>Payout:</span><br />{item.OfferPayout} $</p>
                                                <p><span>Product price:</span><br />{item.OfferProductPrice}</p>
                                                <p><span>Referral payout:</span><br />{item.ReferralPayout} $</p>
                                            </div>
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                            return <EditOffer ID={item.ID} user={item} c={c} onUpdate={(usr) => {
                                                if (usr) {
                                                    setUserData({
                                                        status: "ok", data: [
                                                            ...userData.data.filter((_, index2) => index2 < index),
                                                            usr,
                                                            ...userData.data.filter((_, index2) => index2 > index)
                                                        ]
                                                    });
                                                };
                                            }} />
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => {
                                            return <DeleteOffer ID={item.ID} user={item} c={c} onUpdate={() => {
                                                setUserData({
                                                    status: "ok", data: [
                                                        ...userData.data.filter((_, index2) => index2 < index),
                                                        ...userData.data.filter((_, index2) => index2 > index)
                                                    ]
                                                });
                                            }} />
                                        }
                                    },
                                ]
                            }
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <PaginationOffer /> }])

                    return tmp;
                })()} filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "BoundAdvertiser", friendlyName: "Advertiser ID", type: "string" },
                    { name: "OfferName", friendlyName: "Offer name", type: "string" },
                    { name: "OfferProductName", friendlyName: "Product name", type: "string" },
                    { name: "OfferProductBrand", friendlyName: "Product brand", type: "string" },
                    {
                        name: "OfferCountry", friendlyName: "Country", type: "custom", varType: "string", data: countries.map(cItem => {
                            return { text: cItem.name, value: cItem.code };
                        })
                    },
                    {
                        name: "OfferType", friendlyName: "Offer type", type: "custom", varType: "string", data: [
                            { text: "Cost per action (CPA)", value: "CPA" },
                            { text: "Cost per lead (CPL)", value: "CPL" }
                        ]
                    },
                    { name: "OfferPayout", friendlyName: "Offer payout ($)", type: "number" },
                    { name: "OfferLimit", friendlyName: "Offer lead limit", type: "number" },
                    { name: "OfferProductPrice", friendlyName: "Product price", type: "string" },
                    { name: "OfferMonthlyConversionRatio", friendlyName: "Offer conversions (%)", type: "number" },
                    { name: "OfferActive", friendlyName: "Offer active", type: "boolean" },
                    { name: "PlatformTax", friendlyName: "Platform tax (%)", type: "number" }
                ]} />
            };
        })()}
    </div>
};

export default AdminOffers;