import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import {getParamsFromURL} from "../../../../modules/urlModule";
import {useLocation, useNavigate} from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import {useSelector} from "react-redux";

import {FilteredCustomTable} from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomButton from "../../../../components/customComponents/Button";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";
import AddWebsite from "./AddWebsite";

const AdminWebsites = () => {
    let [userData, setUserData] = React.useState(null);
    let [offerNames, setOfferNames] = React.useState([]);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [canPaginate, setCanPaginate] = React.useState(false);

    let [addWebsiteVisible, setAddWebsiteVisible] = React.useState(false);

    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();
    let curUser = useSelector(state => state.userData?.userData?.UserInfo?.ID);

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const continueWebsites = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsitesFrontend`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({status: "ok", data: [...userData.data, ...res.data.data.DBData], timestamp: Date.now()});
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const getWebsites = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsitesFrontend`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({status: "ok", data: res.data.data.DBData, timestamp: Date.now()});

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/offers/getAllOfferNames`,
                    ...backendModule.axiosConfig
                }).then(oRes => {
                    if (oRes.data.status === "error") {
                        setUserData({status: "error", data: "There was an error while getting offer names"});
                        setLoadingSpinner(false);
                    } else {
                        setOfferNames({status: "ok", data: oRes.data.data});
                        setLoadingSpinner(false);
                    };
                }).catch(() => {
                    setUserData({status: "error", data: "Server timed out!"});
                    setLoadingSpinner(false);
                });
            } else {
                setUserData({status: "error", data: "There was an error while getting websites"});
                setLoadingSpinner(false);
            };
        }).catch(() => {
            setUserData({status: "error", data: "Server timed out!"});
            setLoadingSpinner(false);
        }).finally(() => {
            setCanPaginate(true);
        });
    };

    const PaginationWebsite = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try{observer.unobserve(tmpRef.current);} catch{};
                        canPaginate && setTimeout(continueWebsites, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try{observer.unobserve(tmpRef.current);} catch{};
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    const DeleteWebsite = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/websites/deleteWebsite`,
                data: {
                    id: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to delete the website!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminWebsites__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminWebsites__verifyPublisher">
            <p>This will delete the internal domain</p>
            <p>It cant be used anymoe, this might cause issues for people who are using it to get links.</p>
            <p>Existing links will still work!</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{backgroundColor: "#00A3FF", color: 'white'}} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };



    React.useEffect(() => {
        getWebsites();
    }, [filters]);
    
    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    return <div className="route__adminWebsites">
        {userData?.status === "ok" && offerNames?.status === "ok" && <>
            <button  className="route__adminDomains__addDomainBtn" onClick={() => setAddWebsiteVisible(!addWebsiteVisible)} >Add website<img src="/images/plusImageWhite.png"/></button>

            <AddWebsite key={userData?.timestamp} onUpdate={() => {
                setAddWebsiteVisible(false);
                getWebsites()
            }} setActive={setAddWebsiteVisible} active={addWebsiteVisible} offers={offerNames.data} />
        </>}
        {(() => {
            if (!userData || !offerNames) return <Spinner />
            if (userData.status === "error") {
                return <p>{userData.data}</p>
            } else if (offerNames.status === "error") {
                return <p>{offerNames.data}</p>
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Website name",  "Offer"]} data={(()=>{
                    let tmp = userData.data.map((item, index) => {
                        return [
                            {keyID: item.ID, type: "text", text:item.WebsiteName},
                            {keyID: item.ID, type: "text", text:offerNames?.data?.find(elem => elem.ID === item.BoundOffer)?.OfferName},
                            {keyID: item.ID, type: "groupNewline", group: [
                                {keyID: item.ID, type: 'button', text: 'More info', triggerDropdown: true, triggerData: c=>{
                                    return <div className='moreInfoContainer'>
                                        <p> <span> Website name:</span><br /> {item.WebsiteName} </p>
                                        <p> <span> Entry file:</span><br /> {item.EntryFile}</p>
                                        <p> <span> "Thank you" file:</span><br /> {item.PurchaseCompleteFile} </p>
                                        <p> <span>Type:</span> <br /> {item.WebsiteType} </p>
                                        <p> <span> Offer:</span><br />  {offerNames?.data?.find(elem => elem.ID === item.BoundOffer)?.OfferName} </p>
                                    </div>
                                }},
                                {keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => {
                                    return <DeleteWebsite ID={item.ID} c={c} onUpdate={() => {
                                        setUserData({status: "ok", data: [
                                            ...userData.data.filter((_, index2) => index2 < index),
                                            ...userData.data.filter((_, index2) => index2 > index)
                                        ]});
                                    }} />
                                }},
                                {keyID: item.ID, type: "button", text: "Go to offer", onClick: () => {
                                    animateNavigate(`/dashboard/admin-offers?ID=${item.BoundOffer}`);
                                }},
                                {keyID: item.ID, type: "button", text: "Preview website", onClick: () => {
                                    window.open(item.URL, "_blank").focus();
                                }}
                            ]}
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{keyID: "customSpinner", type: "custom", data: <Spinner />}]);
                    if (curPagination.current !== -1) tmp.push([{keyID: "customPagination", type: "custom", data: <PaginationWebsite />}])

                    return tmp;
                })()} filters={[
                    {name: "WebsiteName", friendlyName: "Website name", type: "string"},
                    {name: "EntryFile", friendlyName: "Entry file", type: "string"},
                    {name: "PurchaseCompleteFile", friendlyName: "Thankyou file", type: "string"},
                    {name: "WebsiteType", friendlyName: "Website type", type: "custom", varType: "string", data: [
                        {text: "Landing page", value: "Landing"},
                        {text: "Pre-landing page", value: "PreLanding"},
                    ]},
                ]}/>
            };
        })()}
    </div>
};

export default AdminWebsites;