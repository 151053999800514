import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as backendModule from "../../../../modules/backendModule";
import animateModule from "../../../../modules/animateModule";
import useOnScreen from "../../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import KPI from "../../../../components/customComponents/KPI";
import FilterByDate from "../../../../components/filters/FilterByDate";
import Spinner from "../../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";

const AdminBlockedLeads = () => {
    const [data, setData] = React.useState();
    const [kpiData, setKPIData] = React.useState();
    const [offers, setOffers] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [dateFilters, setDateFilters] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);

    const curOnScreen = useOnScreen();
    const navigate = useNavigate();
    const timestampRef = React.useRef();

    const themeSelector = useSelector(state => state?.theme?.theme ?? "dark");

    const animateNavigate = to => {
        animateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const getKPI = () => {
        if (dateFilters === undefined) return;

        let finalDateFilters = [];
        if (dateFilters?.start && dateFilters?.end) {
            finalDateFilters.push({name: "createdAt", op: "dgeq", value: dateFilters.start.toDate().getTime()});
            finalDateFilters.push({name: "createdAt", op: "dleq", value: dateFilters.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllBlockedLeadsKPIAdmin`,
            data: {
                filters: [
                    ...finalDateFilters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setKPIData(res.data);
        }).catch(() => {
            setKPIData(backendModule.genericError);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        let finalDateFilters = [];
        if (dateFilters?.start && dateFilters?.end) {
            finalDateFilters.push({name: "createdAt", op: "dgeq", value: dateFilters.start.toDate().getTime()});
            finalDateFilters.push({name: "createdAt", op: "dleq", value: dateFilters.end.toDate().getTime()});
        };

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllBlockedLeadsDashboardAdmin`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    ...(Array.isArray(filters) ? filters : []),
                    ...finalDateFilters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);
                };
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data?.status !== "ok") return;
        if (!canPaginate) return;
        if (timestampRef.current !== ts) return;

        let finalDateFilters = [];
        if (dateFilters?.start && dateFilters?.end) {
            finalDateFilters.push({name: "createdAt", op: "dgeq", value: dateFilters.start.toDate().getTime()});
            finalDateFilters.push({name: "createdAt", op: "dleq", value: dateFilters.end.toDate().getTime()});
        };

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllBlockedLeadsDashboardAdmin`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters: [
                    ...(Array.isArray(filters) ? filters : []),
                    ...finalDateFilters
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) {
                    setCanPaginate(true);

                    setData(d => {
                        return {
                            ...d,
                            data: [
                                ...d.data,
                                ...res.data.data
                            ]
                        };
                    });
                };
            };
        }).catch(() => null);
    };

    const getSingleUser = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSingleUser`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                animateNavigate(`/dashboard/admin-users?Username=${encodeURI(res.data.data.Username)}`)
            }
        }).catch(() => {
        });
    };

    const getOffers = () => {
        setOffers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers({
                    status: "ok", data: res.data.data.DBData
                });
            } else {
                setOffers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setOffers({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        let ts = Date.now();
        timestampRef.current = ts;
        continueData(ts);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
        getKPI(ts);
    }, [dateFilters, filters]);

    React.useEffect(() => {
        getOffers();
    }, []);

    if (!data) return <Spinner />;
    if (data.status === "error") return <p style={{color: themeSelector === "dark" ? "white" : null}}>Error while fetching data!</p>
    return <div className="route__adminBlockedLeads">
        <div className="route__adminBlockedLeads__filters">
            <FilterByDate style={{width: "300px"}} onChange={e => {
                if (!e) return setDateFilters(e);
                setDateFilters({...e});
            }} disable24h={true} defaultValue="all" />
        </div>

        <div className="route__adminBlockedLeads__kpiPanel">
            {kpiData ? <>
                {kpiData?.status === "ok" ? <>
                    <KPI name="Total" value={kpiData.data.reduce((acc, val) => acc + +(val?.Count ?? 0) ,0)} />
                    {kpiData.data.map(d => <KPI name={d["BlockedReason"]} value={d["Count"]} />)}
                </> : <p style={{color: themeSelector === "dark" ? "white" : null, gridColumn: "1 / span all"}}>Error while fetching KPI data!</p>}
            </> : <Spinner style={{position: "relative", background: "transparent", justifyContent: "flex-start"}} spinnerMargin={0} />}
        </div>

        <FilteredCustomTable
            headers={["No.", "Product name", "Blocked reason", "Date"]}
            data={(()=>{
                let out = [];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: data.data.indexOf(item) + 1},
                        {keyID: item.ID, type: "text", text: item.ProductName},
                        {keyID: item.ID, type: "text", text: item.BlockedReason},
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString()},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "button", text: "More info", triggerDropdown: true, triggerData: c => {
                                return <div style={{ marginLeft: '10px' }} className='moreInfoContainer'>
                                    
                                    <p><span>Lead ID:</span> <br />{item.ID}</p>
                                    <p><span>Product name:</span> <br />{item.ProductName}</p>
                                    <p><span>Offer name:</span><br /> {item.OfferName}</p>
                                    <p><span>Domain:</span><br /> {item.PurchaseDomain}</p>
                                    <p><span>Purchase location:</span> <br />{item.PurchaseLocation.city} {item.PurchaseLocation.countryCode}</p>
                                    <p><span>Device:</span><br /> {item.PurchaseIsPhone? 'Mobile':'Desktop'}</p>
                                    <p><span>Date:</span><br /> {new Date(item.createdAt).toLocaleString()}</p>
                                    <p> <span>Purchase details:</span><br /> {(()=>{
                                        let final = [];
                                        Object.keys(item.PurchaseDetails).forEach(k => {
                                            if (!item.PurchaseDetails[k]) return;
                                            final.push(<p >{k.replace("scalelead-","")}: {item.PurchaseDetails[k]}</p>);
                                        });
                                        return final;
                                    })()}</p>
                                    <p><span>Purchase IP:</span><br /> {item.PurchaseIP ?? "-"}</p>
                                    <div style={{width: '100%'}}>
                                        <CustomButtonSmall style={{ width: '120px', marginTop: '10px', marginRight: '20px' }} value='See publisher' onClick={() => { getSingleUser(item.BoundUser) }} />
                                        <CustomButtonSmall style={{ width: '120px', marginTop: '10px' }} value='See advertiser' onClick={() => { animateNavigate(`/dashboard/admin-advertisers?ID=${encodeURI(item.BoundAdvertiser)}`) }} />
                                    </div>
                                </div>
                            }}
                        ]}
                    ])
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);
                return out;
            })()}
            filters={[
                { name: "ID", type: "string", friendlyName: "Lead ID" },
                { name: "BlockedReason", type: "string", friendlyName: "Blocked reason" },
                { name: "BoundUser", type: "string", friendlyName: "User ID" },
                { name: "BoundAdvertiser", type: "string", friendlyName: "Advertiser ID" },
                { name: "BoundOffer", type: "custom", friendlyName: "Offer ID", varType: "string", data: offers?.status === "ok" ? offers.data.map(offer => {
                    return { text: offer.OfferName, value: offer.ID }
                }) : [] },
                { name: "BoundTakenOffer", type: "string", friendlyName: "TakenOffer ID" },
                { name: "OfferName", friendlyName: "Offer name", type: "string" },
                { name: "ProductName", friendlyName: "Product name", type: "string" },
                { name: "PurchaseDomain", friendlyName: "Purchase domain", type: "string" },
                { name: "PurchaseIP", friendlyName: "Purchase IP", type: "string" },
                { name: "PurchaseIsPhone", friendlyName: "Is purchased on phone", type: "boolean" },
                { name: "PurchaseLeadType", friendlyName: "Offer type", type: "string" },
                { name: "PurchaseLocation:countryCode", friendlyName: "Country code", type: "string" },
                { name: "PurchaseLocation:city", friendlyName: "Purchase city", type: "string" },
                { name: "PurchaseDetails:scalelead-phone", type: "string", friendlyName: "Phone number" },
                { name: "createdAt", type: "date", friendlyName: "Date" },
                { name: "PurchaseDetails:scalelead-uid", type: "string", friendlyName: "Custom UID" },
                { name: "PurchaseDetails:scalelead-c1", type: "string", friendlyName: "Custom C1" },
                { name: "PurchaseDetails:scalelead-c2", type: "string", friendlyName: "Custom C2" },
                { name: "PurchaseDetails:scalelead-c3", type: "string", friendlyName: "Custom C3" },
                { name: "PurchaseDetails:scalelead-c4", type: "string", friendlyName: "Custom C4" },
                { name: "PurchaseDetails:scalelead-c5", type: "string", friendlyName: "Custom C5" },
                { name: "PurchaseDetails:scalelead-c6", type: "string", friendlyName: "Custom C6" },
                { name: "PurchaseDetails:scalelead-stclickid", type: "string", friendlyName: "ScaleTrack ClickID" }
            ]}
            filterCB={f => setFilters(f)}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}} />}
    </div>
};

export default AdminBlockedLeads;