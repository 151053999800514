let initialState = {
    theme: 'dark'
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "LIGHT":
            return {
                ...state,
                theme: 'light'
            };
        case "DARK":
            return {
                ...state,
                theme: 'dark'
            };

        default:
            return state;
    }
}