export const getLeadColor = (statusCode) => {
    switch (String(statusCode)) {
        case "-1":
        case "-2":
            return "#d0d003";
        case "0":
        case "1":
        case "6":
            return "gray";
        case "2":
            return "#0f8c0f";
        case "3":
        case "4":
        case "5":
            return "#d25151eb";

        default: return "#d25151eb";
    };
};