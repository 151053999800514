import React from "react";

import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import {countries} from "../../../../modules/countryModule";
import ReactQuill from "react-quill";

import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropbox from "../../../../components/customComponents/DropBox";
import CustomRadio from "../../../../components/customComponents/Radio";
import Spinner from "../../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";

const AddOffer = (props) => {
    const [advertiserData, setAdvertiserData] = React.useState(null);
    const [selectedAdvertiser, setSelectedAdvertiser] = React.useState(null);
    const [selectedCRM, setSelectedCRM] = React.useState(null);

    const [offerCountries, setOfferCountries] = React.useState([]);
    const [offerCountriesVisible, setOfferCountriesVisible] = React.useState(false);

    const [offerActive, setOfferActive] = React.useState(true);
    const [curImage, setCurImage] = React.useState(null);
    const [offerDescription, setOfferDescription] = React.useState(null);

    const [infoP, setInfoP] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);

    const offerNameRef = React.useRef();
    const offerProductBrandRef = React.useRef();
    const offerProductNameRef = React.useRef();
    const offerPayoutRef = React.useRef();
    const platformTaxRef = React.useRef();
    const offerLimitRef = React.useRef();
    const offerProductPriceRef = React.useRef();
    const refferalPayoutRef = React.useRef();
    const crmIDRef = React.useRef();
    const finalCalculationsRef = React.useRef();

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
    };
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    let boundAdvertiser = CustomDropbox();
    let boundCRM = CustomDropbox();
    let offerCountryList = CustomDropbox();
    let offerType = CustomDropbox();

    const performFinalCalculations = () => {
        if (!finalCalculationsRef?.current) return;
        if (!offerPayoutRef?.current) return;
        if (!platformTaxRef?.current) return;

        let tax = Number(platformTaxRef.current.value);
        let payout = Number(offerPayoutRef.current.value);

        if (isNaN(tax) || isNaN(payout)) return;

        let platformTakes = payout * (tax / 100);
        let publisherGets = payout - platformTakes;

        finalCalculationsRef.current.innerHTML = `
            <p>Advertiser pays: ${Number(payout).toFixed(2)} $</p>
            <p>Platform takes: ${Number(platformTakes).toFixed(2)} $</p>
            <p>Publisher gets: ${Number(publisherGets).toFixed(2)} $</p>
        `;
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/getAllAdvertisersWithCRM`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAdvertiserData(res.data);
            } else {
                setAdvertiserData({status: "error", data: "Cant get advertisers"});
            };
        }).catch(() => {
            setAdvertiserData({status: "error", data: "Cannot connect to server"});
        });
    }, []);

    const verifyAndSendData = async () => {
        setInfoP(null);
        let BoundAdvertiser = selectedAdvertiser;
        let SelectedCRM = selectedCRM;
        let OfferCountry = offerCountries; 
        let OfferName = offerNameRef.current.value;
        let OfferProductName = offerProductNameRef.current.value;
        let OfferType = offerType.getSelectedItem()?.value;
        let OfferPayout = offerPayoutRef.current.value;
        let PlatformTax = platformTaxRef.current.value;
        let OfferLimit = offerLimitRef.current.value;
        let OfferProductPrice = offerProductPriceRef.current.value;
        let OfferActive = offerActive;
        let OfferImages = curImage;
        let OfferDescription = offerDescription;
        let OfferProductBrand = offerProductBrandRef.current.value;
        let ReferralPayout = refferalPayoutRef.current.value;
        let CRMID = crmIDRef.current.value;

        if (!BoundAdvertiser) return setInfoP("Advertiser not selected");
        if (!SelectedCRM) return setInfoP("CRM for advertiser not selected");
        if (OfferCountry.length === 0) return setInfoP("No countries selected");
        if (!OfferName) return setInfoP("Offer name missing");
        if (!OfferProductBrand) return setInfoP("Product brand missing");
        if (!OfferProductName) return setInfoP("Product name missing");
        if (OfferType !== "CPA" && OfferType !== "CPL") return setInfoP("Offer type not selected");
        if (isNaN(Number(OfferPayout))) return setInfoP("Offer payout invalid");
        if (isNaN(Number(PlatformTax))) return setInfoP("Platform tax invalid");
        if (isNaN(Number(OfferLimit))) return setInfoP("Offer limit invalid");
        if (isNaN(Number(ReferralPayout))) return setInfoP("Refferal payout must be a number (0.00)");
        if (OfferProductPrice.split(" ").length !== 2) return setInfoP("Product price invalid, it should look like this: 50,00 KM or 20,00 EUR...etc");
        if (!OfferImages) return setInfoP("Image missing");
        if (!OfferDescription) return setInfoP("Offer description can't be empty!");

        setSpinner(true);

        // upload image
        let fd = new FormData();
        fd.append("image", OfferImages);
        fd.append("ImageName", OfferName);
        fd.append("tag", "Offer");
        try {
            let finalImage = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: fd,
                ...backendModule.axiosConfig
            });
            if (finalImage.data.status === "error") {
                setInfoP("Failed to upload image!");
                return setSpinner(false);
            } else {
                OfferImages = finalImage.data.data;
            }
        } catch {
            setInfoP("Server timed out while uploading image!");
            return setSpinner(false);
        };

        // Add offer
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/addOffer`,
            data: {
                BoundAdvertiser,
                SelectedCRM,
                OfferCountry,
                OfferName,
                OfferProductName,
                OfferType,
                OfferPayout,
                PlatformTax,
                OfferLimit,
                OfferProductPrice,
                OfferActive,
                OfferImages,
                OfferDescription,
                OfferProductBrand,
                ReferralPayout,
                CRMID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onUpdate) props.onUpdate();
                setSpinner(false);
            } else {
                setInfoP("An error occured while adding offer");
                setSpinner(false);
            };
        }).catch(() => {
            setInfoP("Server timed out!");
            setSpinner(false);
        });
    };

    return <div className={`route__adminOffers__addOffer ${props.active ? "route__adminOffers__addOffer--active" : ""}`}>
        {spinner && <div className="route__adminOffers__addOffer__spinner">
            <Spinner />    
        </div>}
        {(() => {
            if (!advertiserData) return <Spinner />
            if (advertiserData.status === "error") return <p>{advertiserData.data}</p>
            if (advertiserData.status === "ok") return <>
                <div className="route__adminOffers__addOffer__dropBox">
                    <span>Advertiser</span>
                    <boundAdvertiser.DropBox data={advertiserData.data.map(elem => {
                        return {name: elem.Username, value: elem.ID};
                    })} onChange={() => setSelectedAdvertiser(boundAdvertiser.getSelectedItem().value)} />
                </div>
                {selectedAdvertiser && <>
                    <div className="route__adminOffers__addOffer__dropBox">
                        <span>CRM</span>
                        <boundCRM.DropBox data={JSON.parse(advertiserData.data.find(item => item.ID === selectedAdvertiser).CRMInfo).map(elem =>{
                            return {name: elem.friendlyName, value: elem.ID};
                        })} onChange={() => setSelectedCRM(boundCRM.getSelectedItem().value)} />
                    </div>
                </>}
                {selectedCRM && <>
                    <div className="route__adminOffers__addOffer__dropBox route__adminOffers__addOffer__dropBox--pills">
                        {offerCountries.map(cItem => {
                            return <div className="route__adminOffers__addOffer__dropBox__pill" onClick={() => {
                                setOfferCountries(offerCountries.filter(item => item !== cItem));
                            }}>
                                <img src={`/images/flags/${cItem.replace(/"/g, "")}.png`} />
                                <span>✖</span>
                            </div>
                        })}
                        {offerCountriesVisible && <offerCountryList.DropBox reset={true} data={countries.map(elem => {
                            return {name: elem.name, value: elem.code};
                        })} onChange={() => {
                            setOfferCountries([...offerCountries, offerCountryList.getSelectedItem().value]);
                            setOfferCountriesVisible(false);
                        }} />}
                        {!offerCountriesVisible && <div className="route__adminOffers__addOffer__dropBox__pill" onClick={() => setOfferCountriesVisible(!offerCountriesVisible)}>
                            <span>Add country</span>
                        </div>}
                    </div>
                    <CustomInput ref={offerNameRef} placeholder1="Offer name" />
                    <CustomInput ref={offerProductBrandRef} placeholder1="Offer product brand" />
                    <CustomInput ref={offerProductNameRef} placeholder1="Offer product name" />
                    <div className="route__adminOffers__addOffer__dropBox">
                        <span>Offer Type</span>
                        <offerType.DropBox data={[
                            {name: "Cost per action (CPA)", value: "CPA"},
                            {name: "Cost per lead (CPL)", value: "CPL"}
                        ]} />
                    </div>
                    <CustomInput ref={offerPayoutRef} onChange={performFinalCalculations} placeholder1="Offer payout ($)" />
                    <CustomInput ref={platformTaxRef} onChange={performFinalCalculations} placeholder1="Platform tax (%)" value="20" />
                    <CustomInput ref={offerLimitRef} placeholder1="Offer lead limit" value="1000" />
                    <CustomInput ref={offerProductPriceRef} placeholder1="Offer product price + currency" />
                    <CustomInput ref={refferalPayoutRef} placeholder1="Refferal payout (in $)" value="0" />
                    <CustomInput ref={crmIDRef} placeholder1="CRM Article ID" value="" />
                    <div className="route__adminOffers__addOffer__radio">
                        <span>Offer active</span>
                        <CustomRadio
                            className="route__adminOffers__addOffer__radio__component"
                            valueyes={<p>Yes</p>}
                            valueno={<p>No</p>}
                            functionYes={() => setOfferActive(true)}
                            functionNo={() => setOfferActive(false)}
                        />
                    </div>
                    <div onClick={eDiv => eDiv?.target?.querySelector("input[type=file]")?.click()} className="route__adminOffers__addOffer__image">
                        <img src="" />
                        {!curImage ? <div className="route__adminOffers__addOffer__image__textStatic">
                            Click to add image
                        </div> : <div className="route__adminOffers__addOffer__image__text">
                            Click to change image
                        </div>}
                        <input style={{display: "none"}} type="file" accept="image/*" onChange={eFile => {
                            let fr = new FileReader();
                            fr.onload = eData => {
                                eFile.target.parentNode.querySelector("img").src = eData.target.result;
                            };
                            fr.readAsDataURL(eFile.target.files[0]);
                            setCurImage(eFile.target.files[0]);
                        }} />
                    </div>

                    <br />
                    <h3 style={{marginTop: "20px", marginBottom: "20px"}}>Final calculations:</h3>
                    <div ref={finalCalculationsRef} className="route__adminOffers__addOffer__finalCalc">

                    </div>

                    <div className="route__adminOffers__addUser__offerDesc route__adminOffers__addOffer__quill">
                        <ReactQuill value={offerDescription} onChange={setOfferDescription} style={{width: "100%", height: "100%"}} theme='snow' modules={modules} formats={formats} />
                    </div>
                    <div className="route__adminOffers__addOffer__dropBox">
                        <CustomButtonSmall onClick={verifyAndSendData} value="Save" style={{backgroundColor: "rgb(0, 163, 255)", marginRight: "5px"}} />
                        <CustomButtonSmall onClick={() => props.setActive(false)} style={{marginLeft: "5px"}} value="Cancel" />
                    </div>
                    <div className="route__adminOffers__addOffer__infoP">
                        {infoP && <p>{infoP}</p>}
                    </div>
                </>}
            </>
        })()}
    </div>
};

export default AddOffer