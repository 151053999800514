import React from "react";
import "./index.scss";

import * as backendModule from "../../../../modules/backendModule";
import axios from "axios";
import { useSelector } from "react-redux";

import Spinner from "../../../../components/customComponents/Spinner";
import Dropdown from "../../../../components/customComponents/Dropdown";
import Button from "../../../../components/customComponents/Button";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Radio from "../../../../components/customComponents/Radio";

const CustomPayouts = () => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const [add, setAdd] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customOfferPayouts/getAllPayouts`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customOfferPayouts/getAllPayouts`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };
    
    React.useEffect(() => {
        getData();
    }, [filters]);

    return <div className="route__adminCustomPayouts">
        <button onClick={() => setAdd(!add)} class="route__adminDomains__addDomainBtn">Add custom payout<img src="/images/plusImageWhite.png" /></button>
        <AddCustomPayout isActive={add} onClose={() => {setAdd(false); getData();}} />

        <FilteredCustomTable
            headers={["ID", "Username", "Offer name", "Payout", "Publisher gets"]}
            filterCB={f => setFilters(f)}
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "Username", friendlyName: "Username", type: "string"},
                {name: "OfferName", friendlyName: "Offer name", type: "string"},
                {name: "OfferID", friendlyName: "Offer ID", type: "string"},
                {name: "UserID", friendlyName: "User ID", type: "string"},
                {name: "Payout", friendlyName: "Payout", type: "number"}
            ]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: <p>There was an error while fetching custom payouts</p>}]];

                let final = data.data.map(elem => {
                    return [
                        {keyID: elem.ID, type: "text", text: elem.ID},
                        {keyID: elem.ID, type: "text", text: elem.Username},
                        {keyID: elem.ID, type: "text", text: elem.OfferName},
                        {keyID: elem.ID, type: "text", text: `${Number(elem.Payout).toFixed(2)} $`},
                        {keyID: elem.ID, type: "text", text: `${Number(elem.Payout - (elem.Payout * (elem.offer.PlatformTax / 100))).toFixed(2)} $`},

                        {keyID: elem.ID, type: "groupNewline", group: [
                            {keyID: elem.ID, type: "button", text: "Edit payout", triggerDropdown: true, triggerData: c => <EditPayout c={c} data={elem} onChange={getData} />},
                            {keyID: elem.ID, type: "button", text: "Remove payout", triggerDropdown: true, triggerData: c => <RemovePayout c={c} data={elem} onChange={getData} />},
                        ]}
                    ];
                });
                if (final.length === 0) final.push([{keyID: "noData-text", type: "custom", data: <p>There is nothing to show.</p>}]);
                if (secondarySpinner) final.push([{keyID: "data-paginationSpinner", type: "spinner"}]);
                if (canPaginate) final.push([{keyID: "data-pagination", type: "custom", data: <PaginationData />}]);

                return final;
            })()}
        />
    </div>
};

const AddCustomPayout = props => {
    const [spinner, setSpinner] = React.useState(true);
    const [users, setUsers] = React.useState();
    const [offers, setOffers] = React.useState();
    const [infoP, setInfoP] = React.useState();

    const [selectedUser, setSelectedUser] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [autoCalculateTax, setAutoCalculateTax] = React.useState(false);

    const themeSelector = useSelector(state => state?.theme?.theme ?? "light");
    const payoutRef = React.useRef();
    const finalCalculationsRef = React.useRef();

    const autoTaxCalc = (price, tax) => {
        if (!autoCalculateTax) return price;
        
        let finalTax = 100 - tax;
        let finalNum = price / finalTax;
        return finalNum * 100;
    };

    const addPayout = () => {
        setInfoP("");
        let data = {
            UserID: selectedUser,
            OfferID: selectedOffer,
            Payout: Number(payoutRef.current.value)
        };

        if (!data.UserID) return setInfoP("Please select an user.");
        if (!data.OfferID) return setInfoP("Please select an offer.");
        if (isNaN(data.Payout)) return setInfoP("Payout must be a number");

        if (autoCalculateTax) {
            let curOffer = offers.data.find(o => o.ID === selectedOffer);
            data.Payout = autoTaxCalc(data.Payout, curOffer.PlatformTax);
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customOfferPayouts/addPayout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
            } else {
                setInfoP("There was an error while creating a custom payout!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getUsers = () => {
        setUsers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUsers({
                    status: "ok", data: res.data.data.DBData
                });
            } else {
                setUsers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setUsers({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getOffers = () => {
        setOffers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers({
                    status: "ok", data: res.data.data.DBData
                });
            } else {
                setOffers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setOffers({status: "error", data: "SERVER_ERROR"});
        });
    };

    const performFinalCalculations = () => {
        if (!finalCalculationsRef?.current) return;
        if (!selectedOffer) return finalCalculationsRef.current.innerHTML = `Please select an offer first.`;

        let curOffer = offers.data.find(o => o.ID === selectedOffer);
        if (!curOffer) return finalCalculationsRef.current.innerHTML = `Offer not found?!`;

        let tax = Number(curOffer.PlatformTax);
        let payout = Number(autoTaxCalc(payoutRef.current.value, curOffer.PlatformTax));

        if (isNaN(tax) || isNaN(payout)) finalCalculationsRef.current.innerHTML = `Payout or tax is not a number.`;

        let platformTakes = payout * (curOffer.PlatformTax / 100);
        let publisherGets = payout - platformTakes;

        finalCalculationsRef.current.innerHTML = `
            <p>Advertiser pays: ${Number(curOffer.OfferPayout).toFixed(2)} $</p>
            <p>New payout: ${Number(payout).toFixed(2)} $</p>
            <p>Platform tax: ${Number(curOffer.PlatformTax).toFixed(2)} %</p>
            <p>Publisher gets: ${Number(publisherGets).toFixed(2)} $</p>
            ${publisherGets > curOffer.OfferPayout ? "<p style='color:red;'>Platform is loosing money!</p>" : ""}
        `;
    };

    React.useEffect(() => {
        if (users && offers && spinner) setSpinner(false);
    }, [users, offers]);

    React.useEffect(() => {
        performFinalCalculations()
    }, [autoCalculateTax]);

    React.useEffect(() => {
        if (props.isActive && offers === undefined) getOffers();
        if (props.isActive && users === undefined) getUsers();
    }, [props.isActive]);

    return <div className={`route__adminCustomPayouts__addCustomPayout ${props.isActive ? "route__adminCustomPayouts__addCustomPayout--active" : ""}`}>
        <div className="route__adminCustomPayouts__addCustomPayout__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }} onClick={e => spinner && e.stopPropagation()}>
            <Spinner />
        </div>

        {(offers && users) ? <>
            {(offers.status === "ok" && users.status === "ok") ? <>
                <h3 style={{marginBottom: "20px"}}>Add custom payout</h3>

                <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                    <p style={{marginRight: "20px"}}>User </p> <Dropdown theme={themeSelector} data={users.data.map(usr => {
                        return {name: usr.Username, value: usr.ID};
                    })} onChange={e => setSelectedUser(e?.value)} />
                </div>

                <br />
                <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                    <p style={{marginRight: "20px"}}>Offer </p> <Dropdown theme={themeSelector} data={offers.data.map(offer => {
                        return {name: `${offer.OfferName} (${offer.OfferProductName})`, value: offer.ID};
                    })} onChange={e => setSelectedOffer(e?.value)} />
                </div>

                <br />
                <CustomInput placeholder1="New payout (in $)" ref={payoutRef} onChange={performFinalCalculations} />
                <br />
                <p className="route__adminCustomPayouts__addCustomPayout__radio">
                    <span>Calculate Tax automatically?</span>
                    <Radio selected={autoCalculateTax} valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setAutoCalculateTax(true)} functionNo={() => setAutoCalculateTax(false)} />
                </p>

                <br />
                <div className="route__adminCustomPayouts__addCustomPayout__finalCalc">
                    <h3>Final calculations:</h3>
                    <div ref={finalCalculationsRef}></div>
                </div>

                <Button value="Save" accent="#00A3FF" style={{marginTop: "20px"}} onClick={addPayout} />
                {infoP && <p className="route__adminCustomPayouts__addCustomPayout__infoP">{infoP}</p>}
            </> : <p>There was an error while getting {offers.status === "error" ? "offer" : "users"} data!</p>}
        </> : <Spinner />}
    </div>
};

const EditPayout = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [newPayout, setNewPayout] = React.useState(props.data.Payout);
    const [infoP, setInfoP] = React.useState("");
    const [autoCalculateTax, setAutoCalculateTax] = React.useState(false);

    const payoutRef = React.useRef();
    const finalCalculationsRef = React.useRef();

    const autoTaxCalc = (price, tax) => {
        if (!autoCalculateTax) return price;
        
        let finalTax = 100 - tax;
        let finalNum = price / finalTax;
        return finalNum * 100;
    };

    const performFinalCalculations = () => {
        if (!finalCalculationsRef?.current) return;

        let curOffer = props.data.offer;
        if (!curOffer) return finalCalculationsRef.current.innerHTML = `Offer not found?!`;

        let tax = Number(curOffer.PlatformTax);
        let payout = Number(autoTaxCalc(payoutRef.current.value, curOffer.PlatformTax));

        if (isNaN(tax) || isNaN(payout)) finalCalculationsRef.current.innerHTML = `Payout or tax is not a number.`;

        let platformTakes = payout * (curOffer.PlatformTax / 100);
        let publisherGets = payout - platformTakes;

        finalCalculationsRef.current.innerHTML = `
            <p>Advertiser pays: ${Number(curOffer.OfferPayout).toFixed(2)} $</p>
            <p>New payout: ${Number(payout).toFixed(2)} $</p>
            <p>Platform tax: ${Number(curOffer.PlatformTax).toFixed(2)} %</p>
            <p>Publisher gets: ${Number(publisherGets).toFixed(2)} $</p>
            ${publisherGets > curOffer.OfferPayout ? "<p style='color:red;'>Platform is loosing money!</p>" : ""}
        `;
    };

    const editPayout = () => {
        let data = {
            ID: props.data.ID,
            Payout: Number(newPayout)
        };

        if (isNaN(data.Payout)) return setInfoP("Payout must be a number!");

        if (autoCalculateTax) {
            let curOffer = props.data.offer;
            data.Payout = autoTaxCalc(data.Payout, curOffer.PlatformTax);
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customOfferPayouts/editPayout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.c().then(() => {
                    props.onChange();
                });
            } else {
                setInfoP("There was an error while editing a custom payout!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        performFinalCalculations();
    }, [autoCalculateTax]);

    if (spinner) return <div className="route__adminCustomPayouts__editCustomPayout">
        <Spinner />
    </div>
    if (infoP) return <div className="route__adminCustomPayouts__editCustomPayout">
        <p>{infoP}</p>
        <br />
        <Button value="Close" onClick={props.c} />
    </div>
    return <div className="route__adminCustomPayouts__editCustomPayout">
        <CustomInput ref={payoutRef} value={newPayout} onChange={e => {
            setNewPayout(e?.target?.value);
            performFinalCalculations();
        }} placeholder1="New payout (in $)" />

        <br />
        <p className="route__adminCustomPayouts__addCustomPayout__radio">
            <span>Calculate Tax automatically?</span>
            <Radio selected={autoCalculateTax} valueyes={<p>Yes</p>} valueno={<p>No</p>} functionYes={() => setAutoCalculateTax(true)} functionNo={() => setAutoCalculateTax(false)} />
        </p>

        <br />
        <div className="route__adminCustomPayouts__addCustomPayout__finalCalc">
            <h3>Final calculations:</h3>
            <div ref={finalCalculationsRef}></div>
        </div>
        <br />
        
        <div className="route__adminCustomPayouts__editCustomPayout__btns">
            <Button value="Save" style={{marginRight: "10px", backgroundColor: "rgb(0, 163, 255)"}} onClick={editPayout} />
            <Button value="Cancel" onClick={props.c} />
        </div>
    </div>
};

const RemovePayout = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removePayout = () => {
        let data = {
            ID: props.data.ID,
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/customOfferPayouts/removePayout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.c().then(() => {
                    props.onChange();
                });
            } else {
                setInfoP("There was an error while removing a custom payout!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    if (spinner) return <div className="route__adminCustomPayouts__editCustomPayout">
        <Spinner />
    </div>
    if (infoP) return <div className="route__adminCustomPayouts__editCustomPayout">
        <p>{infoP}</p>
        <br />
        <Button value="Close" onClick={props.c} />
    </div>
    return <div className="route__adminCustomPayouts__editCustomPayout">
        <p>Are you sure?</p>
        <p>This action cannot be undone!</p>
        
        <div className="route__adminCustomPayouts__editCustomPayout__btns">
            <Button value="Save" style={{marginRight: "10px", backgroundColor: "rgb(0, 163, 255)"}} onClick={removePayout} />
            <Button value="Cancel" onClick={props.c} />
        </div>
    </div>
};

export default CustomPayouts;