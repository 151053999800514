import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from '../../../../modules/backendModule'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "../../../../modules/countryModule";

import Button from '../../../../components/customComponents/Button'
import { CustomTable } from "../../../../components/customComponents/Table";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomDropbox from '../../../../components/customComponents/DropBox'
import CustomInput from "../../../../components/customComponents/CustomInput";
import animateModule from "../../../../modules/animateModule";

const Article = () => {
    let Dropbox = CustomDropbox();
    const navigate = useNavigate();
    let timestamp = useSelector((state) => state.timeStamp);
    let userData = useSelector((state) => state.userData)
    const [articleData, setArticleData] = React.useState(null)
    const [domains, setDomains] = React.useState()
    const [prelandings, setPrelandings] = React.useState()
    const [landings, setLandings] = React.useState()
    const [choosenLanding, setChosenLanding] = React.useState('')
    const [choosenPrelanding, setChosenPrelanding] = React.useState('')
    const [chosenDomain, setChosenDomain] = React.useState('')
    const friendlyNameRef = React.useRef();

    const animateNavigate = to => {
        animateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };


    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getSingleOffer`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID,
                ID: `${document.location.hash.replace('#/dashboard/article/', '')}`
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                setArticleData(e.data.data)
                getAllWebSites(e.data.data)
            }
        }).catch((e) => {
        })
    }
    const getAllDomains = () => {
        let domainsTemp = []
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getDomains`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID,
                ID: userData.userData?.UserInfo?.ID,
                showInternal: true
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                e.data.data.map(item => {
                    domainsTemp.push({ name: item.DomainName, value: item.ID })
                })
                setDomains(domainsTemp)

            }


        }).catch((e) => {
        })
    }
    const getAllWebSites = (offerData) => {
        let landingsTemp = []
        let prelandingsTemp = []
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                authUser: userData.userData?.UserInfo?.ID,
                BoundOffer: offerData.ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                e.data.data.map(item => {
                    if (item.WebsiteType === 'Landing') {
                        landingsTemp.push(item)
                    } else {
                        prelandingsTemp.push(item)
                    }
                })
                setPrelandings(prelandingsTemp)
                setLandings(landingsTemp)
            }


        }).catch((e) => {
        })
    }
    React.useEffect(() => {
        getData()
        getAllDomains()
    }, [timestamp])
    const selectLanding = (e, id, link) => {
        let landingstemp = e.target.parentNode.querySelectorAll('p')
        landingstemp.forEach(element => {
            element.classList.remove('activeLanding')
        });
        e.target.classList.add('activeLanding')
        setChosenLanding(id)
        e.target.parentNode.querySelector('a').innerHTML = 'Link preview'
        e.target.parentNode.querySelector('a').href = link
    }
    const selectDomain = (e, id) => {
        let landingstemp = e.target.parentNode.querySelectorAll('p')
        landingstemp.forEach(element => {
            element.classList.remove('activeLanding')
        });
        e.target.classList.add('activeLanding')
        setChosenDomain(id)
    }
    const selectPreLanding = (e, id, link) => {
        let landingstemp = e.target.parentNode.querySelectorAll('p')
        landingstemp.forEach(element => {
            element.classList.remove('activeLanding')
        });
        e.target.classList.add('activeLanding')
        setChosenPrelanding(id)
        if (link !== null) {
            e.target.parentNode.querySelector('a').innerHTML = 'Link preview'
            e.target.parentNode.querySelector('a').href = link
        } else {
            e.target.parentNode.querySelector('a').innerHTML = ''
            e.target.parentNode.querySelector('a').href = link
        }
    }
    const takeOffer = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/takenOffers/addTakenOffer`,
            data: {
                authUser: userData.userData?.UserInfo?.ID,
                BoundOffer: articleData?.ID,
                BoundDomain: chosenDomain,
                BoundLandingWebsite: choosenLanding,
                BoundPreLandingWebsite: choosenPrelanding,
                FriendlyName: friendlyNameRef.current.value
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            if (e.data.status === 'ok') {
                document.querySelector('.article__confirmMessage').style.opacity = 1
                localStorage.setItem('taken', 1)
                setTimeout(() => {
                    animateNavigate('/dashboard/publisher-offers')
                }, 1000)

            }


        }).catch((e) => {
        })
    }

    return <div className="article">

        <div className="article__firstContainer">
            <img className="article__firstContainer__img" src={articleData?.OfferImages} alt='' onError={(e) => { e.target.onerror = null; e.target.src = "/images/slika.png" }} />
            <h1 className="article__firstContainer__content__head">{articleData?.OfferProductName}</h1>
            <div className="article__firstContainer__content">
                <div dangerouslySetInnerHTML={{ __html: articleData?.OfferDescription }}></div>
            </div>
        </div>
        <div className="article__secondContainer">
            <div className="article__secondContainer__informations">
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleBottom article__secondContainer__informations__singleRight"  >
                    <p className="article__secondContainer__informations__single__head">
                        Price:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferProductPrice}
                    </p>
                </div>
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleBottom article__secondContainer__informations__singleRight" >
                    <p className="article__secondContainer__informations__single__head">
                        Payout:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferPayout}$
                    </p>
                </div>
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleBottom article__secondContainer__informations__singleRight" >
                    <p className="article__secondContainer__informations__single__head">
                        Brand:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferProductBrand}
                    </p>
                </div>
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleBottom" >
                    <p className="article__secondContainer__informations__single__head">
                        Limit:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferLimit}/day
                    </p>
                </div>
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleRight" >
                    <p className="article__secondContainer__informations__single__head">
                        Offer type:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferType} ({articleData?.OfferType === 'CPA' ? 'Cost per action' : articleData?.OfferType === 'CPM' ? 'Cost per mile' : 'Cost per click'})
                    </p>
                </div>
                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleRight" >
                    <p className="article__secondContainer__informations__single__head">
                        Country:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferCountry.map(item => {
                            return <div className="publisherOffers__offersContainer__singleOffer__firstContainer__info__tooltip">
                            <img src={`/images/flags/${item.replace(/"/g, '').toLowerCase()}.png`} style={{ position: 'relative', top: '3px' }} alt='Country' />
                            <span style={{marginLeft: "5px"}}>{item.replace(/"/g, "")}</span>
                            <div className="publisherOffers__offersContainer__singleOffer__firstContainer__info__tooltip__container">
                                {(()=>{
                                    let curCountry = countries.find(c => c.code === item.replace(/"/g, ''));
                                    if (!curCountry) return "Unknown";
                                    return curCountry.name;
                                })()}
                            </div>    
                        </div>
                        })}
                    </p>
                </div>



                <div className="article__secondContainer__informations__single article__secondContainer__informations__singleRight" >
                    <p className="article__secondContainer__informations__single__head">
                        Payment type:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        COD (Cash on delivery)
                    </p>
                </div>
                <div className="article__secondContainer__informations__single" >
                    <p className="article__secondContainer__informations__single__head">
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                    </p>
                </div>
                {/* <div className="article__secondContainer__informations__single" >
                    <p className="article__secondContainer__informations__single__head">
                        Brand:
                    </p>
                    <p className="article__secondContainer__informations__single__info" >
                        {articleData?.OfferProductBrand}
                    </p>
                </div> */}
            </div>
        </div>
        <div className="article__thirdContainer">
            <div>
                <p>Select domain:</p>
                <div>
                    {domains?.map(item => {
                        return <p onClick={(e) => { selectDomain(e, item.value) }}>{item.name} &nbsp;</p>
                    })}
                </div>

            </div>

            <div style={chosenDomain === '' ? { opacity: 0 } : { opacity: 1 }}>
                <p>Select prelanding:</p>
                <div>
                    <p onClick={(e) => { selectPreLanding(e, null, null) }} > &nbsp;None &nbsp; </p>
                    {prelandings?.map((item) => {
                        return <p onClick={(e) => { selectPreLanding(e, item.ID, item.URL) }} >&nbsp; {item.WebsiteName} &nbsp; </p>
                    })}
                    <a target='_blank'></a>
                </div>

            </div>
            <div style={choosenPrelanding === '' ? { opacity: 0 } : { opacity: 1 }}>
                <p>Select landing:</p>
                <div>
                    {landings?.map((item) => {
                        return <p onClick={(e) => { selectLanding(e, item.ID, item.URL) }} >&nbsp; {item.WebsiteName} &nbsp; </p>
                    })}
                    <a target='_blank' ></a>
                </div>

            </div>


        </div>
        <div className="article__button" style={choosenLanding === '' ? { opacity: 0 } : { opacity: 1 }}>
            <CustomInput ref={friendlyNameRef} placeholder1="Custom name (optional)" />
            <Button style={{borderRadius: '5px', border: '1px solid #58596E'}} value='Take offer' onClick={() => takeOffer()} />
        </div>
        <div className="article__confirmMessage">
            <p>You have successfully taken offer "{articleData?.OfferProductName}"</p>
        </div>
    </div>
};

export default Article;