import React from "react";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";

import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropBox from "../../../../components/customComponents/DropBox";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import Spinner from "../../../../components/customComponents/Spinner";

const AddWebsite = (props) => {
    let [selectedOffer, setSelectedOffer] = React.useState(null);
    let [curFile, setCurFile] = React.useState(null);
    let [spinner, setSpinner] = React.useState(false);
    let [infoP, setInfoP] = React.useState("");

    let offersDropBox = CustomDropBox();
    let websiteTypeDropBox = CustomDropBox();
    let webbsiteNameRef = React.useRef();
    let websiteEntryFileRef = React.useRef();
    let websitePurchaseCompleteFileRef = React.useRef();

    const addWebsite = () => {
        setInfoP("");
        let WebsiteName = webbsiteNameRef.current.value;
        let WebsiteEntryFile = websiteEntryFileRef.current.value;
        let WebsitePurchaseCompleteFile = websitePurchaseCompleteFileRef.current.value;
        let CurFile = curFile;
        let WebsiteType = websiteTypeDropBox.getSelectedItem()?.value;
        let BoundOffer = offersDropBox.getSelectedItem()?.value;

        if (!WebsiteName) return setInfoP("Website name can't be empty");
        if (!WebsiteEntryFile) return setInfoP("Entry file can't be empty");
        if (!CurFile || !(CurFile instanceof File)) return setInfoP("File not found or invalid");
        if (!WebsiteType) return setInfoP("Website type can't be empty");
        if (!BoundOffer) return setInfoP("Offer can't be empty");

        setSpinner(true);
        let fd = new FormData();
        fd.append("websiteFile", CurFile);
        fd.append("WebsiteName", WebsiteName);
        fd.append("BoundOffer", BoundOffer);
        fd.append("EntryFile", WebsiteEntryFile);
        fd.append("WebsiteType", WebsiteType);
        fd.append("PurchaseCompleteFile", WebsitePurchaseCompleteFile);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/uploadWebsite`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                "Content-Type": "multipart/form-data",
            }
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("There was an error while uploading the website");
            } else {
                if (props.onUpdate) props.onUpdate();
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className={`route__adminWebsites__addWebsite ${props.active ? "route__adminWebsites__addWebsite--active" : ""}`}>
        {spinner && <div className="route__adminWebsites__addWebsite__spinner">
            <Spinner />    
        </div>}
        <div className="route__adminWebsites__addWebsite__container">
            <span>Offer</span>
            <offersDropBox.DropBox selectText="Select an offer" data={props.offers.map(elem => {
                return {name: elem.OfferName, value: elem.ID}
            })} onChange={() => setSelectedOffer(offersDropBox.getSelectedItem().value)} />
        </div>
        {selectedOffer && <>
            <div className="route__adminWebsites__addWebsite__container">
                <span>Website file</span>
                <input onChange={e => e.target?.files[0] && setCurFile(e.target.files[0])} type="file" accept=".zip" />
            </div>
            <div className="route__adminWebsites__addWebsite__container">
                <span>Website type</span>
                <websiteTypeDropBox.DropBox selected={0} data={[
                    {name: "Landing page", value: "Landing"},
                    {name: "Pre-landing page", value: "PreLanding"}
                ]} />
            </div>
            <div className="route__adminWebsites__addWebsite__container">
                <CustomInput placeholder1="Website name" ref={webbsiteNameRef} />
            </div>
            <div className="route__adminWebsites__addWebsite__container">
                <CustomInput placeholder1="Entry file" ref={websiteEntryFileRef} />
            </div>
            <div className="route__adminWebsites__addWebsite__container">
                <CustomInput placeholder1="Thankyou file" ref={websitePurchaseCompleteFileRef} />
            </div>
            <div className="route__adminWebsites__addWebsite__container">
                <CustomButtonSmall onClick={addWebsite} value="Save" style={{backgroundColor: "rgb(0, 163, 255)", color: "white", marginRight: "5px"}} />
                <CustomButtonSmall onClick={() => props.setActive(false)} style={{marginLeft: "5px"}} value="Cancel" />
            </div>
            <div className="route__adminWebsites__addWebsite__infoP">
                {infoP && <p>{infoP}</p>}
            </div>
        </>}
    </div>
};

export default AddWebsite;