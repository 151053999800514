import React from "react";
import "./index.scss";
import moment from "moment";

import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getLeadColor } from "../../../../modules/leadModule";

import Dropdown from "../../../../components/customComponents/Dropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import FilterByDate from "../../../../components/filters/FilterByDate";

let curTimeout = null;
const LeadStats = () => {
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const [statusCodes, setStatusCodes] = React.useState();
    const [selectedPublisher, setSelectedPublisher] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();
    const [shownLeads, setShownLeads] = React.useState();
    const [leadsShown, setLeadsShown] = React.useState();
    const [dropdownActive, setDropdownActive] = React.useState(false);
    const [statusFilters, setStatusFilters] = React.useState();


    const timestampRef = React.useRef();
    const mainRef = React.useRef();

    const getLeadStatusCodes = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getStatusCodes`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setStatusCodes(res.data);

            if (res.data.status === "ok") {
                setStatusFilters(Object.keys(res.data.data));
            };
        }).catch(() => {
            setStatusCodes({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getUsers = () => {
        setUsers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUsers({
                    status: "ok", data: [
                        ...(users?.data ?? []),
                        ...res.data.data?.DBData
                    ]
                });
            } else {
                setUsers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setUsers({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getDateRanges = () => {
        let tmp = [];
        let ds = moment(selectedDate.start);
        let de = moment(selectedDate.end);

        while (ds.isBefore(de)) {
            tmp.push(ds.format("DD.MM.YYYY"));
            ds = ds.add(1, "days");
        };

        return tmp;
    };

    const getData = (ts) => {
        if (!statusFilters) return;
        if (!selectedDate) return;
        if (timestampRef.current !== ts) return;

        let filters = [];
        if (selectedPublisher) {
            filters.push({name: "BoundUser", op: "eq", value: selectedPublisher});
        };
        if (statusFilters && Array.isArray(statusFilters)) {
            filters.push({name: "Status", op: "in", value: statusFilters});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadStatsForFrontendAdminDashboard`,
            data: {
                filters,
                startDate: selectedDate.start.toDate().getTime(),
                endDate: selectedDate.end.toDate().getTime(),
                utcOffset: (new Date()).getTimezoneOffset()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                let tmpRanges = getDateRanges();
                for (let item of tmpRanges) {
                    if (!res.data.data.allLeads.find(l => l.LeadDate === item)) {
                        res.data.data.allLeads.push({
                            LeadDate: item,
                            LeadCount: 0,
                            ConvertedLeads: 0,
                            BadLeads: 0,
                            UniqueLeads: 0,
                            LeadInfo: []
                        });
                    };
                };

                res.data.data.allLeads = res.data.data.allLeads.sort((a, b) => {
                    let aDate = moment(a.LeadDate, "DD.MM.YYYY");
                    let bDate = moment(b.LeadDate, "DD.MM.YYYY");

                    return aDate.isAfter(bDate) ? 1 : -1;
                });
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getDateDiff = () => {
        if (!selectedDate) return "?";
        let tmp = selectedDate.end.diff(selectedDate.start, "days");
        if (tmp === 0) tmp = 1; else tmp++;
        return tmp;
    };

    const changeLeadsShown = (id) => {
        if (leadsShown === id) return;
        if (!mainRef.current) return setLeadsShown(id);

        mainRef.current.querySelectorAll("*[data-id]").forEach(elem => {
            elem.style.backgroundColor = null;
            elem.style.color = null;
        });

        let curElem = mainRef.current.querySelector(`*[data-id=${id}]`);
        if (!curElem) return setLeadsShown(id);

        curElem.style.backgroundColor = "#00A3FF";
        curElem.style.color = "white";
        setLeadsShown(id);
    };

    React.useEffect(() => {
        clearTimeout(curTimeout);

        if (!selectedDate) return;
        setData();
        setShownLeads();
        changeLeadsShown();

        let ts = Date.now();
        timestampRef.current = ts;
        curTimeout = setTimeout(() => getData(ts), 1000);
    }, [selectedDate, selectedPublisher, statusFilters]);

    React.useEffect(() => {
        getUsers();
        getLeadStatusCodes();
    }, []);

    return <div ref={mainRef} className="rotue__adminPanel__leadStats">
        <div className="rotue__adminPanel__leadStats__heading">
            <div className="rotue__adminPanel__leadStats__heading__left">Lead report</div>
            <div className="rotue__adminPanel__leadStats__heading__right">
                <FilterByDate disableAll={true} disable24h={true} onChange={e => setSelectedDate(e)} />
                <Dropdown
                    theme="dark"
                    data={[
                        {name: "All publishers", value: null},
                        ...(users?.status === "ok" ? users.data.map(usr => {
                            return {name: usr.Username, value: usr.ID};
                        }) : [])
                    ]}
                    selected={(()=>{
                        if (!users) return null;
                        if (users.status !== "ok") return null;
                        if (!selectedPublisher) return 0;
                        return users.data.indexOf(users.data.find(usr => usr.ID === selectedPublisher)) + 1;
                    })()}
                    onChange={e => setSelectedPublisher(e?.value)}
                />
                <div className="rotue__adminPanel__leadStats__heading__right__statusFilters" onClick={e => {
                    e.stopPropagation();
                    setDropdownActive(e => !e);
                }}>
                    <p>Status {Array.isArray(statusFilters) ? (`(${statusFilters.length})`) : ""}</p>

                    <div className={`rotue__adminPanel__leadStats__heading__right__statusFilters__dropdown ${dropdownActive ? "rotue__adminPanel__leadStats__heading__right__statusFilters__dropdown--active" : ""}`} onClick={e => e?.stopPropagation()}>
                        {statusCodes ? <>
                            {statusCodes.status === "ok" ? <>
                                {Object.keys(statusCodes.data).map(code => {
                                    return <div className="rotue__adminPanel__leadStats__heading__right__statusFilters__dropdown__item" onClick={e => {
                                        e?.stopPropagation();
                                        e?.preventDefault();
                                        if (!Array.isArray(statusFilters)) return;

                                        if (statusFilters.includes(code)) {
                                            setStatusFilters(sf => sf.filter(sff => sff !== code));
                                        } else  {
                                            setStatusFilters(sf => [...sf, code]);
                                        };
                                    }}>
                                        <input type="checkbox" checked={Array.isArray(statusFilters) ? statusFilters.includes(code) : false} />
                                        <span>{statusCodes.data[code]}</span>
                                    </div>
                                })}
                            </> : <p className="rotue__adminPanel__leadStats__infoP">Error while fetching statuses!</p>}
                        </> : <Spinner style={{width: "32px", height: "32px", background: "transparent", position: "relative"}} spinnerWidth="32px" spinnerHeight="32px" />}
                    </div>
                </div>
            </div>
        </div>

        {((selectedDate && data?.status !== "error") && (statusCodes && statusCodes !== "error") && Array.isArray(statusFilters)) && <>
            <div className="rotue__adminPanel__leadStats__subheading">General report for {getDateDiff()} day{getDateDiff() > 1 ? "s" : ""}</div>

            <div className="rotue__adminPanel__leadStats__box">
                <GeneralReport changeLeadsShown={id => changeLeadsShown(id)} data={data} selected={e => setShownLeads(e)} />
            </div>
            <div className="rotue__adminPanel__leadStats__box">
                <OfferDayReport changeLeadsShown={id => changeLeadsShown(id)} data={data} selectedDate={selectedDate} selected={e => setShownLeads(e)} resetShownLeads={() => setShownLeads()} />
            </div>
            <div className="rotue__adminPanel__leadStats__box rotue__adminPanel__leadStats__box--right">
                <LeadList leads={shownLeads} />
            </div>
        </>}
        {data?.status === "error" && <div className="rotue__adminPanel__leadStats__infoP">
            Error while fetching data!
        </div>}
    </div>
};

const GeneralReport = props => {
    let curID = 0;

    const calculateAR = (total, converted) => {
        let tmp = (100 / total) * converted;
        if (isNaN(tmp)) tmp = 0;
        if (!isFinite(tmp)) tmp = 0;

        return `${tmp.toFixed(0)} %`
    };

    const calculateTotals = () => {
        let totals = {
            LeadCount: 0,
            UniqueLeads: 0,
            ConvertedLeads: 0,
            BadLeads: 0
        };

        for (let item of props.data.data.allLeads) {
            for (let key of Object.keys(item)) {
                if (Object.keys(totals).includes(key)) totals[key] += item[key];
            };
        };

        return totals;
    };

    if (!props.data) return <Spinner style={{
        position: "relative",
        backgroundColor: "transparent"
    }} />

    return <div className="rotue__adminPanel__leadStats__box__table">
        <div className="rotue__adminPanel__leadStats__box__table__head">Date</div>
        <div className="rotue__adminPanel__leadStats__box__table__head">Generated</div>
        <div className="rotue__adminPanel__leadStats__box__table__head">Unique</div>
        <div className="rotue__adminPanel__leadStats__box__table__head">Approved</div>
        <div className="rotue__adminPanel__leadStats__box__table__head">AR%</div>
        <div className="rotue__adminPanel__leadStats__box__table__head">Action</div>

        {props.data.data.allLeads.map(l => {
            return <>
                <div className="rotue__adminPanel__leadStats__box__table__item">{moment(l.LeadDate, "DD.MM.YYYY").toDate().toLocaleDateString()}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item">{l.LeadCount}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item">{l.UniqueLeads}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item">{l.ConvertedLeads}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item">{calculateAR(l.LeadCount, l.ConvertedLeads)}</div>
                <div data-id={`gr-${curID++}`} className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--action" onClick={(e) => {
                    props.selected(l.LeadInfo);
                    props.changeLeadsShown(e.target.getAttribute("data-id"));
                }}>Show</div>
            </>;
        })}

        {(()=>{
            let totals = calculateTotals();

            return <>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total1">Total</div>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.LeadCount}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.UniqueLeads}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.ConvertedLeads}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{calculateAR(totals.LeadCount + totals.BadLeads, totals.ConvertedLeads)}</div>
                <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2"></div>
            </>;
        })()}
    </div>
};

const OfferDayReport = props => {
    const [selectedDate, setSelectedDate] = React.useState();

    let curID = 0;

    const getDates = () => {
        let dates = [];

        if (!props.selectedDate) return dates;

        let datesObj = {
            start: moment(props.selectedDate.start.toDate().getTime()),
            end: moment(props.selectedDate.end.toDate().getTime())
        };
        while (datesObj.start.isBefore(datesObj.end)) {
            dates.push(datesObj.start.format("DD.MM.YYYY"));
            datesObj.start = datesObj.start.add(1, "days");
        };

        return dates;
    };

    const calculateAR = (total, converted) => {
        let tmp = (100 / total) * converted;
        if (isNaN(tmp)) tmp = 0;
        if (!isFinite(tmp)) tmp = 0;

        return `${tmp.toFixed(0)} %`
    };

    const calculateTotals = () => {
        let totals = {
            LeadCount: 0,
            UniqueLeads: 0,
            ConvertedLeads: 0,
            BadLeads: 0
        };

        for (let item of props.data.data.allOffers[selectedDate]) {
            for (let key of Object.keys(item)) {
                if (Object.keys(totals).includes(key)) totals[key] += item[key];
            };
        };

        return totals;
    };

    React.useEffect(() => {
        let tmp = getDates();
        if (tmp.length === 0) return setSelectedDate(null);

        setSelectedDate(tmp[0]);
    }, [props.selectedDate]);

    React.useEffect(() => {
        props.resetShownLeads();
        props.changeLeadsShown();
    }, [selectedDate]);

    if (!props.data) return <Spinner style={{
        position: "relative",
        backgroundColor: "transparent"
    }} />

    return <>
        <div className="rotue__adminPanel__leadStats__subheading" style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
            Daily report

            {selectedDate && <Dropdown
                theme="dark"
                style={{width: "max-content"}}
                data={getDates().map(d => {
                    return {name: moment(d, "DD.MM.YYYY").toDate().toLocaleDateString(), value: d}
                })}
                selected={(()=>{
                    let tmp = getDates();
                    return tmp.indexOf(selectedDate);
                })()}
                onChange={e => {
                    if (selectedDate !== e?.value) setSelectedDate(e?.value);
                }}
            />}
        </div>

        <div className="rotue__adminPanel__leadStats__box__table" style={{gridTemplateColumns: "300px repeat(5, auto)"}}>
            <div className="rotue__adminPanel__leadStats__box__table__head">Offer</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Generated</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Unique</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Approved</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">AR%</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Action</div>
            
            {selectedDate && <>
                {(props.data.data.allOffers[selectedDate] ?? []).map(l => {
                    return <>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--fixed"><span>{l.OfferName}</span></div>
                        <div className="rotue__adminPanel__leadStats__box__table__item">{l.LeadCount}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item">{l.UniqueLeads}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item">{l.ConvertedLeads}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item">{calculateAR(l.LeadCount + l.BadLeads, l.ConvertedLeads)}</div>
                        <div data-id={`odr-${curID++}`} className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--action" onClick={(e) => {
                            props.selected(l.LeadInfo);
                            props.changeLeadsShown(e.target.getAttribute("data-id"));
                        }}>Show</div>
                    </>; 
                })}

                {(props.data.data.allOffers[selectedDate] ?? []).length === 0 ? <div className="rotue__adminPanel__leadStats__box__table__item" style={{gridColumn: "1 / span 6"}}>No offers found</div> : (()=>{
                    let totals = calculateTotals();

                    return <>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total1">Total</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.LeadCount}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.UniqueLeads}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{totals.ConvertedLeads}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2">{calculateAR(totals.LeadCount + totals.BadLeads, totals.ConvertedLeads)}</div>
                        <div className="rotue__adminPanel__leadStats__box__table__item rotue__adminPanel__leadStats__box__table__item--total2"></div>
                    </>;
                })()}
            </>}
        </div>
    </>
};

const LeadList = props => {
    const prepareLeads = () => {
        let out = {};
        for (let item of props.leads) {
            if (!out[item.IP]) out[item.IP] = {Generated: 0, Approved: 0, Rejected: 0};

            out[item.IP].Generated += 1;
            if (item.Status === 2) {
                out[item.IP].Approved += 1;
            } else if ([3,4,5].includes(item.Status)) {
                out[item.IP].Rejected += 1;
            };
        };

        return Object.keys(out).map(key => {
            return {
                IP: key,
                ...out[key]
            };
        });
    };

    if (!props.leads) return <div className="rotue__adminPanel__leadStats__box__leadListNoData">Select a date / offer to see lead list</div>

    return <div className="rotue__adminPanel__leadStats__box__leadList">

        <div className="rotue__adminPanel__leadStats__box__table" style={{gridTemplateColumns: "200px repeat(3, auto)"}}>
            <div className="rotue__adminPanel__leadStats__box__table__head">IP</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Generated</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Approved</div>
            <div className="rotue__adminPanel__leadStats__box__table__head">Rejected</div>

            {prepareLeads().map(l => {
                return <>
                    <div className="rotue__adminPanel__leadStats__box__table__item">{l.IP}</div>
                    <div className="rotue__adminPanel__leadStats__box__table__item">{l.Generated}</div>
                    <div className="rotue__adminPanel__leadStats__box__table__item">{l.Approved}</div>
                    <div className="rotue__adminPanel__leadStats__box__table__item">{l.Rejected}</div>
                </>
            })}
        </div>

    </div>
};

export default LeadStats;