
import Footer from "../../components/Footer";
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';


const Advertisers = () => {
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    return <div className="publishers" >
        <div className="publishers__firstScreen" >
            <img src="/images/landing/advertisersImage.png" className="publishers__firstScreen__image" />
            <h1>For advertisers</h1>
            <p>If you're not sure this is the right marketing investment for you, here are the facts:
            </p>
            <h2><img src="/images/landing/tableCheck.png" />Vetted publishers </h2>
            <h2><img src="/images/landing/tableCheck.png" />Anti-Fraud team and AI monitoring system </h2>
            <h2><img src="/images/landing/tableCheck.png" />Dedicated affiliate manager working on your perfect affiliate strategy</h2>
            <p><spam onClick={() => { animateNavigate('/register') }} >Register </spam>
                and start your journey or send us an <a href="mailto: info@scale-lead.com" >email</a> and find out how we can help you reach your goals.</p>
        </div>

        <Footer />
    </div>
};


export default Advertisers
