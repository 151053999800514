
import "./index.scss";
import React from "react";
import Footer from "../../components/Footer";
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


const AboutUs = () => {
    const navigate = useNavigate();
    const curLocation = useLocation();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const [sliders, setSliders] = React.useState([
        {
            Name: 'Senad Bećirović',
            Title: 'CEO & co-founder',
            Image: '/images/aboutus/senad.png'
        }, {
            Name: 'Ešef Ćosić',
            Title: 'CEO & co-founder',
            Image: '/images/aboutus/esef.png'
        }, {
            Name: 'Sulejman Hodžić',
            Title: 'Full-stack developer & team leader',
            Image: '/images/aboutus/suljo.png'
        },
        {
            Name: 'Nevzet Šabanovski',
            Title: 'Full-stack developer',
            Image: '/images/aboutus/nevzet.png'
        }, {
            Name: 'Aldina Čizmić',
            Title: 'Affiliate manager',
            Image: '/images/aboutus/aldina.png'
        }, {
            Name: 'Zoriana Myronchuk',
            Title: 'Account manager',
            Image: '/images/aboutus/zoriana.png'
        }, {
            Name: 'Vladanka Teovanović',
            Title: 'Affiliate manager',
            Image: '/images/aboutus/vladanka.png'
        },
    ])

    const [activeSlider, setActiveSlider] = React.useState(0)

    React.useEffect(() => {
        moveActiveSlide(0)
    }, [])
    const moveActiveSlide = (id) => {
        if (id === -1) {
            id = sliders.length - 1
        }
        if (id === sliders.length) {
            id = 0
        }
        let counter1 = 0
        let counter2 = id
        let positions = ['34%', '75%', '100%', '100%', '-100%', '-100%', '0px']
        let positionsTablet = ['0%', '60%', '100%', '100%', '-100%', '-100%', '-100%']
        let positionsMobile = ['calc(50% - 140px )', '100%', '100%', '100%', '-100%', '-100%', '-100%']
        let visibility = [1, 1, 1, 0, 0, 1, 1]
        let slTemp = document.querySelectorAll('.custom__carousel__container__slide')
        let dots = document.querySelectorAll('.custom__carousel__container__dots__dot')
        while (counter1 < sliders.length) {
            if (window.innerWidth > 1024) {
                slTemp[counter2].style.left = positions[counter1]
            } else if (window.innerWidth > 700) {
                slTemp[counter2].style.left = positionsTablet[counter1]
            } else {
                slTemp[counter2].style.left = positionsMobile[counter1]
            }

            slTemp[counter2].style.opacity = visibility[counter1]
            if (counter1 === 0) {
                if (window.innerWidth > 1024) {
                    slTemp[counter2].style.width = '32%'
                } else if (window.innerWidth > 700) {
                    slTemp[counter2].style.width = '45%'
                    slTemp[counter2].querySelector('.custom__carousel__container__slide__head__image').style.height = 'auto'
                } else {
                    slTemp[counter2].style.width = '280px'
                }

                slTemp[counter2].style.filter = 'grayscale(0)'
                slTemp[counter2].style.zIndex = '5'
            } else {
                slTemp[counter2].style.filter = 'grayscale(1)'

                slTemp[counter2].style.zIndex = '1'
                if (window.innerWidth > 1024) {
                    slTemp[counter2].style.width = '25%'
                } else if (window.innerWidth > 700) {
                    slTemp[counter2].querySelector('.custom__carousel__container__slide__head__image').style.height = 'auto'
                    slTemp[counter2].style.width = '40%'
                }
            }
            if (counter2 === sliders.length - 1) {
                counter2 = 0
            } else {
                counter2++
            }
            counter1++

        }
        setActiveSlider(id)

        for (let i = 0; i < dots.length; i++) {
            if (i !== id) {
                dots[i].style.backgroundColor = 'AF6CF4'
                dots[i].style.border = 'none'
            } else {
                dots[i].style.backgroundColor = 'white'
                dots[i].style.border = '1px solid #6664E5'
            }
        }


    }
    const setActive = (id) => {
        let slideTemp = activeSlider
        let counter = 0
        if (id > slideTemp) {
            while (slideTemp !== id) {

                moveActiveSlide(slideTemp + 1)
                slideTemp++
                counter++
            }

        } else if (id < slideTemp) {
            while (slideTemp !== id) {
                moveActiveSlide(slideTemp - 1)
                slideTemp--
                counter++
            }
        }

    }
    let touchstartX = 0;
    let touchendX = 0;
    let container
    React.useEffect(() => {
        document.querySelector('.component__footer__contact').style.display = 'none'
    }, [])
    function handleGesure() {
        if (touchendX < touchstartX - 100) {
            moveActiveSlide(activeSlider + 1)
        }
        if (touchendX > touchstartX + 100) {
            moveActiveSlide(activeSlider - 1)
        }

    }

    return <div className="aboutus">
        <img className="login__image" src='/images/landing/logibimage.png'  />
        <img className="login__image" src='/images/landing/logibimage.png' />
        <img className="login__maskMobile" src="/images/landing/mobileimageup.png" />
        <div className="aboutus__affiliate">
            <h1>Upcoming Conferences</h1>
            <div className="aboutus__affiliate__upcoming">
                <div className="aboutus__affiliate__upcoming__single">
                    <div
                    onClick={()=>{animateNavigate('/conference?city=barcelona')}}
                    className="aboutus__affiliate__upcoming__single__top" style={{ 'backgroundImage': `url(/images/barcelona.jpg)` }}>
                        <div className="aboutus__affiliate__upcoming__single__top__content">
                            <h2>EUROPE</h2>
                            <p>6-7 July 2022</p>
                            <p>BARCELONA, SPAIN</p>
                            <img src="/images/logoNewWhite.svg" />
                        </div>
                    </div>
                    <div className="aboutus__affiliate__upcoming__single__line">

                    </div>
                    <div className="aboutus__affiliate__upcoming__single__bottom">
                        <h3>AFFILIATE WORLD EUROPE</h3>
                        <h2>BARCELONA</h2>
                        <p>You will network with the industry’s brightest minds,
                            absorb mastermind-level content on stage, and be exposed
                            to an exhibition floor overflowing with opportunities to
                            push your career forward</p>
                        <button onClick={()=>{animateNavigate('/conference?city=barcelona')}}>Find out more &#8594;</button>
                    </div>
                </div>
                <div className="aboutus__affiliate__upcoming__single">
                    <div
                    onClick={()=>{animateNavigate('/conference?city=bangkok')}}
                    className="aboutus__affiliate__upcoming__single__top" style={{ 'backgroundImage': `url(/images/bangkok.jpg)` }}>
                        <div className="aboutus__affiliate__upcoming__single__top__content">
                            <h2>ASIA</h2>
                            <p>30 Nov - 1 Dec 2022</p>
                            <p>BANGKOK, THAILAND</p>
                            <img src="/images/logoNewWhite.svg" />

                        </div>
                    </div>
                    <div className="aboutus__affiliate__upcoming__single__line">

                    </div>
                    <div className="aboutus__affiliate__upcoming__single__bottom">
                        <h3>AFFILIATE WORLD ASIA</h3>
                        <h2>BANGKOK</h2>
                        <p>Affiliate World is the world’s premier gathering of influential performance and ecommerce marketers.</p>
                        <button onClick={()=>{animateNavigate('/conference?city=bangkok')}}>Find out more &#8594;</button>
                    </div>
                </div>
            </div>
            <h1 style={{ 'marginTop': '40px' }}>Previous Conferences</h1>
            <div className="aboutus__affiliate__upcoming">

                <div className="aboutus__affiliate__upcoming__single">
                    <div 
                    onClick={()=>{animateNavigate('/conference?city=dubai')}}
                    className="aboutus__affiliate__upcoming__single__top" style={{ 'backgroundImage': `url(/images/dubai.jpg)` }}>
                        <div className="aboutus__affiliate__upcoming__single__top__content">
                            <h2>DUBAI</h2>
                            <p>28 February - 1 March 2022</p>
                            <p>DUBAI, UAE</p>
                            <img src="/images/logoNewWhite.svg" />

                        </div>
                    </div>
                    <div className="aboutus__affiliate__upcoming__single__line">

                    </div>
                    <div className="aboutus__affiliate__upcoming__single__bottom">
                        <h3>AFFILIATE WORLD GLOBAL</h3>
                        <h2>DUBAI</h2>
                        <p>For the first time ever, two Affiliate World events will collide for the largest gathering of
                            the world’s top affiliate marketers and ecommerce entrepreneurs.</p>
                        <button onClick={()=>{animateNavigate('/conference?city=dubai')}}>Find out more &#8594;</button>
                    </div>
                </div>
                <div className="aboutus__affiliate__upcoming__single">
                    <div
                    onClick={()=>{animateNavigate('/conference?city=moscow')}}
                    className="aboutus__affiliate__upcoming__single__top" style={{ 'backgroundImage': `url(/images/moscow.jpg)` }}>
                        <div className="aboutus__affiliate__upcoming__single__top__content">
                            <h2>MOSCOW</h2>
                            <p>5-6 October 2021</p>
                            <p>MOSCOW, RUSSIA</p>
                            <img src="/images/logoNewWhite.svg" />
                        </div>
                    </div>
                    <div className="aboutus__affiliate__upcoming__single__line">

                    </div>
                    <div className="aboutus__affiliate__upcoming__single__bottom">
                        <h3> MOSCOW AFFILIATE CONFERENCE</h3>
                        <h2>MOSCOW</h2>
                        <p>Moscow Affiliate Conference or MAC is the biggest marketing event in CIS, with more than 50 countries participating this year.
                            It is a grand opportunity to network and learn new ways to monetize content with 3000 participants and 30 reports to boost yields.</p>
                        <button onClick={()=>{animateNavigate('/conference?city=moscow')}}>Find out more &#8594;</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="custom__carousel" >
            <img src="/images/aboutus/sliderimg.png" className="custom__carousel__arrow" onClick={() => { moveActiveSlide(activeSlider + 1) }} />
            <h1>Meet our team</h1>
            <div className="custom__carousel__container" onTouchStart={(e) => { touchstartX = e.changedTouches[0].screenX }} onTouchEnd={(e) => { touchendX = e.changedTouches[0].screenX; handleGesure() }}>

                {/* <div className="custom__carousel__container__arrowLeft" onClick={() => { moveActiveSlide(activeSlider - 1) }}>
                <img src="/img/arrowdown.svg" />
            </div>
            <div className="custom__carousel__container__arrowRight" onClick={() => { moveActiveSlide(activeSlider + 1) }}>
                <img src="/img/arrowdown.svg" />
            </div> */}
                {sliders.map((item, index) => {
                    return <div className="custom__carousel__container__slide" >
                        <div className="custom__carousel__container__slide__head">
                            <div>
                                <h2>{item.Name}</h2>
                                <h3>{item.Title}</h3>
                            </div>
                            <div className="custom__carousel__container__slide__head__image" >
                                <img className="custom__carousel__container__slide__head__image__picture" src={item.Image} />
                            </div>


                        </div>
                    </div>
                })}



            </div>
            <img className="swipeImage" src="/images/swipe.png" />

        </div>
        <Footer />

    </div>
};

export default AboutUs;