import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import * as backendModule from "../../modules/backendModule";

import Spinner from "../../components/customComponents/Spinner";

const LeadCRMInfo = props => {
    const [data, setData] = React.useState();

    const convertDuration = dur => {
        dur = Number(dur);
        if (isNaN(dur)) dur = 0;
        if (dur < 0) dur = 0;

        let hours = 0;
        let minutes = 0;
        let seconds = dur / 1000;

        while (seconds > 59) {
            seconds -= 60;
            minutes++;
        };
        while (minutes > 59) {
            minutes -= 60;
            hours++;
        };

        return `${hours}h : ${minutes}m : ${seconds}s`;
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadCRMInfo`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData({status: "error", data: "GENERIC_ERROR"});
        });
    }, []);

    return <div className="component__leadCRMInfo">
        <div className="component__leadCRMInfo__wrap">
            <div className="component__leadCRMInfo__wrap__head">
                <div className="component__leadCRMInfo__wrap__head__text">CRM Info</div>
                <img className="component__leadCRMInfo__wrap__head__close" src="/images/closeLight.svg" onClick={props.onClose} />
            </div>
            {data ? <>
                {data.status === "ok" ? <>
                    <p className="component__leadCRMInfo__wrap__headInfo">
                        <span>CRM ID: &nbsp;</span>
                        <span>{data.data.CRMID}</span>
                    </p>
                    <p className="component__leadCRMInfo__wrap__headInfo">
                        <span>CRM Status: &nbsp;</span>
                        <span>{data.data.Status}</span>
                    </p>

                    <p className="component__leadCRMInfo__wrap__line"></p>
                    <p className="component__leadCRMInfo__wrap__headInfo"><span>CRM Activities:</span><span></span></p>
                    <div className="component__leadCRMInfo__wrap__comments">
                        {data.data.Comments.map(comment => {
                            return <div className="component__leadCRMInfo__wrap__comments__comment">
                                <p className="component__leadCRMInfo__wrap__comments__comment__text">
                                    <span>Date:</span>
                                    <span>&nbsp;{moment(comment.createdAt).toDate().toLocaleString()}</span>
                                </p>
                                <p className="component__leadCRMInfo__wrap__comments__comment__text">
                                    <span>Agent ID:</span>
                                    <span>&nbsp;{comment.AgentID}</span>
                                </p>
                                <p className="component__leadCRMInfo__wrap__comments__comment__text">
                                    <span>Type:</span>
                                    <span>&nbsp;{comment.Type}</span>
                                </p>
                                {comment.Type === "audio" ? <>
                                    <p className="component__leadCRMInfo__wrap__comments__comment__text">
                                        <span>Duration:</span>
                                        <span>&nbsp;{convertDuration(comment.audioDuration)}</span>
                                    </p>
                                </> : <>
                                    <p className="component__leadCRMInfo__wrap__comments__comment__text">
                                        <span>Agent comment:</span>
                                        <span>&nbsp;{comment.Comment}</span>
                                    </p>
                                </>}
                            </div>
                        })}
                    </div>
                </> : <p className="component__leadCRMInfo__wrap__error">Current CRM is not supported!</p>}
            </> : <Spinner />}
        </div>
    </div>
};

export default LeadCRMInfo;