import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../modules/backendModule";
import animateModule from "../../../../modules/animateModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";

const Invoices = props => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const curTimestampSelector = useSelector(state => state?.timestamp?.timestamp ?? null);
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/getAllInvoices`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/invoices/getAllInvoices`,
            data: {
                offset: 0,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    const DisplayInvoiceInfo = (props) => {

        return <div className="route__adminUsers__userInfo">
            <p>ID: {props.data.ID}</p>
            <p>Invoice ID: {props.data.InvoiceID}</p>
            <p>Advertiser ID: {props.data.BoundAdvertiser}</p>
            <p>Advertiser name: {props.data.Advertiser}</p>
            <p>Article: {props.data.Name}</p>
            <p>Amount: {Number(props.data.Amount).toFixed(2)} $</p>
            <p>Paid: {props.data.isPaid ? <span style={{color: "#53ea53"}}>Yes</span> : <span style={{color: "#ff6464"}}>No</span>}</p>
            <p>Date: {new Date(props.data.createdAt).toLocaleString()}</p>
        </div>
    };

    React.useEffect(() => {
        getData();
    }, [curTimestampSelector, filters]);

    return <div className="route__adminInvoices">
        <FilteredCustomTable
            headers={["Invoice ID", "Advertiser", "Amount", "Paid"]}
            filterCB={f => setFilters(f)}
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "BoundAdvertiser", friendlyName: "Advertiser ID", type: "string"},
                {name: "Name", friendlyName: "Article", type: "string"},
                {name: "InvoiceID", friendlyName: "Invoice ID", type: "number"},
                {name: "Amount", friendlyName: "Amount", type: "number"},
                {name: "isPaid", friendlyName: "Is paid", type: "boolean"}
            ]}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: <p>There was an error while fetching invoices</p>}]];

                let final = data.data.map(elem => {
                    return [
                        {keyID: elem.ID, type: "text", text: elem.InvoiceID},
                        {keyID: elem.ID, type: "text", text: elem.Advertiser},
                        {keyID: elem.ID, type: "text", text: Number(elem.Amount).toFixed(2) + " $"},
                        {keyID: elem.ID, type: "text", text: elem.isPaid ? <span style={{color: "#53ea53"}}>Yes</span> : <span style={{color: "#ff6464"}}>No</span>},
                        {keyID: elem.ID, type: "groupNewline", group: [
                            {keyID: elem.ID, type: "button", text: "More info", triggerDropdown: true, triggerData: c => <DisplayInvoiceInfo data={elem} />},
                            {keyID: elem.ID, type: "button", text: "Go to advertiser account", onClick: () => animateNavigate(`/dashboard/admin-advertisers?ID=${encodeURI(elem.BoundAdvertiser)}`) }
                        ]}
                    ];
                });
                if (final.length === 0) final.push([{keyID: "noData-text", type: "custom", data: <p>There is nothing to show.</p>}]);
                if (secondarySpinner) final.push([{keyID: "data-paginationSpinner", type: "spinner"}]);
                if (canPaginate) final.push([{keyID: "data-pagination", type: "custom", data: <PaginationData />}]);

                return final;
            })()}
        />
    </div>
};

export default Invoices;