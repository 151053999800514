import './index.scss';
import React from 'react';
import CustomInput from '../../components/customComponents/CustomInput'
import Button from '../../components/customComponents/Button'
import Spinner from '../../components/customComponents/Spinner'
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';
import { countries as CountryList } from '../../modules/countryModule';
import { useDispatch, useSelector } from "react-redux";
import * as userDataActions from '../../actions/userDataActions'
import Footer from '../../components/Footer'
import Dropdown from '../../components/customComponents/Dropdown'
import * as timestampActions from '../../actions/timestampActions';

const Register = () => {
    const navigate = useNavigate();
    let userData = useSelector((state) => state.userData)
    let userDataDispatch = useDispatch();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const countries = CountryList
    const publisherForm = React.useRef()
    const advertiserForm1 = React.useRef();
    const advertiserForm2 = React.useRef();
    const [country, setCountry] = React.useState();
    const [registerModal, setRegisterModal] = React.useState(false)

    const [signupType, setSignupType] = React.useState(null);
    const [advertiserPage, setAdvertiserPage] = React.useState(1);
    const registerPublisher = () => {
        let refID = window.localStorage.getItem("ScaleleadRefID");
        let inputs = publisherForm.current.querySelectorAll('input')
        let username = inputs[0].value
        let password = inputs[1].value
        let passwordC = inputs[2].value
        let firstName = inputs[3].value
        let lastName = inputs[4].value
        let email = inputs[5].value
        let zip = inputs[6].value
        let experience = inputs[7].value
        let skype = inputs[9].value
        let whatsapp = ''
        let telegram = inputs[10].value
        if (inputs[inputs.length - 1].checked === false) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please accept terms and conditions'
            return
        }
        if (!country) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please choose your country'
            return

        }
        if (username.length < 5) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Username must be longer than eight characters'
            return
        }
        if (password.length < 8 && password === passwordC) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Password must be longer than eight characters'
            return
        }
        if (/\d/.test(firstName) === true) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please remove numbers from your name'
            return
        }
        if (/\d/.test(lastName) === true) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please remove numbers from your name'
            return
        }
        if (/\S+@\S+\.\S+/.test(email) === false) {
            publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please enter correct e-mail'
            return

        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createUser`,
            data: {
                username: username,
                password: password,
                email: email,
                FirstName: firstName,
                LastName: lastName,
                Country: country,
                ZIP: zip,
                Experience: experience,
                ContactWhatsApp: whatsapp,
                ContactSkype: skype,
                ContactTelegram: telegram,
                refID: refID
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status !== 'ok') {
                if (res.data.data === 'USER_ALREADY_EXISTS') {
                    publisherForm.current.querySelector('.register__form__msg').innerHTML = 'The user with the entered email or username already exists please check your email or change username'
                }else if(res.data.data === 'FIRST_OR_LAST_NAME_EMPTY'){
                    publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please enter your first and last name'

                }else if(res.data.data === 'ZIP_EMPTY_OR_INVALID'){
                    publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Please check your ZIP code'

                }else if(res.data.data === 'EXPERIENCE_EMPTY_OR_INVALID'){
                    publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Experience must be number'

                } else {
                    publisherForm.current.querySelector('.register__form__msg').innerHTML = 'Registration failed please check your email and username '
                }

            } else {
                window.localStorage.removeItem("ScaleleadRefID");
                document.querySelector('.registerMsg').style.opacity = 1
                // setTimeout(() => {
                //     animateNavigate("/login")
                // }, 3000)

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/auth/login`,
                    data: {
                        username: username,
                        password: password,
                    },
                    ...backendModule.axiosConfig
                }).then(check => {
                    if (check.data.status === 'ok') {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/auth/checkLogin`,
                            data: {
                                BoundUser: userData.userData?.UserInfo?.ID
                            },
                            ...backendModule.axiosConfig
                        }).then((e) => {
                            if (e.data.status === "ok") {
                                userDataDispatch(userDataActions.setUserData(e.data.data));
                                userDataDispatch(userDataActions.setLoginStatus(true));
                            } else {
                                userDataDispatch(userDataActions.setUserData(null));
                                userDataDispatch(userDataActions.setLoginStatus(false));
                            };
                        })
                        setRegisterModal(true)
                    }
                })
            }
        }).catch((res) => {
        });
    }
    const registerPublisher2 = () => {
        let refID = window.localStorage.getItem("ScaleleadRefID");
        let inputs = advertiserForm1.current.querySelectorAll('input')
        let username = inputs[0].value
        let password = inputs[1].value
        let passwordC = inputs[2].value
        let firstName = inputs[3].value
        let lastName = inputs[4].value
        let email = inputs[5].value
        let zip = inputs[6].value
        let experience = inputs[7].value
        let skype = inputs[9].value
        let whatsapp = ''
        let telegram = inputs[10].value
        if (inputs[inputs.length - 1].checked === false) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Please accept terms and conditions'
            return
        }
        if (!country) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Please choose your country'
            return

        }
        if (username.length < 8) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Username must have at least 8 characters'
            return
        }
        if (password.length < 8 && password === passwordC) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Password must be longer than eight characters'
            return
        }
        if (/\d/.test(firstName) === true) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Please remove numbers from your name'
            return
        }
        if (/\d/.test(lastName) === true) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Please remove numbers from your name'
            return
        }
        if (/\S+@\S+\.\S+/.test(email) === false) {
            advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Please remove numbers from your name'
            return

        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createUser`,
            data: {
                username: username,
                password: password,
                email: email,
                FirstName: firstName,
                LastName: lastName,
                Country: country,
                ZIP: zip,
                Experience: experience,
                ContactWhatsApp: whatsapp,
                ContactSkype: skype,
                ContactTelegram: telegram,
                refID: refID,
                Flags:{isPublisherVisible:false}

            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status !== 'ok') {
                if (res.data.data === 'USER_ALREADY_EXISTS') {
                    advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'The user with the entered email or username already exists please check your email or change username'
                } else {
                    advertiserForm1.current.querySelector('.register__form__msg').innerHTML = 'Registration failed please check your email and username '
                }

            } else {
                window.localStorage.removeItem("ScaleleadRefID");
                document.querySelector('.registerMsg').style.opacity = 1
                // setTimeout(() => {
                //     animateNavigate("/login")
                // }, 3000)

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/auth/login`,
                    data: {
                        username: username,
                        password: password,
                    },
                    ...backendModule.axiosConfig
                }).then(check => {
                    if (check.data.status === 'ok') {
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/auth/checkLogin`,
                            data: {
                                BoundUser: userData.userData?.UserInfo?.ID
                            },
                            ...backendModule.axiosConfig
                        }).then((e) => {
                            if (e.data.status === "ok") {
                                userDataDispatch(userDataActions.setUserData(e.data.data));
                                userDataDispatch(userDataActions.setLoginStatus(true));
                            } else {
                                userDataDispatch(userDataActions.setUserData(null));
                                userDataDispatch(userDataActions.setLoginStatus(false));
                            };
                        })
                        setAdvertiserPage(2)
                    }
                })
            }
        }).catch((res) => {
        });
    }
    const registerAdvertiser = (e) => {
        let inputs = advertiserForm2.current.querySelectorAll('input')
        let companyName = inputs[0].value
        let companyAdress = inputs[1].value
        let companyCity = inputs[2].value
        let ZIP = inputs[3].value
        let tax = inputs[4].value
        let vat = inputs[5].value
        if (companyName.length === '') {
            inputs[0].parentNode.style.border = '1px solid red'
            advertiserForm2.current.querySelector('.register__form__msg').innerHTML = 'Please enter your  company name'
            setTimeout(() => {
                inputs[0].parentNode.style.border = 'none'
            }, 3000)
            return;
        }
        if (companyAdress === '') {
            inputs[1].parentNode.style.border = '1px solid red'
            advertiserForm2.current.querySelector('.register__form__msg').innerHTML = 'Please enter your company adress'
            setTimeout(() => {
                inputs[1].parentNode.style.border = 'none'
            }, 3000)
            return;
        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/addAdvertiser`,
            data: {
                CompanyName: companyName,
                CompanyAddress: companyAdress,
                CompanyCity: companyCity,
                CompanyZipCode: ZIP,
                TaxVatNumber: 'Tax: ' + tax + ', VAT: ' + vat,
                id: userData.userData?.UserInfo?.ID

            },
            ...backendModule.axiosConfig
        }).then((e) => {
            userDataDispatch(timestampActions.updateTimestamp());
            if (e.data.status === 'ok') {
                setRegisterModal(true)
            }


        }).catch(() => {
        }).finally(() => {
        })
    }



    return <div className='component__register'>
        {registerModal && <div className='succesfullRegisterModal'>
            <div className='succesfullRegisterModal__content'>
                <img src='/images/succRegister.png' alt='' />
                <h4>Awesome!</h4>
                <p>Congratulation, your account has been successfully created</p>
                <button onClick={() => {
                    setTimeout(() => {
                        if (signupType === 'publisher') {
                            animateNavigate("/dashboard/publisher")
                        } else {
                            animateNavigate("/dashboard/advertiser")
                        }
                    }, 500)
                }} >Go to dashboard</button>
            </div>
        </div>}
        <img className="login__image" src='/images/landing/logibimage.png' />
        <img className="login__maskMobile" src="/images/landing/mobileimageup.png" />
        <div className='component__register__box'>
            <div className="login__container__left" >
                <img src="/images/landing/containerImage.png" />
            </div>
            <div style={signupType === null ? { 'width': '50%', 'backgroundImage':'url(/images/registerBackground.png)' } : { 'width': 0, 'backgroundImage':'url(/images/registerBackground.png)' }} className='component__register__box__form'>
                <h1>Sign up</h1>
                <div className='component__register__box__form__select'>
                    <button onClick={() => { setSignupType('publisher') }}>Sign up as Publisher</button>
                    <p>I want to promote your offers.<br />
                        I have traffic.</p>
                </div>
                <div className='component__register__box__form__select'>
                    <button onClick={() => { setSignupType('advertiser') }}>Sign up as Advertiser</button>
                    <p>I have my own offers.<br />
                        I need traffic.</p>
                </div>
            </div>
            <div ref={publisherForm} style={signupType === 'publisher' ? { 'maxWidth': '100%' } : { 'maxWidth': 0 }} className='component__register__box__form component__register__box__form--register'>
                <h1>Sign up as publisher</h1>
                <label>Username: *</label>
                <label>Password: *</label>
                <input placeholder={'Username'} type='text' required name='Username' />
                <input placeholder={'Password'} type='password' required name='password' />
                <label>Confirm password: *</label>
                <label>First name: *</label>
                <input placeholder={'Confirm your password'} type='password' required name='password' />
                <input placeholder={'First Name'} type='text' required name='First name' />
                <label>Last name: *</label>
                <label>E-mail: *</label>
                <input placeholder={'Last Name'} type='text' required name='Last name' />
                <input placeholder={'E-mail'} type='text' required name='E-mail' />
                <label>ZIP code: *</label>
                <label>Experience in years: *</label>
                <input placeholder={'ZIP'} type='number' required />
                <input placeholder={'Experience (in years)'} type='number' required />
                <label>Country: *</label>
                <label>Skype username (optional)</label>
                <Dropdown theme='light' accent="rgba(70,100,233,0.75)"
                    data={countries.map(item => {
                        return { name: item.name, value: item.code }
                    })} onChange={(e) => {
                        setCountry(e?.value)
                    }} />
                <input placeholder={'Skype'} type='text' />
                <label>Telegram username (optional)</label>
                <label>WhatsApp number (optional)</label>
                <input placeholder={'Telegram'} type='text' />
                <input placeholder={'WhatsApp'} type='text' />
                <div style={{ 'width': '100%' }} className='checkboxPublisher checkbox' >
                    <input type={'checkbox'} />
                    <p>I agree with:
                        <span onClick={() => { animateNavigate('/terms') }}>Terms and Conditions</span> and <span onClick={() => { animateNavigate('/privacy') }}>Privacy Policy</span>.</p>
                </div>

                <p style={{ width: '40%' }}></p>
                <p className='register__form__msg' ></p>
                <div className='registerMsg' >
                    Registration was successful.
                </div>
                <Button onClick={() => { registerPublisher() }} value='Sign up' />
            </div>
            <div ref={advertiserForm1} style={(signupType === 'advertiser' && advertiserPage === 1) ? { 'maxWidth': '100%'} : { 'maxWidth': 0 }} className='component__register__box__form component__register__box__form--register'>
                <h1>Sign up as Advertiser</h1>
                <label>Username: *</label>
                <label>Password: *</label>
                <input placeholder={'Username'} type='text' required name='Username' />
                <input placeholder={'Password'} type='password' required name='password' />
                <label>Confirm password: *</label>
                <label>First name: *</label>
                <input placeholder={'Confirm your password'} type='password' required name='password' />
                <input placeholder={'First Name'} type='text' required name='First name' />
                <label>Last name: *</label>
                <label>E-mail: *</label>
                <input placeholder={'Last Name'} type='text' required name='Last name' />
                <input placeholder={'E-mail'} type='text' required name='E-mail' />
                <label>ZIP code: *</label>
                <label>Experience in years: *</label>
                <input placeholder={'ZIP'} type='number' required />
                <input placeholder={'Experience (in years)'} type='number' required />
                <label>Country: *</label>
                <label>Skype username (optional)</label>
                <Dropdown theme='light' accent="rgba(70,100,233,0.75)"
                    data={countries.map(item => {
                        return { name: item.name, value: item.code }
                    })} onChange={(e) => {
                        setCountry(e?.value)
                    }} />
                <input placeholder={'Skype'} type='text' />
                <label>Telegram username (optional)</label>
                <label>WhatsApp number (optional)</label>
                <input placeholder={'Telegram'} type='text' />
                <input placeholder={'WhatsApp'} type='text' />

                <div style={{ 'width': '100%' }} className='checkboxPublisher checkbox' >
                    <input type={'checkbox'} />
                    <p>I agree with:
                        <span onClick={() => { animateNavigate('/terms') }}>Terms and Conditions</span> and <span onClick={() => { animateNavigate('/privacy') }}>Privacy Policy</span>.</p>
                </div>
                <p style={{ width: '40%' }}></p>
                <p className='register__form__msg' ></p>
                <div className='registerMsg' >
                </div>
                <Button onClick={() => { registerPublisher2() }} value='Next' />
            </div>
            <div ref={advertiserForm2} style={(signupType === 'advertiser' && advertiserPage === 2) ? { 'maxWidth': '100%', 'backgroundImage':'url(/images/registerBackground.png)' } : { 'maxWidth': 0, 'backgroundImage':'url(/images/registerBackground.png)' }} className='component__register__box__form component__register__box__form--register register2'>
                <h1>Sign up as Advertiser</h1>
                <label>Company name: *</label>
                <input placeholder={'Company name'} type='text' required name='Username' />
                <label>Company address: *</label>
                <input placeholder={'Company adress'} type='text' required name='password' />
                <label>Company city: </label>
                <input placeholder={'Company city'} type='text' required name='password' />
                <label>Company ZIP code: *</label>
                <input placeholder={'Company ZIP code'} type='number' required name='password' />
                <label>Tax number: *</label>
                <input placeholder={'Tax number'} type='text' required name='First name' />
                <label>VAT number: *</label>
                <input placeholder={'VAT number'} type='text' required name='Last name' />



                <p style={{ width: '40%' }}></p>
                <p className='register__form__msg' ></p>
                <div className='registerMsg' >
                    Registration was successful.
                </div>
                <Button onClick={() => { registerAdvertiser() }} value='Sign up' />
            </div>

        </div>

        <Footer />

    </div>

};

export default Register;