import React from "react";
import "./index.scss";

import CustomDropbox from "../DropBox";
import CustomInput from "../CustomInput";
import CustomButtonSmall from "../ButtonSmall";
import { darkTheme, lightTheme } from "../../../actions/themeActions";
import { useDispatch, useSelector } from "react-redux";


const FilterPillItem = React.memo((props) => {
    let internalRef = React.useRef();
    React.useEffect(() => {
        if (!internalRef.current) return;

        let cmp = getComputedStyle(internalRef.current);
        if (!internalRef.current) return;
        internalRef.current.animate([
            { maxWidth: cmp.maxWidth ? cmp.maxWidth : 0, padding: cmp.padding ? `10px ${cmp.paddingRight} 10px ${cmp.paddingLeft}` : 0, marginRight: cmp.marginRight ? cmp.marginRight : 0 },
            { maxWidth: "100%", padding: "10px", marginRight: "10px" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" });
    }, [internalRef.current]);

    const parseType = (t) => {
        switch (t) {
            case "eq": return "equals";
            case "deq": return "equals";
            case "neq": return "not equals";
            case "dneq": return "not equals";
            case "gt": return "Greater than";
            case "dgt": return "Greater than";
            case "lt": return "Less than";
            case "dlt": return "Less than";
            case "geq": return "Greater or equals";
            case "dgeq": return "Greater or equals";
            case "leq": return "Less or equals"
            case "dleq": return "Less or equals"
            case "like": return "contains";
            case "startsWith": return "starts with";
            case "endsWith": return "ends with";
            default: return t;
        };
    };

    switch (props.data.varType) {
        case "string":
        case "date":
            return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}'` : "empty string"}`}</span>
                <span>✖</span>
            </span>
        case "number":
            return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}` : 0}`}</span>
                <span>✖</span>
            </span>
        case "boolean":
            return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} `}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                <span>✖</span>
            </span>
        case "custom": return (() => {
            switch (props.data.filterType) {
                case "string":
                    return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `'${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}'` : "empty string"}`}</span>
                        <span>✖</span>
                    </span>
                case "number":
                    return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)} ${props.data.value ? `${props.data.friendlyValue ? props.data.friendlyValue : props.data.value}` : 0}`}</span>
                        <span>✖</span>
                    </span>
                case "boolean":
                    return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                        <span>✖</span>
                    </span>
                case "custom":
                    return <span style={{maxWidth: 0}} ref={internalRef} key={props.data.ID} className={`customComponents__filterPanel__filterList__item${props.data.ID}`} onClick={(e) => props.removeFilterItem(props.data.ID, props.data.ID)}>
                        <span><span style={{ color: "#00A3FF" }}>{props.data.friendlyName}</span>{` ${parseType(props.data.type)}`}{props.data.value ? <span style={{ color: "#26e126" }}>Yes</span> : <span style={{ color: "#ee8181" }}>No</span>}</span>
                        <span>✖</span>
                    </span>
            };
        })()
    };
    return null;
});

const FilterPanel = (props) => {
    let [filterList, setFilterList] = React.useState([]);
    let [oldFilterList, setOldFilterList] = React.useState([]);
    let themeData = useSelector((state) => state.userData?.userData?.UserInfo?.Flags?.theme);

    const internalRef = React.useRef();
    let [curFilter, setCurFilter] = React.useState(null);
    let filterValueRef = React.useRef();
    let filterNameBox = CustomDropbox();
    let filterTypeBox = CustomDropbox();
    let filterValueBox = CustomDropbox();

    const callCB = () => {
        props.filterCB([]);
        if (props.filterCB) props.filterCB(filterList.map(elem => {
            return {
                name: elem.name,
                op: elem.type,
                value: elem.value
            };
        }));
    };
    const addFilterItem = async (fi) => {
        setFilterList([...filterList, fi]);
    };
    const removeFilterItem = async (id, curIndex) => {
        let curTarget = internalRef?.current?.querySelector(`.customComponents__filterPanel__filterList__item${curIndex}`);
        if (curTarget) {
            let cmp = getComputedStyle(curTarget);
            curTarget.animate([
                { width: cmp.width ? cmp.width : 0, padding: cmp.padding ? cmp.padding : 0 },
                { width: 0, padding: 0 }
            ], { duration: 300, iterations: 1, easing: "ease-in-out", fill: "both" }).onfinish = () => {
                setFilterList(filterList.filter(item => item.ID !== id));
            };
        } else {
            setFilterList(filterList.filter(item => item.ID !== id));
        };
    };

    React.useEffect(() => {
        if (props.addFilter) props.addFilter(() => {
            return (data) => data ? addFilterItem(data) : null;
        });
    }, []);

    React.useEffect(() => {
        let shouldCall = false;
        for (let item of filterList) {
            let found = oldFilterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        for (let item of oldFilterList) {
            let found = filterList.find(elem => elem.ID === item.ID);
            if (!found) {
                shouldCall = true;
                break;
            };
        };
        if (shouldCall) {
            setOldFilterList([...filterList]);
            callCB();
        };
    }, [filterList]);

    return <div className={`customComponents__filterPanel ${props.className ? props.className : ""}`} ref={internalRef} style={
        props.style ? { ...props.style } : null
    }>
        <div className="customComponents__filterPanel__filterList">
            <p className="customComponents__filterPanel__filterList__addFilter" onClick={() => {
                let curElem = internalRef?.current?.querySelector(".customComponents__filterPanel__addFilterWrap");
                if (curElem) {
                    curElem.classList.toggle("customComponents__filterPanel__addFilterWrap--active");
                };
            }}>+ Add filter &nbsp; {themeData === 'light' ? <img style={{ marginLeft: ' 10px' }} src="/images/filterlight.svg" /> : <img style={{ marginLeft: ' 10px' }} src="/images/filterdark.svg" />}</p>

            {filterList.map((fi) => {
                return <FilterPillItem key={fi.ID} data={fi} removeFilterItem={removeFilterItem} />
            })}
        </div>
        <div className="customComponents__filterPanel__addFilterWrap">
            <filterNameBox.DropBox selected={0} selectText="Filter column" data={props.filters.map((item, index) => {
                if (index === 0 && !curFilter) setCurFilter(item);
                return {
                    name: item.friendlyName,
                    value: index
                }
            })} onChange={val => {
                setCurFilter(props.filters.find((_, index) => String(index) === String(val.value)));
            }} />
            {(() => {
                switch (curFilter?.type) {
                    case "string": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" },
                        { name: "Contains", value: "like" },
                        { name: "Starts with", value: "startsWith" },
                        { name: "Ends with", value: "endsWith" }
                    ]} />
                    case "number": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" },
                        { name: "Greater than", value: "gt" },
                        { name: "Greater or equals", value: "geq" },
                        { name: "Less than", value: "lt" },
                        { name: "Less or equals", value: "leq" }
                    ]} />
                    case "date": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Equals", value: "deq" },
                        { name: "Not equals", value: "dneq" },
                        { name: "Greater than", value: "dgt" },
                        { name: "Greater or equals", value: "dgeq" },
                        { name: "Less than", value: "dlt" },
                        { name: "Less or equals", value: "dleq" }
                    ]} />
                    case "boolean": return <filterTypeBox.DropBox selected={0} data={[
                        { name: "Equals", value: "eq" },
                        { name: "Not equals", value: "neq" }
                    ]} />
                    case "custom": return (() => {
                        switch (curFilter?.varType) {
                            case "string": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" },
                                { name: "Contains", value: "like" },
                                { name: "Starts with", value: "startsWith" },
                                { name: "Ends with", value: "endsWith" }
                            ]} />
                            case "number": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" },
                                { name: "Greater than", value: "gt" },
                                { name: "Greater or equals", value: "geq" },
                                { name: "Less than", value: "lt" },
                                { name: "Less or equals", value: "leq" }
                            ]} />
                            case "boolean": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" }
                            ]} />
                            case "custom": return <filterTypeBox.DropBox selected={0} data={[
                                { name: "Equals", value: "eq" },
                                { name: "Not equals", value: "neq" }
                            ]} />
                        };
                    })()
                };
            })()}
            {(() => {
                switch (curFilter?.type) {
                    case "date": return <CustomInput ref={filterValueRef} placeholder1="Value (text/string)" type="date" />
                    case "string": return <CustomInput ref={filterValueRef} placeholder1="Value (text/string)" />
                    case "number": return <CustomInput ref={filterValueRef} placeholder1="Value (number)" type="number" />
                    case "boolean": return <filterValueBox.DropBox data={[
                        { value: true, name: "Yes / true" },
                        { value: false, name: "No / false" }
                    ]} selected={0} />
                    case "custom": return <filterValueBox.DropBox data={curFilter.data.map(item => {
                        return { value: item.value, name: item.text }
                    })} selected={0} />
                };
            })()}
            <CustomButtonSmall value="Add filter" onClick={() => {
                switch (curFilter?.type) {
                    case "string":
                        try {
                            let final = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: filterValueRef.current.value,
                                varType: "string",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final);
                            filterValueRef.current.value = "";
                        } catch { };
                        break;
                    case "number":
                        try {
                            let final2 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: isNaN(Number(filterValueRef.current.value)) ? 0 : Number(filterValueRef.current.value),
                                varType: "number",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final2);
                            filterValueRef.current.value = "";
                        } catch { };
                        break;
                    case "boolean":
                        try {
                            let final3 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: filterValueBox.getSelectedItem()?.value ? true : false,
                                varType: "boolean",
                                filterType: curFilter.varType
                            };
                            addFilterItem(final3);
                        } catch { };
                        break;
                    case "date":
                        try {
                            let tmpDate = new Date(filterValueRef.current.value);
                            let finaldate = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: tmpDate.toISOString(),
                                varType: "date",
                                filterType: curFilter.varType,
                                friendlyValue: tmpDate.toLocaleDateString()
                            };
                            addFilterItem(finaldate);
                        } catch { };
                        break;
                    case "custom":
                        try {
                            let final4 = {
                                ID: Date.now(),
                                name: curFilter.name,
                                friendlyName: curFilter.friendlyName,
                                type: filterTypeBox.getSelectedItem() !== null ? filterTypeBox.getSelectedItem().value : null,
                                value: (() => {
                                    if (curFilter.varType === "boolean") return (String(filterValueBox.getSelectedItem().value) === "true") ? true : false;
                                    if (curFilter.varType === "number") return Number(filterValueBox.getSelectedItem().value);
                                    if (curFilter.varType === "string") return String(filterValueBox.getSelectedItem().value);
                                    if (curFilter.varType === "date") return new Date(filterValueBox.getSelectedItem().value).toISOString();
                                    if (curFilter.varType === "custom") return filterValueBox.getSelectedItem().value;
                                })(),
                                varType: curFilter.type,
                                filterType: curFilter.varType
                            };
                            addFilterItem(final4);
                        } catch { };
                        break;
                };
                let curElem = internalRef?.current?.querySelector(".customComponents__filterPanel__addFilterWrap");
                if (curElem) {
                    curElem.classList.toggle("customComponents__filterPanel__addFilterWrap--active");
                };
            }} />
        </div>
    </div>
};

export default FilterPanel;