import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import Radio from "../../../../components/customComponents/Radio";
import { useSelector } from "react-redux";
import moment from "moment";
import { animateBox } from "../../../../modules/componentAnimation";
import { getLeadColor } from "../../../../modules/leadModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomDropbox from "../../../../components/customComponents/DropBox";
import LeadCRMInfo from "../../../../components/LeadCRMInfo";

const AdminLeads = () => {
    let userInfo = useSelector((state) => state.userData);
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    const [confirmSpinner, setConfirmSpinner] = React.useState(false)
    let location = useLocation();
    let navigate = useNavigate();
    const [statusCodes, setStatusCodes] = React.useState()
    let [canPaginate, setCanPaginate] = React.useState(false);

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const getLeadStatusCodes = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getStatusCodes`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setStatusCodes(res.data.data)
            }
        }).catch(() => {
        })
    };


    const continueLeads = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForAdmin`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;

                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false)
            setCanPaginate(true);
        });
    };

    const getLeads = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForAdmin`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData });
            } else {
                setUserData({ status: "error", data: "There was an error while getting leads" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };


    const Paginationleads = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continueLeads, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };


    React.useEffect(() => {
        getLeads();
        getLeadStatusCodes()
    }, [filters]);

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);
    const getSingleUser = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSingleUser`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                animateNavigate(`/dashboard/admin-users?Username=${encodeURI(res.data.data.Username)}`)
            }
        }).catch(() => {
        });
    }

    const SetLeadStatus = (props) => {
        const dp = CustomDropbox();
        const [spinner, setSpinner] = React.useState(false);
        const [infoP, setInfoP] = React.useState("");

        const performVerify = () => {
            const newStatus = dp.getSelectedItem()?.value;
            setInfoP("");
            setSpinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/leads/setLeadStatusAdmin`,
                data: {
                    ID: props.id,
                    newStatus: String(newStatus)
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        props.onFinish(newStatus)
                    });
                } else {
                    setInfoP("There was an error while setting the lead status!");
                };
            }).catch(() => {
                setInfoP("Server timed out!");
            }).finally(() => {
                setSpinner(false);
            });
        };

        if (spinner) return <div className="route__leads__setLeadStatus">
            <Spinner />
        </div>
        if (infoP) return <div className="route__leads__setLeadStatus">
            <p className="route__leads__setLeadStatus__infoP">{infoP}</p>
        </div>
        return <div className="route__leads__setLeadStatus">
            <p>This will change the status of the lead regardless of the CRM</p>
            <p style={{ marginBottom: "15px" }}>BE CAREFUL!</p>
            <span>New lead status</span>
            <dp.DropBox data={Object.keys(statusCodes).map(item => {
                return { name: statusCodes[item], value: item }
            })} selected={Object.keys(statusCodes).indexOf(String(props.status))} />
            <br />
            <CustomButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
            <CustomButtonSmall value="No" onClick={props.c} />
        </div>
    }

    const resendLead = (ID, index) => {
        setConfirmSpinner(true)
        let tempData = [...userData.data]
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/resendLead`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            tempData[index].Status = -2

        }).catch((e) => {
        }).finally(() => {
            setUserData({ status: 'ok', data: tempData })
            setTimeout(() => {
                setConfirmSpinner(false)
            }, 300)

        })
    }
    return <div className="route__leads">
        {(() => {
            if (!userData || !statusCodes) return <Spinner />
            if (userData.status === "error") {
                return null
            } else {

                return confirmSpinner ? <Spinner /> : <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Product name", 'Status', 'Date']} data={(() => {
                    let tmp = userData?.data?.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.ProductName },
                            {
                                keyID: item.ID, type: "text", text: <p className="curLeadStatusText">
                                    <span className="curLeadStatusText__leadStatusOrb" style={{ background: getLeadColor(item.Status) }}></span>
                                    <span>{statusCodes[item.Status]}</span>
                                </p>
                            },
                            { keyID: item.ID, type: "text", text: <p><span>{new Date(item.createdAt).toLocaleDateString()}</span></p> },
                            {
                                keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: 'button', text: 'More info', triggerDropdown: true, triggerData: c => {
                                            return <div style={{ marginLeft: '10px' }} className='moreInfoContainer'>

                                                <p><span> Lead ID:</span> <br />{item.ID}</p>
                                                <p><span> Product name:</span> <br />{item.ProductName}</p>
                                                <p><span>Offer name:</span><br /> {item.OfferName}</p>
                                                <p><span> Domain:</span><br /> {item.PurchaseDomain}</p>
                                                <p><span>Status:</span><br /> {statusCodes[item.Status]}</p>
                                                <p><span>CRM type:</span> <br />{item.CRMType} </p>
                                                <p><span> Money withdrawn:</span><br /> {item.LeadMoneyWithdrawn ? <spam style={{ color: 'green' }} >Yes</spam> : (item.PublisherPaid ? <spam style={{ color: 'red' }} >No</spam> : <spam style={{ color: 'gray' }} >Not eligible</spam>)} </p>
                                                <p><span>Lead price:</span><br /> {`${Number(item.PlatformEarnedAmount).toFixed(2)} $`}</p>
                                                <p><span>Publisher paid:</span> <br />{item.PublisherPaid === false ? <span style={{ color: 'red' }} >Not paid</span> : <span style={{ color: 'green' }} >Paid</span>} </p>
                                                <p><span>Purchase location:</span> <br />{item.PurchaseLocation.city} {item.PurchaseLocation.countryCode}</p>
                                                <p><span>Device:</span><br /> {item.PurchaseIsPhone ? 'Mobile' : 'Desktop'}</p>
                                                <p><span>Date:</span><br /> {new Date(item.createdAt).toLocaleString()}</p>
                                                <p> <span>Purchase details:</span><br /> {(() => {
                                                    let final = [];
                                                    Object.keys(item.PurchaseDetails).forEach(k => {
                                                        if (!item.PurchaseDetails[k]) return;
                                                        if (k === "GenericData") return;
                                                        final.push(<p >{k.replace("scalelead-", "")}: {item.PurchaseDetails[k]}</p>);
                                                    });
                                                    return final;
                                                })()}</p>
                                                <p><span>Purchase IP:</span><br /> {item.PurchaseIP ?? "-"}</p>
                                                <div style={{ width: '100%' }}>
                                                    <CustomButtonSmall style={{ width: '120px', marginTop: '10px', marginRight: '20px' }} value='See publisher' onClick={() => { getSingleUser(item.BoundUser) }} />
                                                    <CustomButtonSmall style={{ width: '120px', marginTop: '10px' }} value='See advertiser' onClick={() => { animateNavigate(`/dashboard/admin-advertisers?ID=${encodeURI(item.BoundAdvertiser)}`) }} />

                                                </div>
                                            </div>
                                        }

                                    },
                                    userInfo.userData.UserInfo.Flags.isAdmin ? {
                                        keyID: item.ID, type: 'button', text: "Set lead status", triggerDropdown: true, triggerData: c => {
                                            return <SetLeadStatus c={c} onFinish={(newStatus) => {
                                                setUserData({
                                                    ...userData,
                                                    data: [
                                                        ...userData.data.filter((_, i) => i < index),
                                                        {
                                                            ...item,
                                                            Status: Number(newStatus)
                                                        },
                                                        ...userData.data.filter((_, i) => i > index)
                                                    ]
                                                })
                                            }} id={item.ID} status={item.Status} />
                                        }
                                    } : null,
                                    userInfo.userData.UserInfo.Flags.isAdmin ? {
                                        keyID: item.ID, type: 'button', text: "Resend lead", triggerDropdown: true, triggerData: c => {
                                            return <div>
                                                <p>Are you sure you want to resend this lead?
                                                    <CustomButtonSmall value='Yes' style={{ backgroundColor: '#00a3ff', margin: '0 15px', }} onClick={() => { resendLead(item.ID, index) }} />
                                                    <CustomButtonSmall value='No' onClick={() => { c() }} /> </p>
                                            </div>
                                        }
                                    } : null,
                                    userInfo.userData.UserInfo.Flags.isAdmin || userInfo.userData.UserInfo.Flags.isManager || userInfo.userData.UserInfo.Flags.isCRMInfoAllowed ? {
                                        keyID: item.ID, type: "button", text: "Get CRM info", onClick: e => animateBox(e, <LeadCRMInfo ID={item.ID} />)
                                    } : null
                                ].filter(t => t)
                            }

                        ];


                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])
                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <Paginationleads /> }])

                    return tmp;
                })()} filters={[
                    { name: "ID", type: "string", friendlyName: "Lead ID" },
                    { name: "BoundUser", type: "string", friendlyName: "User ID" },
                    { name: "BoundAdvertiser", type: "string", friendlyName: "Advertiser ID" },
                    { name: "BoundOffer", type: "string", friendlyName: "Offer ID" },
                    { name: "BoundTakenOffer", type: "string", friendlyName: "TakenOffer ID" },
                    { name: "CRMType", friendlyName: "CRM type", type: "string" },
                    { name: "LeadMoneyWithdrawn", friendlyName: "Lead money withdrawn", type: "boolean" },
                    { name: "EarnedAmount", friendlyName: "Lead price", type: "number" },
                    { name: "OfferName", friendlyName: "Offer name", type: "string" },
                    { name: "ProductName", friendlyName: "Product name", type: "string" },
                    { name: "PublisherPaid", friendlyName: "Publisher paid", type: "boolean" },
                    { name: "PurchaseDomain", friendlyName: "Purchase domain", type: "string" },
                    { name: "PurchaseIP", friendlyName: "Purchase IP", type: "string" },
                    { name: "PurchaseIsPhone", friendlyName: "Is purchased on phone", type: "boolean" },
                    { name: "PurchaseLeadType", friendlyName: "Offer type", type: "string" },
                    { name: "PurchaseLocation:countryCode", friendlyName: "Country code", type: "string" },
                    { name: "PurchaseLocation:city", friendlyName: "Purchase city", type: "string" },
                    {
                        name: "Status", friendlyName: "Status", type: "custom", varType: "number", data: [
                            { text: "Retrying...", value: "-2" },
                            { text: "Lead processing", value: "-1" },
                            { text: "Waiting", value: "0" },
                            { text: "Lead in progress", value: "1" },
                            { text: "Lead confirmed", value: "2" },
                            { text: "Lead rejected", value: "3" },
                            { text: "Bad lead", value: "4" },
                            { text: "Unknown CRM error", value: "5" },
                            { text: "Processing lead", value: "6" },
                        ]
                    },
                    { name: "createdAt", type: "date", friendlyName: "Date" },
                    { name: "PurchaseDetails:scalelead-uid", type: "string", friendlyName: "Custom UID" },
                    { name: "PurchaseDetails:scalelead-c1", type: "string", friendlyName: "Custom C1" },
                    { name: "PurchaseDetails:scalelead-c2", type: "string", friendlyName: "Custom C2" },
                    { name: "PurchaseDetails:scalelead-c3", type: "string", friendlyName: "Custom C3" },
                    { name: "PurchaseDetails:scalelead-c4", type: "string", friendlyName: "Custom C4" },
                    { name: "PurchaseDetails:scalelead-c5", type: "string", friendlyName: "Custom C5" },
                    { name: "PurchaseDetails:scalelead-c6", type: "string", friendlyName: "Custom C6" },
                    { name: "PurchaseDetails:scalelead-stclickid", type: "string", friendlyName: "ScaleTrack ClickID" }
                ]} />
            };
        })()}
    </div>
};

export default AdminLeads;