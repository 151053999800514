import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import { useSelector } from "react-redux";
import { countries } from "../../../../modules/countryModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Spinner from "../../../../components/customComponents/Spinner";

const AdvertiserOffers = () => {
    let userInfo = useSelector((state) => state.userData);
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };


    const continueLeads = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersForAdvertiser`,
            data: {
                BoundAdvertiser: userInfo?.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;

                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => setLoadingSpinner(false));
    };

    const getLeads = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffersForAdvertiser`,
            data: {
                BoundAdvertiser: userInfo?.userData?.AdvertiserInfo?.ID,
                authUser: userInfo?.userData?.UserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) {
                        curPagination.current = -1;
                    }
                };
                setUserData({ status: "ok", data: res.data.data.DBData });
                setLoadingSpinner(false);
            }
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
            setLoadingSpinner(false);
        }).finally(() => {
            setLoadingSpinner(false);
        });
    };


    const Paginationleads = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        setTimeout(continueLeads, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };
    React.useEffect(() => {
        getLeads();
    }, [filters]);
    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
    }, [userData]);

    if (userInfo.userData?.AdvertiserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="route__leads">
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return null
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Offer name", "Product", '']} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.OfferName },
                            { keyID: item.ID, type: "text", text: <p>{item.OfferProductBrand}<br />{item.OfferProductName}</p> },
                            { keyID: item.ID, type: "groupNewline", group: [
                                {keyID: item.ID, type: "button", text: "More info", triggerDropdown: true, triggerData: c => {
                                    return <div className='moreInfoContainer' >
                                        <p> <span>Offer country:</span>  <br/> <img src={`/images/flags/${item.OfferCountry[0].replace(/"/g, "").toLowerCase()}.png`} /> {item.OfferCountry[0]}</p>
                                        <p> <span> Offer type:</span> <br/> {item.OfferType}</p>
                                        <p> <span> Offer Payout:</span><br/> {Number(item.OfferPayout).toFixed(2)}$</p>
                                        <p> <span> Aprove rate:</span><br/> {item.OfferMonthlyConversionRatio === -1 ? '?' : item.OfferMonthlyConversionRatio + '%'}</p>
                                    </div>
                                }}
                            ]}
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <Paginationleads /> }])

                    return tmp;
                })()} filters={[
                    { name: "OfferProductName", friendlyName: "Product name", type: "string" },
                    { name: "OfferProductBrand", friendlyName: "Product brand", type: "string" },
                    {
                        name: "OfferCountry", friendlyName: "Country", type: "custom", varType: "string", data: countries.map(elem => {
                            return { text: elem.name, value: elem.code }
                        })
                    },
                    { name: "OfferName", friendlyName: "Offer name", type: "string" },
                    {
                        name: "OfferType", friendlyName: "Offer type", type: "custom", varType: "string", data: [
                            { text: "Cost per action (CPA)", value: "CPA" },
                            { text: "Cost per lead (CPL)", value: "CPL" }
                        ]
                    },
                    { name: "OfferPayout", friendlyName: "Offer payout", type: "number" },
                    { name: "OfferMonthlyConversionRatio", friendlyName: "Offer conversion ratio", type: "number" },
                    { name: "createdAt", type: "date", friendlyName: "Date" }
                ]} />
            };
        })()}
    </div>
};

export default AdvertiserOffers;