
import React, { useState } from "react";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import Radio from "../../../../components/customComponents/Radio";
import { useSelector } from "react-redux";
import moment from "moment";
import './index.scss'

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomButton from "../../../../components/customComponents/Button";

const PaymentAdmin = () => {
    const [canCreatePayout, setCanCreatePayout] = React.useState(false)
    let userInfo = useSelector((state) => state.userData);
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();
    let [canPaginate, setCanPaginate] = React.useState(false);
    const [payoutCreating, setPayoutCreating] = React.useState(false)

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const continuePayouts = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getPayoutsAdvertiser`,
            data: {
                BoundAdvertiser: userInfo?.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false)
            setCanPaginate(true);
        });
    };

    const getPayouts = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getAllPlatformPayouts`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData });
            } else {
                setUserData({ status: "error", data: "There was an error while getting leads" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
            getCanCreatePayout()
        });
    };


    const Paginationleads = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continuePayouts, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };


    React.useEffect(() => {
        getPayouts();
    }, [filters]);

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);
    const getCanCreatePayout = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/checkPayoutPending`,

            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCanCreatePayout(true)
            } else {
                setCanCreatePayout(false)
            }
        }).catch(() => {
            setCanCreatePayout(false)
        })
    }
    const createPayout = () => {
        setPayoutCreating(true)
        axios({
            method: 'post',
            url: `${backendModule.backendURL}/payouts/createPlatformPayout`,

            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            getPayouts()
            setPayoutCreating(false)
        })

    }

    const PerformPaymentProcess = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        let [finalState, setFinalState] = React.useState(false);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/payouts/performPayoutProcess`,
                data: {
                    ID: props.ID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setFinalState(true);
                    setSpinner(false);
                    setFinalData(`Done, processed ${res.data.data.processedLeads}, and skipped ${res.data.data.skippedLeads} leads. Also processed ${res.data.data.processedReferrals} referrals, and skipped ${res.data.data.skippedReferrals} referrals`);
                } else {
                    setFinalData("Failed to process payouts!");
                    setFinalState(false);
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setFinalState(false);
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminOffers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(finalState);
                    });
                }} />
            </div>
        }
        return <div className="route__adminOffers__verifyPublisher">
            <p>This will pay out all publishers that are listed in the users.csv file</p>
            <p>THIS ACTION CANNOT BE UNDONE</p>
            <p>Are you sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{backgroundColor: "#00A3FF", color: 'white'}} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    }

    return <div className="route__leads">
        {payoutCreating ? <div style={{ position: 'relative', height: '70px', width: '100%' }} ><Spinner /></div> : <button style={{
            margin: "20px 0px",
            transition: "max-height 0.3s ease",
            height: "auto",
            maxHeight: canCreatePayout ? "100px" : "0px",
            overflow: "hidden"
        }} className="route__adminDomains__addDomainBtn" onClick={() => createPayout()} >Create payout <img src="/images/plusImageWhite.png"/></button>
        }
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return null
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Date and time", "Total paid out", "Processed"]} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: moment(item.createdAt).utc().format('DD-MM-YYYY hh:mm') },
                            { keyID: item.ID, type: "text", text: item.TotalPaidOut == 0 ? <spam style={{ color: 'grey' }}>{item.TotalPaidOut}$</spam> : item.TotalPaidOut > 0 ? <spam style={{ color: 'green' }}>{item.TotalPaidOut}$</spam> : <spam style={{ color: 'red' }}>{item.TotalPaidOut}$</spam> },

                            { keyID: item.ID, type: "text", text: item.Processed === false ? <spam style={{ color: 'red' }}>No</spam> : <spam style={{ color: 'green' }}>Yes</spam> },
                            {
                                keyID: item.ID, type: 'groupNewline', group: [
                                    item.Processed ? null : {
                                        keyID: item.ID, type: 'button', text: "Process", triggerDropdown: true, triggerData: (c) => {
                                            return <PerformPaymentProcess ID={item.ID} c={c} onUpdate={newState => {
                                                setUserData({status: "ok", data: [
                                                    ...userData.data.filter((_, index2) => index2 < index),
                                                    {...item, Processed: newState},
                                                    ...userData.data.filter((_, index2) => index2 > index)
                                                ]});
                                            }} />
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: 'text', text: <CustomButtonSmall value={'Download leads'}
                                            onClick={() => {
                                                axios({
                                                    url: `${backendModule.backendURL}/payouts/getLeadsPayoutFile`, //your url
                                                    method: 'POST',
                                                    data: {
                                                        ID: item.ID
                                                    },
                                                    responseType: 'blob', // important
                                                    ...backendModule.axiosConfig
                                                }).then((response) => {
                                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.setAttribute('download', `leads-${item.createdAt}.csv`); //or any other extension
                                                    document.body.appendChild(link);
                                                    link.click();
                                                }).catch(() => null)
                                            }}
                                        />

                                    }
                                    ,
                                    {
                                        keyID: item.ID, type: 'text', text: <CustomButtonSmall value={'Download users'}
                                            onClick={() => {
                                                axios({
                                                    url: `${backendModule.backendURL}/payouts/getUsersPayoutFile`,
                                                    method: 'POST',
                                                    data: {
                                                        ID: item.ID
                                                    },
                                                    responseType: 'blob',
                                                    ...backendModule.axiosConfig
                                                }).then((response) => {
                                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.setAttribute('download', `users-${item.createdAt}.csv`); //or any other extension
                                                    document.body.appendChild(link);
                                                    link.click();
                                                }).catch(() => null)
                                            }}
                                        />
                                    },
                                    {
                                        keyID: item.ID, type: 'text', text: <CustomButtonSmall value={'Download referrals'}
                                            onClick={() => {
                                                axios({
                                                    url: `${backendModule.backendURL}/payouts/getReferralsPayoutFile`,
                                                    method: 'POST',
                                                    data: {
                                                        ID: item.ID
                                                    },
                                                    responseType: 'blob',
                                                    ...backendModule.axiosConfig
                                                }).then((response) => {
                                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.setAttribute('download', `referrals-${item.createdAt}.csv`); //or any other extension
                                                    document.body.appendChild(link);
                                                    link.click();
                                                }).catch(() => null)
                                            }}
                                        />
                                    },
                                   item.Processed? null:{
                                        keyID: item.ID, type: 'button', text: 'Delete', triggerDropdown: true, triggerData: (c) => {
                                            return <div style={{ padding: '20px' }}>
                                                <p style={{ marginBottom: '20px' }}>Are you sure you want to delete this Payout?</p>
                                                <CustomButtonSmall style={{ marginRight: '20px', color: 'white', backgroundColor: '#00a2ff' }} value={'Confirm'} onClick={() => {
                                                    axios({
                                                        method: 'post',
                                                        url: `${backendModule.backendURL}/payouts/deletePlatformPayout`,
                                                        data: {
                                                            ID: item.ID
                                                        },
                                                        ...backendModule.axiosConfig
                                                    }).then(() => null).catch(() => null).finally(() => getPayouts())
                                                }} />
                                                <CustomButtonSmall value={'Cancel'} onClick={() => c()} />

                                            </div>
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSPinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <Paginationleads /> }])

                    return tmp;
                })()} filters={[
                    { name: "Processed", friendlyName: "Is processed", type: "boolean" },
                    { name: "TotalPaidout", friendlyName: "Total paidout", type: "number" },
                    { name: "createdAt", type: "date", friendlyName: "Date" }
                ]} />
            };
        })()}
    </div>
};

export default PaymentAdmin;