import React from "react";
import "./index.scss";

const CustomDropbox = () => {
    const [selected, setSelected] = React.useState(null);
    //data-niz objekata {name: '',value:'' }
    //getSelected() null || data[elem].value

    return {
        DropBox: React.forwardRef((props, ref) => {
            const [searchInput, setSearchInput] = React.useState('')
            let internalRef = React.useRef();
            let dropboxOpen = false

            React.useImperativeHandle(ref, () => internalRef.current);

            React.useEffect(() => {
                if (selected === null || selected === undefined) return;
                let selectedStringify = JSON.stringify(selected);
                let found = props.data.find(item => JSON.stringify(item) === selectedStringify);
                if (!found) {
                    if (props.selected !== null && props.selected !== undefined) {
                        if (props.data[props.selected]) {
                            setSelected(props.data[props.selected]);
                        } else {
                            setSelected(null);
                        };
                    } else {
                        setSelected(null);
                    };
                };
            }, [props.data]);
            React.useEffect(() => {
                if (!selected) {
                    if (props.selected !== null && props.selected !== undefined) setSelected(props.data[props.selected]);
                };
            }, []);
            React.useEffect(() => {
                if (!selected) return;
                if (props.onChange) props.onChange(selected);
                if (props.reset === true) setSelected(null);
            }, [selected])
            React.useEffect(() => {
                let listenClick = document.body.addEventListener("click", (e) => {
                    let curDropBox = internalRef?.current?.querySelector(".customComponents__dropBox__selected");
                    if (!curDropBox) return;
                    if (e.target === curDropBox) return;
                    if (dropboxOpen) openDropbox();
                });
                return () => document.body.removeEventListener("click", listenClick);
            });

            const openDropbox = (e) => {
                if (!internalRef?.current) return;
                let element = internalRef.current.querySelector('.customComponents__dropBox__all')
                if (dropboxOpen === false) {
                    element.style.height = '200px'
                    element.style.overflowY = 'scroll'
                    dropboxOpen = true
                } else {
                    element.style.height = '0px'
                    element.style.overflow = 'hidden'
                    dropboxOpen = false
                }
            }
            const getSelected = (item, e) => {
                setSelected(item)
                let element = e.target.parentNode
                element.style.height = '0px'
                element.style.overflow = 'hidden'
                dropboxOpen = false
            }
            return <div className='customComponents__dropBox' ref={internalRef}>
                <p className='customComponents__dropBox__selected' onClick={(e) => { openDropbox(e) }}>{(() => {
                    if (selected) {
                        return <>{selected.image && <img className="customComponents__dropBox__selected__icon" src={selected.image} />} {selected.name}</>
                    } else {
                        return props.selectText
                    }
                })()} &nbsp; &nbsp; <img src='/images/arrowlight.png' /></p>
                <div onClick={e => e.stopPropagation()} className='customComponents__dropBox__all'>
                    <input className='customComponents__dropBox__all__input' type='text' autoComplete='off' onChange={(e) => { setSearchInput(e.target.value); }} />
                    {props.data !== undefined ? props.data.map(item => {
                        if (item.name.toLowerCase().includes(searchInput.toLowerCase())===false) {
                        return <p key={item.name} style={{display: 'none'}} onClick={(e) => { getSelected(item.value, e) }} className='customComponents__dropBox__all__single'> {item.image && <img src={item.image} />} {item.name}</p>
                        }else{
                            return <p key={item.name}  onClick={(e) => { getSelected(item, e) }} className='customComponents__dropBox__all__single'> {item.image && <img src={item.image} />} {item.name}</p>

                        }
                    }) : null}
                </div>
            </div>
        }),
        getSelectedItem: () => {
            return selected
        }
    }

};

export default CustomDropbox