import React from "react";
import "./index.scss";

const CustomTabs = React.forwardRef((props, ref) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const internalRef = React.useRef();
    const [firstTime, setFirstTime] = React.useState(true);
    React.useImperativeHandle(ref, () => internalRef.current);

    const activeTabs = (index) => {
        if (!internalRef.current) return;
        if (activeTab === index) return;
        let curContent = internalRef.current?.querySelector(".customComponents__tabs__content");
        curContent.animate([
            { maxHeight: getComputedStyle(curContent).height },
            { maxHeight: 0 }
        ], {
            duration: 300,
            itarations: 1,
            fill: "both",
            easing: "ease-in-out"
        }).onfinish = () => {
            setActiveTab(index);
        };
    };

    React.useEffect(() => {
        if (!internalRef.current) return;
        if (internalRef.current) {
            setTimeout(() => {
                let curContent = internalRef.current?.querySelector(".customComponents__tabs__content");
            if(!curContent) return
                curContent.animate([
                    { maxHeight: 0 },
                    { maxHeight: "500px" }
                ], {
                    duration: 300,
                    itarations: 1,
                    fill: "both",
                    easing: "ease-in-out"
                }).onfinish = () => {
                    if (props.autoScroll === true) {
                        if (!firstTime) {
                            curContent.scrollIntoView({ behavior: "smooth" });
                        } else {
                            setFirstTime(false);
                        };
                    };
                    curContent.animate([
                        { maxHeight: getComputedStyle(curContent).height },
                        { maxHeight: "auto" }
                    ], {
                        duration: 0,
                        itarations: 1,
                        fill: "both",
                        easing: "ease-in-out"
                    })
                };
            }, 0);
        }
    }, [activeTab]);


    if (!props.tabs) return null;
    return <div className={`customComponents__tabs ${props.className ? props.className : ""}`} ref={internalRef} >
        <div className="customComponents__tabs__header">
            {props.tabs.map((elem, index) => <h2 className={`customComponents__tabs__tab ${index === activeTab ? "customComponents__tabs__tab--activeTab" : ""}`} onClick={() => activeTabs(index)}>{elem.name}</h2>)}
        </div>
        <div className="customComponents__tabs__content">
            <div className="customComponents__tabs__content__wrap">
                {props.tabs[activeTab].text}
            </div>
        </div>
    </div>
});

export default CustomTabs