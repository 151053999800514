import React from "react";
import "./index.scss";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import AnimateModule from "../../modules/animateModule";

import Domains from "./Publisher/Domains";
import RegisterAdvertiser from "./Advertiser/Register/registerAdvertizer";
import Profile from "../Profile";
import CRM from "./Advertiser/CRM";
import Postback from "./Publisher/Postbacks";
import OffersPublisher from "./Publisher/Offers";
import AdvertiserOffers from "./Advertiser/Offers/advertiserOffers";
import Article from "./Publisher/SingleArticle";
import PublisherLeads from "./Publisher/Leads";
import AdvertiserLeads from "./Advertiser/Leads/leadsAdvertisert";
import DashboardAdmin from "./admin/Dashboard";
import AdminPublisherStats from "./admin/PublisherStats";
import AdminLeadStats from "./admin/LeadStats";
import AdminInvoices from "./admin/Invoices";
import AdminAPITokens from "./admin/ApiTokens";
import AdminUsers from "./admin/Users";
import AdminAdvertisers from "./admin/Advertisers";
import AdminDomains from "./admin/Domains";
import AdminOffers from "./admin/Offers";
import AdminCustomPayouts from "./admin/CustomPayouts";
import AdminBlockedLeads from "./admin/BlockedLeads";
import AdminLeads from "./admin/Leads";
import AdminWebsites from "./admin/Websites";
import DashboardPublisher from './Publisher/Dashboard'
import DashboardAdvertiser from './Advertiser/Dashboard'
import PaymentPublisher from "./Publisher/Payments";
import PaymentAdvertiser from "./Advertiser/Payments";
import PaymentAdmin from "./admin/Payments/index"
import Logs from "./admin/Logs";
import AdvertiserStats from "./Advertiser/Statistic";
import PublisherStats from "./Publisher/Stats";

import ReferralOverview from "./Referrals/Overview";
import ReferralAdvertiser from "./Referrals/Advertiser";
import ReferralPublisher from "./Referrals/Publisher";

import DashboardAdvertiserManager from "./AdvertiserManager/Dashboard";
import PublisherManager from "./PublisherManager/Dashboard";

import Spinner from "../../components/customComponents/Spinner";
const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const animateNavigate = to => {
    return AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
  };
  let userData = useSelector((state) => state.userData)
  const [loginCheck, setLoginCheck] = React.useState(false);
  const userSelector = useSelector(state => state.userData?.isLoggedIn);
  const publisherHiddenSelector = useSelector(state => state.userData?.userData?.UserInfo?.Flags?.isPublisherHidden);
  const timestampSelector = useSelector(state => state.timeStamp?.timeStamp);
  const paymentMethod = useSelector(state => state.userData?.userData?.UserInfo?.PaymentMethod);
  const advertiser = useSelector(state => state.userData?.userData?.AdvertiserInfo);

  React.useEffect(() => {
    setLoginCheck(false);
    if (userSelector === false) {
      animateNavigate("/login").then(() => setLoginCheck(true));
    } else if (userSelector === true) {
      setLoginCheck(true);
      if (location.pathname === "/dashboard") animateNavigate(`/dashboard/${publisherHiddenSelector ? "advertiser" : "publisher"}`);
    };
  }, [userSelector, timestampSelector]);
  // React.useEffect(() => {
  //   if (userData?.userData?.UserInfo?.Flags?.theme === 'light') {
  //     document.body.classList.add('light')
  //     document.body.classList.remove('dark')
  //   }
  //   if ((userData?.userData?.UserInfo?.Flags?.theme === 'dark')) {
  //     document.body.classList.add('dark')
  //     document.body.classList.remove('light')
  //   }
  // })

  if (!loginCheck) return <Spinner />
  return <div className="dashboard">
    {(paymentMethod === '' && advertiser===null) && <p className="dashboard__notice" style={{color:'white', textAlign:"center", fontFamily: 'Montserrat', padding:'10px'}}>
    You should consider adding a payment method <span style={{border: '1px solid', borderRadius:'5px', padding:'2px 10px', cursor:'pointer'}} onClick={()=>{
      animateNavigate('/dashboard/profile')
    }}>add now</span></p>}
    <Routes>
      <Route path="/domains" element={<Domains />} />
      <Route path="/postback" element={<Postback />} />
      <Route path="/registeradvertiser" element={<RegisterAdvertiser />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/crm' element={<CRM />} />
      <Route path='/publisher-offers' element={<OffersPublisher />} />
      <Route path='/advertiser-offers' element={<AdvertiserOffers />} />
      <Route path="/article/:id" element={<Article />} />
      <Route path='/publisher-leads' element={<PublisherLeads />} />
      <Route path='/advertiser-leads' element={<AdvertiserLeads />} />
      <Route path='/advertiser' element={<DashboardAdvertiser />} />
      <Route path='/publisher' element={<DashboardPublisher />} />
      <Route path='/payments-publisher' element={<PaymentPublisher />} />
      <Route path='/payments-advertiser' element={<PaymentAdvertiser />} />
      <Route path='/admin-payments' element={<PaymentAdmin />} />
      <Route path='/admin-invoices' element={< AdminInvoices />} />
      <Route path='/referral-overview' element={<ReferralOverview />} />
      <Route path='/referral-publisher' element={<ReferralPublisher />} />
      <Route path='/referral-advertiser' element={<ReferralAdvertiser />} />
      <Route path='/advertiser-statistic' element={<AdvertiserStats />} />
      <Route path='/publisher-statistic' element={<PublisherStats />} />


      <Route path="/admin-dashboard" element={<DashboardAdmin />} />
      <Route path="/admin-publisherStats" element={<AdminPublisherStats />} />
      <Route path="/admin-leadStats" element={<AdminLeadStats />} />
      <Route path="/admin-apitokens" element={<AdminAPITokens />} />
      <Route path="/admin-users" element={<AdminUsers />} />
      <Route path="/admin-advertisers" element={<AdminAdvertisers />} />
      <Route path="/admin-domains" element={<AdminDomains />} />
      <Route path="/admin-offers" element={<AdminOffers />} />
      <Route path="/admin-customPayouts" element={<AdminCustomPayouts />} />
      <Route path='/admin-blockedLeads' element={<AdminBlockedLeads />} />
      <Route path='/admin-leads' element={<AdminLeads />} />
      <Route path='/admin-websites' element={<AdminWebsites />} />
      <Route path='/admin-logs' element={<Logs />} />

      <Route path="/manager-advertisers" element={<DashboardAdvertiserManager />} />
      <Route path="/manager-publishers" element={<PublisherManager />} />
      <Route path="/manager-publisherStats" element={<AdminPublisherStats />} />
    </Routes>
  </div>
};

export default Dashboard;