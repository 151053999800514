import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {setAxiosDataHandler} from "./modules/backendModule";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import {HashRouter as  Router} from 'react-router-dom'
import { setStore } from './modules/backendModule';

import {getParamsFromURL} from "./modules/urlModule";

let curParams = getParamsFromURL(window.location.toString());
curParams.forEach(elem => {
  if (elem?.name === "ref") {
    window.localStorage.setItem("ScaleleadRefID", elem.value);
  };
});

setAxiosDataHandler();
let store = createStore(allReducers, composeWithDevTools());
setStore(store);
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
      </Router>
    </Provider>,
  document.getElementById('root')
);