const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const spliceDateForCharts = (date) => {
    let tmp = date.split(".");
    if (tmp.length !== 3) return "";
    if (tmp[1].startsWith("0")) tmp[1] = tmp[1].substring(1, tmp.length);
    return `${tmp[0]}.${months[tmp[1]]}.${tmp[2]}`;
};

export const getIntFromMonthName = month => {
    for (let i=0; i<=months.length-1; i++) {
        if (months[i] === month) return i-1;
    };
    return -1;
};