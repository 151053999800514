
import "./index.scss";
import Footer from "../../components/Footer";
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';
import React from "react";


const Publishers = () => {
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    React.useEffect(()=>{
        document.querySelector('body').classList.remove('dark')
    }, [])
    return <div className="publishers" >
                    <img src="/images/landing/publishersImage.png" className="publishers__firstScreen__image" />

        <div className="publishers__firstScreen" >
            <h1>For publishers</h1>
            <p>If you're still in doubt if this is worth your time, here are the facts:
            </p>
            <h2><img src="/images/landing/tableCheck.png" />Guaranteed Best CPA Prices</h2>
            <h2><img src="/images/landing/tableCheck.png" />Vetted Advertisers </h2>
            <h2><img src="/images/landing/tableCheck.png" />High-Speed Lead Processing</h2>
            <h2><img src="/images/landing/tableCheck.png" />Customizable Payouts</h2>
            <h2><img src="/images/landing/tableCheck.png" />Dedicated Affiliate Manager</h2>
            <p><spam onClick={() => { animateNavigate('/register') }} >Register </spam>
                and start your journey or send us an <a href="mailto: info@scale-lead.com" >email</a> and find out how we can help you reach your goals.</p>
        </div>

        <Footer />
    </div>
};


export default Publishers
