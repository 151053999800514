import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import {getParamsFromURL} from "../../../../modules/urlModule";
import { animateBox } from "../../../../modules/componentAnimation";
import {useLocation, useNavigate} from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import Radio from "../../../../components/customComponents/Radio";
import { useSelector } from "react-redux";
import { getLeadColor } from "../../../../modules/leadModule";

import {FilteredCustomTable} from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";
import LeadCRMInfo from "../../../../components/LeadCRMInfo";

const AdvertiserLeads = () => {
    let userInfo = useSelector((state) => state.userData);
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let [firstTime, setFirstTime] = React.useState(true);
    let location = useLocation();
    let navigate = useNavigate();
    let [canPaginate, setCanPaginate] = React.useState(false); 
    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const [statusCodes, setStatusCodes]=React.useState()
    const getLeadStatusCodes=()=>{
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getStatusCodes`,
            data: {
                BoundAdvertiser: userInfo.userData.AdvertiserInfo.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setStatusCodes(res.data.data)
            }
        }).catch(() => {
        })
    };

    const continueLeads = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForAdvertiser`,
            data: {
                BoundAdvertiser: userInfo.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({status: "ok", data: [...userData.data, ...res.data.data.DBData]});
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false)
            setCanPaginate(true);
        });
    };

    const getLeads = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForAdvertiser`,
            data: {
                BoundAdvertiser: userInfo.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({status: "ok", data: res.data.data.DBData});
            } else {
                setUserData({status: "error", data: "There was an error while getting leads"});
            };
        }).catch(() => {
            setUserData({status: "error", data: "Server timed out!"});
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    

    const Paginationleads = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try{observer.unobserve(tmpRef.current);} catch{};
                         canPaginate && setTimeout(continueLeads, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try{observer.unobserve(tmpRef.current);} catch{};
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };


    React.useEffect(() => {
        getLeads();
        getLeadStatusCodes()
    }, [filters]);
    
    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    if (userInfo.userData?.AdvertiserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="route__leads">
        {(() => {
            if (!userData || !statusCodes) return <Spinner />
            if (userData.status === "error") {
                return null
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Product name", "Status", "Date"]} data={(()=>{
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.ProductName },
                            { keyID: item.ID, type: "text", text: <p className="curLeadStatusText">
                                <span className="curLeadStatusText__leadStatusOrb" style={{background: getLeadColor(item.Status)}}></span>
                                <span>{statusCodes[item.Status]}</span>
                            </p> },
                            { keyID: item.ID, type: "text", text: `Date: ${new Date(item.Date).toLocaleDateString()} Time: ${new Date(item.Date).toLocaleTimeString()} `},
                            {
                                keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: 'button', text: 'More info', triggerDropdown: true, triggerData: c => {
                                            return <div style={{marginLeft: '10px'}} className='moreInfoContainer'>
                                                <p> <span> Lead ID:</span> <br /> {item.ID} </p>
                                                <p> <span> Lead type:</span> <br /> {item.CRMType} </p>
                                                <p> <span> Lead price:</span><br /> {item.LeadPrice }</p>
                                                <p> <span> Advertiser paid:</span><br /> { item.AdvertiserPaid === false ? <span style={{ color: 'red' }} >Not paid</span> : <span style={{ color: 'green' }} >Paid</span>} </p>
                                                <p> <span>Purchase location:</span><br /> {item.PurchaseLocation.city} {item.PurchaseLocation.countryCode}</p>
                                                <p> <span> Domain:</span><br /> {item.PurchaseDomain}</p>
                                                <p> <span> Device:</span><br /> {item.PurchaseIsPhone ? "Phone": "Desktop"}</p>
                                                <p> <span>Purchase details:</span><br /> {(()=>{
                                                    let final = [];
                                                    Object.keys(item.PurchaseDetails).forEach(k => {
                                                        if (!item.PurchaseDetails[k]) return;
                                                        final.push(<p >{k.replace("scalelead-","")}: {item.PurchaseDetails[k]}</p>);
                                                    });
                                                    return final;
                                                })()}</p>
                                            </div>
                                        }

                                    },
                                    userInfo.userData.UserInfo.Flags.isAdmin || userInfo.userData.UserInfo.Flags.isManager || userInfo.userData.UserInfo.Flags.isCRMInfoAllowed ? {
                                        keyID: item.ID, type: "button", text: "Get CRM info", onClick: e => animateBox(e, <LeadCRMInfo ID={item.ID} />)
                                    } : null
                                ].filter(t => t)
                            }
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{keyID: "customSpinner", type: "custom", data: <Spinner />}]);
                    if (curPagination.current !== -1) tmp.push([{keyID: "customPagination", type: "custom", data: <Paginationleads />}])

                    return tmp;
                })()} filters={[
                    { name: "ID", friendlyName: "Lead ID", type: "string" },
                    {name: "PurchaseLeadType", friendlyName: "Lead type", type: "custom", data: [
                        {value: "CPA", text: "Cost per action (CPA)"},
                        {value: "CPL", text: "Cost per lead (CPL)"}
                    ], varType: "string"},
                    {name: "EarnedAmount", friendlyName: "Lead price", type: "number"},
                    {name: "OfferName", friendlyName: "Offer name", type: "string"},
                    {name: "ProductName", friendlyName: "Product name", type: "string"},
                    {name: "AdvertiserPaid", friendlyName: "Advertiser paid", type: "boolean"},
                    {name: "PurchaseDomain", friendlyName: "Purchase domain", type: "string"},
                    {name: "PurchaseIsPhone", friendlyName: "Is purchased on phone", type: "boolean"},
                    {name: "PurchaseLeadType", friendlyName: "Offer type", type: "string"},
                    {name: "PurchaseLocation:countryCode", friendlyName: "Country code", type: "string"},
                    {name: "PurchaseLocation:city", friendlyName: "Purchase city", type: "string"},
                    {name: "Status", friendlyName: "Status", type: "custom", varType: "number", data: [
                        {text: "Retrying...", value: "-2"},
                        {text: "Lead processing", value: "-1"},
                        {text: "Waiting", value: "0"},
                        {text: "Lead in progress", value: "1"},
                        {text: "Lead confirmed", value: "2"},
                        {text: "Lead rejected", value: "3"},
                        {text: "Bad lead", value: "4"},
                        {text: "Unknown CRM error", value: "5"},
                        {text: "Processing lead", value: "6"},
                    ]},
                    {name: "createdAt", type: "date", friendlyName: "Date"}
                ]}/>
            };
        })()}
    </div>
};

export default  AdvertiserLeads;