import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import { useNavigate, useLocation } from "react-router-dom";
import AnimateModule from "../../modules/animateModule";
import { darkTheme, lightTheme } from "../../actions/themeActions";
import { updateTimestamp } from "../../actions/timestampActions";


const SideBar = () => {
    let themeDispatch = useDispatch();
    let userDataDispatch = useDispatch();
    const navigate = useNavigate();
    const curLocation = useLocation();


    const matchLocationClass = (input) => curLocation.pathname.endsWith(input) ? "component__sidebar__submenu__items__activeItem" : null;
    const animateNavigate = to => {
        if (window.innerWidth < 1025)
            change()
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const change = () => {
        let div1 = document.querySelector('.div1')
        let div2 = document.querySelector('.div2')
        let div3 = document.querySelector('.div3')

        div2.style.opacity = '1'
        div1.style.transform = 'rotate(0)'
        div3.style.transform = 'rotate(0)'
        div1.style.top = '0'
        div3.style.bottom = '0'
        sidebar.style.width = '0'
        sidebar.style.padding = '0'

    }
    let userData = useSelector((state) => state.userData);
    let themeData = useSelector((state) => state?.userData?.userData?.UserInfo?.Flags?.theme);
    let isPublisherHidden = useSelector((state) => state?.userData?.userData?.UserInfo?.Flags?.isPublisherHidden);
    let sidebar = document.querySelector('.component__sidebar')
    const openSubmenu = (element) => {

        let parag = element.querySelector('.component__sidebar__submenu__items').childNodes
        let arrow = element.querySelectorAll('.component__sidebar__submenu__main__arrow')
        let menus = document.querySelectorAll('.activeSubmenu')

        if (element.classList.contains('activeSubmenu') === false) {
            for (let j = 0; j < menus.length; j++) {
                let parag1 = menus[j].querySelector('.component__sidebar__submenu__items').childNodes
                let arrow1 = menus[j].querySelectorAll('.component__sidebar__submenu__main__arrow')
                for (let i = 0; i < parag1.length; i++) {
                    parag1[i].style.height = '0'
                    parag1[i].style.marginTop = '0'
                    arrow1[0].style.transform = 'rotate(180deg)'
                    arrow1[1].style.transform = 'rotate(180deg)'
                    menus[j].classList.remove('activeSubmenu')
                }
            }
            for (let i = 0; i < parag.length; i++) {
                parag[i].style.height = '22px'
                parag[i].style.marginTop = '20px'
                arrow[0].style.transform = 'rotate(0)'
                arrow[1].style.transform = 'rotate(0)'
                element.classList.add('activeSubmenu')
            }
        } else {
            for (let i = 0; i < parag.length; i++) {
                parag[i].style.height = '0'
                parag[i].style.marginTop = '0'
                arrow[0].style.transform = 'rotate(180deg)'
                arrow[1].style.transform = 'rotate(180deg)'
                element.classList.remove('activeSubmenu')
            }
        }

    }
    // mijenja temu u reduxu
    const setThemeDark = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/setTheme`,
            data: {
                theme: 'dark'
            },
            ...backendModule.axiosConfig
        }).then((e) => {
        }).catch((e) => {
        }).finally(() => {
            themeDispatch(darkTheme());
            themeDispatch(updateTimestamp());
        });
    }
    const setThemeLight = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/setTheme`,
            data: {
                theme: 'light'
            },
            ...backendModule.axiosConfig
        }).then((e) => {

        }).catch((e) => {
        }).finally(() => {
            themeDispatch(lightTheme());
            themeDispatch(updateTimestamp());
        });
    }

    //logout
    const logOut = () => {
        let dashboard = document.querySelector('.dashboard')
        let header = document.querySelector('.component__headerLoged')
        let header1 = document.querySelector('.component__header')

        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data: {
                BoundUser: userData.userData?.UserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then((e) => {

            dashboard.style.paddingLeft = '10px'
            header.style.paddingLeft = '50px'
            header1.style.paddingLeft = '50px'
            animateNavigate('/')

        }).catch((e) => {
        }).finally(() => {
            userDataDispatch(updateTimestamp());
        });
    }
    React.useEffect(() => {
        if (themeData === 'light') {
            document.body.classList.remove('dark')
            document.body.classList.add('light')
        } else {
            document.body.classList.add('dark')
            document.body.classList.remove('light')
        }
    })
    return <React.Fragment>
        {window.location.href.includes('dashboard') ? <>
            <div className="component__sidebar">
                <img className="component__sidebar__closeBtn" src="/images/closeLight.svg" onClick={() => {
                    try { document.querySelector(".customComponents__hamburger").click() } catch { };
                }} />
                <div className="component__sidebar__logoContainer">
                    {themeData === "light" ?
                        <img src="/images/logoNewWhite.svg" style={{ 'height': '40px' }} /> : <img src="/images/logoNewWhite.svg" style={{ 'height': '40px' }} />}
                </div>
                {!isPublisherHidden && <div className="component__sidebar__submenu" >
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/publishericonlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/publishericondark.png" alt="" />
                        <p>Publisher</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/publisher")} onClick={() => {
                            animateNavigate("/dashboard/publisher")
                        }} ><span>Dashboard</span><span>Dashboard</span></p>
                        <p className={matchLocationClass("/dashboard/domains")} onClick={() => {
                            animateNavigate("/dashboard/domains")
                        }} ><span>Domains</span><span>Domains</span></p>
                        <p className={matchLocationClass("/dashboard/publisher-offers")} onClick={() => animateNavigate("/dashboard/publisher-offers")}><span>Offers</span><span>Offers</span></p>
                        <p className={matchLocationClass("/dashboard/postback")} onClick={() => animateNavigate("/dashboard/postback")}><span>Postback</span><span>Postback</span></p>
                        <p className={matchLocationClass("/dashboard/publisher-leads")} onClick={() => animateNavigate("/dashboard/publisher-leads")}><span>Leads</span><span>Leads</span></p>
                        <p className={matchLocationClass("/dashboard/publisher-statistic")} onClick={() => animateNavigate("/dashboard/publisher-statistic")}><span>Statistic</span><span>Statistic</span></p>
                        <p className={matchLocationClass("/dashboard/payments-publisher")} onClick={() => animateNavigate("/dashboard/payments-publisher")} ><span>Payments</span><span>Payments</span></p>
                    </div>
                </div>}
                <div className="component__sidebar__submenu">
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/adverticonlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/adverticondark.png" alt="" />
                        <p>Advertiser</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    {userData?.userData?.AdvertiserInfo !== null ? <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/advertiser")} onClick={() => animateNavigate("/dashboard/advertiser")} ><span>Dashboard</span><span>Dashboard</span></p>
                        <p className={matchLocationClass("/dashboard/advertiser-offers")} onClick={() => animateNavigate('/dashboard/advertiser-offers')}><span>Offers</span><span>Offers</span></p>
                        <p className={matchLocationClass("/dashboard/postback")} onClick={() => animateNavigate("/dashboard/postback")}><span>Postback</span><span>Postback</span></p>
                        <p className={matchLocationClass("/dashboard/advertiser-leads")} onClick={() => animateNavigate("/dashboard/advertiser-leads")}><span>Leads</span><span>Leads</span></p>
                        <p className={matchLocationClass("/dashboard/advertiser-statistic")} onClick={() => animateNavigate("/dashboard/advertiser-statistic")}><span>Statistic</span><span>Statistic</span></p>
                        <p className={matchLocationClass("/dashboard/crm")} onClick={() => animateNavigate("/dashboard/crm")}><span>CRM</span><span>CRM</span></p>
                        <p className={matchLocationClass("/dashboard/payments-advertiser")} onClick={() => animateNavigate("/dashboard/payments-advertiser")} ><span>Payments</span><span>Payments</span></p>
                    </div> :
                        <div className="component__sidebar__submenu__items">
                            <p onClick={() => animateNavigate('/dashboard/registeradvertiser')} ><span>Register</span><span>Register</span></p>
                        </div>
                    }
                </div>
                {userData?.userData?.RefferalInfo !== null ? <div className="component__sidebar__submenu">
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/sidebar_referralDark.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/sidebar_referralDark.png" alt="" />
                        <p>Referrals</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/referral-overview")} onClick={() => animateNavigate("/dashboard/referral-overview")} ><span>Overview</span><span>Overview</span></p>
                        <p className={matchLocationClass("/dashboard/referral-publisher")} onClick={() => animateNavigate("/dashboard/referral-publisher")} ><span>Publishers</span><span>Publishers</span></p>
                        <p className={matchLocationClass("/dashboard/referral-advertiser")} onClick={() => animateNavigate("/dashboard/referral-advertiser")} ><span>Advertisers</span><span>Advertisers</span></p>
                    </div>
                </div> : null}
                {(userData?.userData?.UserInfo?.Flags?.isPublisherManager === true || userData?.userData?.UserInfo?.Flags?.isManager === true) && <div className="component__sidebar__submenu" >
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/publishericonlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/publishericondark.png" alt="" />
                        <p>Publishers Manager</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/manager-publishers")} onClick={() => {
                                animateNavigate("/dashboard/manager-publishers")
                            }} ><span>Dashboard</span><span>Dashboard</span></p>
                        <p className={matchLocationClass("/dashboard/manager-publisherStats")} onClick={() => animateNavigate('/dashboard/manager-publisherStats')}><span>PublisherStats</span><span>PublisherStats</span></p>
                    </div>
                </div>}
                {(userData?.userData?.UserInfo?.Flags?.isAdvertiserManager === true || userData?.userData?.UserInfo?.Flags?.isManager === true) && <div className="component__sidebar__submenu" >
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/adverticonlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/adverticondark.png" alt="" />
                        <p>Advertisers manager</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/manager-advertisers")} onClick={() => {
                            animateNavigate("/dashboard/manager-advertisers")
                        }} ><span>Dashboard</span><span>Dashboard</span></p>
                    </div>
                </div>}
                {userData?.userData?.UserInfo?.Flags?.isAdmin === true ? <div className="component__sidebar__submenu">
                    <div className="component__sidebar__submenu__main" onClick={(e) => { openSubmenu(e.target.parentElement.parentElement) }}>
                        <img className="component__sidebar__submenu__main__icon lightmode" src="/images/adminiconlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__icon darkmode" src="/images/adminicondark.png" alt="" />
                        <p>Administrator</p>
                        <img className="component__sidebar__submenu__main__arrow lightmode" src="/images/arrowlight.png" alt="" />
                        <img className="component__sidebar__submenu__main__arrow darkmode" src="/images/arrowdark.png" alt="" />
                    </div>
                    <div className="component__sidebar__submenu__items">
                        <p className={matchLocationClass("/dashboard/admin-dashboard")} onClick={() => animateNavigate('/dashboard/admin-dashboard')}><span>Dashboard</span><span>Dashboard</span></p>
                        <p className={matchLocationClass("/dashboard/admin-publisherStats")} onClick={() => animateNavigate('/dashboard/admin-publisherStats')}><span>PublisherStats</span><span>PublisherStats</span></p>
                        <p className={matchLocationClass("/dashboard/admin-leadStats")} onClick={() => animateNavigate('/dashboard/admin-leadStats')}><span>LeadStats</span><span>LeadStats</span></p>
                        <p className={matchLocationClass("/dashboard/admin-apitokens")} onClick={() => animateNavigate('/dashboard/admin-apitokens')}><span>APITokens</span><span>APITokens</span></p>
                        <p className={matchLocationClass("/dashboard/admin-blockedLeads")} onClick={() => animateNavigate('/dashboard/admin-blockedLeads')}><span>BlockedLeads</span><span>BlockedLeads</span></p>
                        <p className={matchLocationClass("/dashboard/admin-leads")} onClick={() => animateNavigate('/dashboard/admin-leads')}><span>Leads</span><span>Leads</span></p>
                        <p className={matchLocationClass("/dashboard/admin-offers")} onClick={() => animateNavigate('/dashboard/admin-offers')}><span>Offers</span><span>Offers</span></p>
                        <p className={matchLocationClass("/dashboard/admin-customPayouts")} onClick={() => animateNavigate('/dashboard/admin-customPayouts')}><span>CustomPayouts</span><span>CustomPayouts</span></p>
                        <p className={matchLocationClass("/dashboard/admin-users")} onClick={() => animateNavigate('/dashboard/admin-users')}><span>Users</span><span>Users</span></p>
                        <p className={matchLocationClass("/dashboard/admin-advertisers")} onClick={() => animateNavigate('/dashboard/admin-advertisers')}><span>Advertisers</span><span>Advertisers</span></p>
                        <p className={matchLocationClass("/dashboard/admin-domains")} onClick={() => animateNavigate('/dashboard/admin-domains')}><span>Domains</span><span>Domains</span></p>
                        <p className={matchLocationClass("/dashboard/admin-websites")} onClick={() => animateNavigate('/dashboard/admin-websites')}><span>Websites</span><span>Websites</span></p>
                        <p className={matchLocationClass("/dashboard/admin-payments")} onClick={() => animateNavigate("/dashboard/admin-payments")} ><span>Payments</span><span>Payments</span></p>
                        <p className={matchLocationClass("/dashboard/admin-invoices")} onClick={() => animateNavigate("/dashboard/admin-invoices")} ><span>Invoices</span><span>Invoices</span></p>
                        <p className={matchLocationClass("/dashboard/admin-logs")} onClick={() => animateNavigate("/dashboard/admin-logs")} ><span>Logs</span><span>Logs</span></p>
                    </div>
                </div> : null}
                {/* {userData?.userData?.AdvertiserInfo !== null ? <p style={{ marginLeft: '60px', fontFamily: 'Montserrat', marginTop: '60px' }}>Credits:
                    <span style={{ color: 'green' }}>&nbsp;{userData?.userData?.AdvertiserInfo?.Credits}$</span></p> : null}
                {userData?.userData?.UserInfo !== null ? <p style={{ marginLeft: '60px', fontFamily: 'Montserrat', marginTop: '20px' }}>Estimated earnings:
                    <span style={{ color: 'green' }}>&nbsp;{userData?.userData?.UserInfo?.EstimatedEarnings}$</span></p> : null} */}
                <div className="component__sidebar__bottom">
                    <p className="component__sidebar__logout component__sidebar__logout--settings" onClick={() => { animateNavigate('/dashboard/profile') }}> <img src="/images/settingsWhite.png" /> Settings</p>
                    {themeData === 'light' ? <p className="component__sidebar__logout" onClick={() => { setThemeDark() }}><img src="/images/nightmode.png" /> Dark theme</p> :
                        <p className="component__sidebar__logout" onClick={() => { setThemeLight() }}> <img src="/images/lightmodelight.png" /> Light theme</p>
                    }
                    <p className="component__sidebar__logout component__sidebar__logout--logout" onClick={() => { logOut() }}> <img src="/images/logoutWhite.png" /> Log out</p>

                </div>


            </div></> : null}
    </React.Fragment>

};

export default SideBar;