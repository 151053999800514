import React from "react";
import "./index.scss";

const CustomInput = React.forwardRef((props, ref) => {
    let defaultData = {...props};
    if (defaultData.value) {
        defaultData.defaultValue = defaultData.value;
        delete defaultData.value;
    };
    return <div className={`customComponents__input ${props.className ? props.className : ""}`} >
        <div className="floating-label-group">
            <input type={props.type}  className="form-control" autoComplete="false" {...defaultData} placeholder=" " ref={ref} />
            <label className="floating-label">{props.placeholder1}</label>
        </div>

    </div>
});

export default CustomInput