
import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import Radio from "../../../../components/customComponents/Radio";
import { useSelector } from "react-redux";
import moment from "moment";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";

const PaymentAdvertiser = () => {
    
    let userInfo = useSelector((state) => state.userData);
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();
    let [canPaginate, setCanPaginate] = React.useState(false);

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const continueLeads = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getPayoutsAdvertiser`,
            data: {
                BoundAdvertiser:userInfo?.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false)
            setCanPaginate(true);
        });
    };

    const getLeads = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getPayoutsAdvertiser`,
            data: {
                BoundAdvertiser:userInfo?.userData?.AdvertiserInfo?.ID,
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData });
            } else {
                setUserData({ status: "error", data: "There was an error while getting leads" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };


    const Paginationleads = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continueLeads, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    React.useEffect(() => {
        getLeads();
    }, [filters]);

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    if (userInfo.userData?.AdvertiserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="route__leads">
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return null
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Date and time", "Amount", "Description"]} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: moment(item.Date).utc().format('DD-MM-YYYY hh:mm') },
                            { keyID: item.ID, type: "text", text: item.Amount===0? <spam style={{color:'grey'}}>{item.Amount}$</spam>:item.Amount>0?<spam style={{color:'green'}}>{item.Amount}$</spam>:<spam style={{color:'red'}}>{item.Amount}$</spam> },
                            { keyID: item.ID, type: "text", text: item.Description },
                            (item.Comment ? { keyID: item.ID, type: "groupNewline", group: [
                                { keyID: item.ID, type: "text", text: <><b>Comment:</b> {item.Comment}</> }
                            ] } : null)
                        ].filter(t => t);
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <Paginationleads /> }])

                    return tmp;
                })()} filters={[
                    { name: "Amount", friendlyName: "Amount", type: "string" },
                    {name: "createdAt", type: "date", friendlyName: "Date"}
                ]} />
            };
        })()}
    </div>
};

export default PaymentAdvertiser;