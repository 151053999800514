import React from "react";
import "./index.scss";

const KPI = (props) => {
    return <div className={`customComponents__kpi ${props.className ? props.className : ""}`} style={
        props.style ? {...props.style} : null
    }>
        <p className="customComponents__kpi__name">{props.name ? props.name : ""}</p>
        <p className="customComponents__kpi__value">{props.value ? props.value : ""}</p>
    </div>
};

export default KPI