import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Chartist from "chartist";
import "chartist/dist/chartist.css";
import Legend from "chartist-plugin-legend";

import CustomButtonSmall from "../ButtonSmall";

let openModalsList = [];
const createModal = (name, value, seriesIndex, e, iRef, filterData = {}) => {
    if (!iRef) return;
    let letters = ["a","b","c"];
    if (seriesIndex > 2) seriesIndex = 2;
    let pBounding = iRef.parentNode.getBoundingClientRect();
    let tmpElem = document.createElement("div");
    tmpElem.style.opacity = 0;
    tmpElem.style.position = "absolute";
    tmpElem.style.left = (e.clientX - pBounding.left) + "px";
    tmpElem.style.top = (e.clientY - pBounding.top) + "px";
    tmpElem.classList.add("customComponents__chart__modal");

    let nameSpan = document.createElement("span");
    nameSpan.textContent = name;
    nameSpan.classList.add(`customComponents__chart__modal__series-${letters[seriesIndex]}`);
    nameSpan.classList.add(`customComponents__chart__modal__series-default`);
    let valueSpan = document.createElement("span");
    valueSpan.textContent = value;

    tmpElem.appendChild(nameSpan);
    tmpElem.appendChild(valueSpan);

    openModalsList.push(tmpElem);
    setTimeout(() => {
        if (filterData.filterText) {
            let filterTextBtn = document.createElement("div");
            filterTextBtn.classList.add("customComponents__chart__modal__filter");
            tmpElem.appendChild(filterTextBtn);
            setTimeout(() => ReactDOM.render(<CustomButtonSmall onClick={() => filterData.onFilter ? filterData.onFilter(name) : null} value={filterData.filterText} />, filterTextBtn), 0)
        };
        let cmp = tmpElem.getBoundingClientRect();
        if (cmp.left + cmp.width > pBounding.width) {
            tmpElem.style.transform = "translate(-80%, -50%)";
        } else if (cmp.left - pBounding.left <= 20) {
            tmpElem.style.transform = "translate(-20%, -50%)";
        };
        tmpElem.animate([
            {opacity: getComputedStyle(tmpElem).opacity},
            {opacity: 1}
        ],{duration: 300, iterations: 1, easing: "ease-in-out", fill: "both"});
    }, 0);

    return {
        element: tmpElem,
        remove: () => {
            openModalsList = openModalsList.filter(tmp => tmp !== tmpElem);
            closeAllModals();
            tmpElem.animate([
                {opacity: getComputedStyle(tmpElem).opacity},
                {opacity: 0}
            ],{duration: 300, iterations: 1, easing: "ease-in-out", fill: "both"}).onfinish = () => {
                tmpElem.remove();
            };
        }
    }
};

const closeAllModals = () => {
    openModalsList.forEach(tmpElem => {
        tmpElem.animate([
            {opacity: getComputedStyle(tmpElem).opacity},
            {opacity: 0}
        ],{duration: 300, iterations: 1, easing: "ease-in-out", fill: "both"}).onfinish = () => {
            tmpElem.remove();
        };
    });
    openModalsList = [];
};

const animateCharts = (data, dataLength) => {
    switch (data.type) {
        case "area":
            data.element._node.style.fillOpacity = "0";
            data.element._node.animate([{fillOpacity: 0}, {fillOpacity: 1}],{duration: 300, iterations: 1, fill: "both", easing: "ease-in-out", delay: 700});
            break;
        case "line":
        case "slice":
            if (data.type === "line") {
                let pathLength = data.element._node.getTotalLength();
                data.element._node.style.strokeDasharray = pathLength;
                data.element._node.style.strokeDashoffset = pathLength;
                data.element._node.animate([
                    {strokeDashoffset: getComputedStyle(data.element._node).strokeDashoffset},
                    {strokeDashoffset: 0}
                ],{duration: 1000, iterations: 1, easing: "ease-in-out", fill: "both"});
            } else if (data.type === "slice") {
                let animSpeed = 1000 / dataLength;
                let pathLength = data.element._node.getTotalLength();
                data.element._node.style.strokeDasharray = pathLength;
                data.element._node.style.strokeDashoffset = pathLength;
                data.element._node.animate([
                    {strokeDashoffset: getComputedStyle(data.element._node).strokeDashoffset},
                    {strokeDashoffset: 0}
                ],{duration: animSpeed, iterations: 1, easing: "ease-in-out", fill: "both", delay: data.index * animSpeed});
            };
            break;
        case "bar":
            data.element.animate({
                y2: {
                    dur: '1s',
                    from: data.y1,
                    to: data.y2
                }
            });
            return;
        default: break;
    };
};

const LineChart = (props) => {
    const internalRef = React.useRef();
    const curChart = React.useRef();

    React.useEffect(() => {
        if (!internalRef?.current) return;
        if (!curChart.current) {
            curChart.current = Chartist.Line(internalRef.current, {
                labels: props.labels ? props.labels : [],
                series: props.series ? props.series : []
            }, {
                showArea: true,
                fullWidth: true,
                axisX: {
                    showGrid: false
                },
                axisY: {
                    offset: 80,
                    labelInterpolationFnc: function(value) {
                      return props.suffix ? `${value} ${props.suffix}` : value;
                    },
                    scaleMinSpace: 150,
                    onlyInteger: true
                },
                showPoint: true,
                plugins: [props.legend ? Legend({legendNames: props.legend}) : null].filter(tmp => tmp)
            });
            curChart.current.on("created", () => curChart.current.detach());
            curChart.current.on('draw', function(data) {
                animateCharts(data, props.labels.length, true);
                if (data.type === "label") {
                    if (data.axis.counterUnits.dir === "vertical") {
                        if (data.index === 0) {
                            data.element._node.classList.add("ct-label-start");
                        } else if (data.index === curChart.current.data.labels.length-1) {
                            data.element._node.classList.add("ct-label-end");
                        } else {
                            data.element._node.classList.add("ct-label-middle");
                        };
                    };
                };
                if (data.type === 'point') {
                   var circle = new Chartist.Svg('circle', {
                      cx: [data.x],
                      cy: [data.y],
                      r: [4],
                   }, 'ct-circle');
                   let tmpOverListen = (e => {
                       const dataPoint = createModal(
                            props.labels ? curChart.current.data.labels[data.index] : "",
                            props.suffix ? `${data.series[data.index]} ${props.suffix}` : data.series[data.index],
                            data.seriesIndex,
                            e, internalRef.current, {filterText: props.filterText, onFilter: props.onFilter});
                       if (internalRef.current) internalRef.current.appendChild(dataPoint.element);
                        let tmpLeaveListen = () => {
                            dataPoint.remove();
                            circle._node.removeEventListener("mouseleave", tmpLeaveListen)
                        };
                        dataPoint.element.addEventListener("mouseleave", tmpLeaveListen);
                   });
                   circle._node.addEventListener("mouseover", tmpOverListen);
                   data.element.replace(circle);
                   circle._node.style.opacity = 0;
                   circle._node.animate([
                       {opacity: 0},
                       {opacity: 1}
                   ],{duration: 300, iterations: 1, easing: "ease-in-out", fill: "both", delay: 1000 / props.labels.length * data.index});
                };
             });
             let gTimeout = null;
             let inProcess = false;
             curChart.current.on("data", (data) => {
                let drawEvent = (data2) => {
                    clearTimeout(gTimeout);
                    gTimeout = setTimeout(() => {
                        if (inProcess) return;
                        inProcess = true;
                        curChart.current.off("created", drawEvent);
                        let totalWidth = 0;
                        if (!internalRef.current) return;
                        let curSvg = internalRef.current.querySelector("svg");
                        if (!curSvg) return;
                        let labelList = curSvg.querySelectorAll(".ct-labels foreignObject");
        
                        let boundingRectSvg = curSvg.getBoundingClientRect();
                        let isFullWidth = curChart.current.options.fullWidth;
                        
                        for (let lbl of labelList) {
                            totalWidth += lbl.children[0].getBoundingClientRect().width;
                        };
                        totalWidth += props.labels.length * 50;
                        if (totalWidth > boundingRectSvg.width) {
                            if (isFullWidth) {
                                curChart.current.update(null, {
                                    ...curChart.current.options,
                                    fullWidth: false,
                                    width: `${totalWidth}px`
                                });
                            };
                        } else {
                            if (!isFullWidth) {
                                curChart.current.update(null, {
                                    ...curChart.current.options,
                                    fullWidth: true,
                                    width: "100%"
                                });
                            };
                        };
                        setTimeout(() => inProcess = false, 300);
                    }, 0);
                };
                curChart.current.on("created", drawEvent);
             });
        };
    }, [internalRef.current]);

    React.useEffect(() => {
        if (!curChart.current) return;
        let shouldUpdate = false;
        if (props.series.length !== curChart.current.data.series.length) shouldUpdate = true;
        props.series.forEach((seriesItem, seriesIndex) => {
            if (shouldUpdate) return;
            if (!curChart.current.data.series[seriesIndex]) {
                shouldUpdate = true;
                return;
            };
            if (seriesItem.length !== curChart.current.data.series[seriesIndex].length) {
                shouldUpdate = true;
                return;
            };
            seriesItem.forEach((valueItem, valueIndex) => {
                if (shouldUpdate) return;
                if (valueItem !== curChart.current.data.series[seriesIndex][valueIndex]) {
                    shouldUpdate = true;
                    return;
                };
            });
        });
        if (shouldUpdate) {
            let curPromises = [];
            curChart.current.svg?._node?.querySelectorAll(".ct-area").forEach(elem => {
                curPromises.push(new Promise(r => {
                    if (!elem) return r();
                    elem.animate([
                        {fillOpacity: getComputedStyle(elem).fillOpacity}, {fillOpacity: 0}
                    ],{duration: 500, iterations: 1, fill: "both", easing: "linear"}).onfinish = r;
                }));
            });
            curChart.current.svg?._node?.querySelectorAll(".ct-line").forEach(elem => {
                curPromises.push(new Promise(r => {
                    if (!elem) return r();
                    elem.animate([
                        {strokeDashoffset: getComputedStyle(elem).strokeDashoffset}, {strokeDashoffset: getComputedStyle(elem).strokeDasharray}
                    ],{duration: 500, iterations: 1, fill: "both", easing: "linear"}).onfinish = r;
                }));
            });
            Promise.allSettled(curPromises).then(() => {
                curChart.current.update({
                    labels: props.labels ? props.labels : [],
                    series: props.series ? props.series : []
                });
            });
        };
    }, [props.data, props.labels]);

    return <div className={`customComponents__chart customComponents__chart--line ${props.className ? props.className : ""}`} style={{
        ...props.style
    }}>
        {props.name && <h3>{props.name}</h3>}
        <div ref={internalRef} style={{overflowX: "auto", overflowY: "hidden"}}></div>
    </div>
};

const BarChart = (props) => {
    const internalRef = React.useRef();
    const curChart = React.useRef();

    React.useEffect(() => {
        if (!internalRef?.current) return;
        if (!curChart.current) {
            curChart.current = Chartist.Bar(internalRef.current, {
                labels: props.labels ? props.labels : [],
                series: props.series ? props.series : []
            }, {
                fullWidth: true,
                axisX: {
                    showGrid: false
                },
                axisY: {
                    offset: 80,
                    labelInterpolationFnc: function(value) {
                      return props.suffix ? `${value} ${props.suffix}` : value;
                    },
                    scaleMinSpace: 15,
                    onlyInteger: true
                },
                showPoint: true,
                plugins: [props.legend ? Legend({legendNames: props.legend}) : null].filter(tmp => tmp)
            });
            curChart.current.on("created", () => curChart.current.detach());
            curChart.current.on('draw', function(data) {
                animateCharts(data, props.labels.length);
                if (data.type === "label") {
                    if (data.axis.counterUnits.dir === "vertical") {
                        if (data.index === 0) {
                            data.element._node.classList.add("ct-label-start");
                        } else if (data.index === props.labels.length-1) {
                            data.element._node.classList.add("ct-label-end");
                        } else {
                            data.element._node.classList.add("ct-label-middle");
                        };
                    };
                };
                if (data.type === 'bar') {
                    let tmpOverListen = (e => {
                        const dataPoint = createModal(
                            props.labels ? curChart.current.data.labels[data.index] : "",
                            props.suffix ? `${data.series[data.index]} ${props.suffix}` : data.series[data.index],
                            data.seriesIndex,
                            e, internalRef.current, {filterText: props.filterText, onFilter: props.onFilter});
                        if (internalRef.current) internalRef.current.appendChild(dataPoint.element);
                        let tmpLeaveListen = () => {
                            dataPoint.remove();
                            data.element._node.removeEventListener("mouseleave", tmpLeaveListen)
                        };
                        dataPoint.element.addEventListener("mouseleave", tmpLeaveListen);
                    });
                    data.element._node.addEventListener("mouseover", tmpOverListen);
                };
                return data;
             });
             let gTimeout = null;
             let inProcess = false;
             curChart.current.on("data", (data) => {
                let drawEvent = (data2) => {
                    clearTimeout(gTimeout);
                    gTimeout = setTimeout(() => {
                        if (inProcess) return;
                        inProcess = true;
                        curChart.current.off("created", drawEvent);
                        let totalWidth = 0;
                        if (!internalRef.current) return;
                        let curSvg = internalRef.current.querySelector("svg");
                        if (!curSvg) return;
                        let labelList = curSvg.querySelectorAll(".ct-labels foreignObject");
                        let boundingRectSvg = curSvg.getBoundingClientRect();
                        let isFullWidth = curChart.current.options.fullWidth;
                        
                        for (let lbl of labelList) {
                            totalWidth += lbl.children[0].getBoundingClientRect().width;
                        };
                        totalWidth += props.labels.length * 50;
                        if (totalWidth > boundingRectSvg.width) {
                            if (isFullWidth) {
                                curChart.current.update(null, {
                                    ...curChart.current.options,
                                    fullWidth: false,
                                    width: `${totalWidth}px`
                                });
                            };
                        } else {
                            if (!isFullWidth) {
                                curChart.current.update(null, {
                                    ...curChart.current.options,
                                    fullWidth: true,
                                    width: "100%"
                                });
                            };
                        };
                        setTimeout(() => inProcess = false, 300);
                    }, 0);
                };
                curChart.current.on("created", drawEvent);
             });
        };
    }, [internalRef.current]);

    React.useEffect(() => {
        if (!curChart.current) return;
        let shouldUpdate = false;
        if (props.series.length !== curChart.current.data.series.length) shouldUpdate = true;
        props.series.forEach((seriesItem, seriesIndex) => {
            if (shouldUpdate) return;
            if (!curChart.current.data.series[seriesIndex]) {
                shouldUpdate = true;
                return;
            };
            if (seriesItem.length !== curChart.current.data.series[seriesIndex].length) {
                shouldUpdate = true;
                return;
            };
            seriesItem.forEach((valueItem, valueIndex) => {
                if (shouldUpdate) return;
                if (valueItem !== curChart.current.data.series[seriesIndex][valueIndex]) {
                    shouldUpdate = true;
                    return;
                };
            });
        });
        if (shouldUpdate) {
            let curPromises = [];
            curChart.current.svg?._node?.querySelectorAll(".ct-bar").forEach(elem => {
                curPromises.push(new Promise(r => {
                    if (!elem) return r();
                    let elemLength = elem.getTotalLength();
                    elem.style.strokeDasharray = elemLength;
                    elem.style.strokeDashoffset = 0;
                    elem.animate([
                        {strokeDashoffset: 0}, {strokeDashoffset: `${elemLength}px`}
                    ],{duration: 500, iterations: 1, fill: "both", easing: "linear"}).onfinish = r;
                }));
            });
            Promise.allSettled(curPromises).then(() => {
                curChart.current.update({
                    labels: props.labels ? props.labels : [],
                    series: props.series ? props.series : []
                });
            });
        };
    }, [props.data, props.labels]);

    return <div className={`customComponents__chart customComponents__chart--bar ${props.className ? props.className : ""}`} style={{
        ...props.style
    }}>
        {props.name && <h3>{props.name}</h3>}
        <div ref={internalRef} style={{overflowX: "auto", overflowY: "hidden"}}></div>
    </div>
};

const PieChart = (props) => {
    const internalRef = React.useRef();
    const curChart = React.useRef();

    React.useEffect(() => {
        if (!internalRef?.current) return;
        if (!curChart.current) {
            curChart.current = Chartist.Pie(internalRef.current, {
                labels: props.labels ? props.labels : [],
                series: props.series ? props.series : []
            }, {
                fullWidth: true,
                donut: true,
                donutWidth: 30,
                donutSolid: false,
                startAngle: 270,
                showLabel: true,
                labelOffset: 30,
                labelDirection: 'explode',
                labelInterpolationFnc: function(value) {
                    return props.suffix ? `${value} ${props.suffix}` : value;
                },
                plugins: [props.legend ? Legend({legendNames: props.legend}) : null].filter(tmp => tmp)
            });
            curChart.current.on("created", () => curChart.current.detach());
            curChart.current.on('draw', function(data) {
                animateCharts(data, props.labels.length, true);
                if (data.type === 'slice') {
                    let hoveredCount = 0;
                    let tmpOverListen = (e => {
                        const dataPoint = createModal(
                            props.labels ? curChart.current.data.labels[data.index] : "",
                            props.suffix ? `${data.series} ${props.suffix}` : data.series,
                            data.index,
                            e, internalRef.current, {filterText: props.filterText, onFilter: props.onFilter});
                        if (internalRef.current) internalRef.current.appendChild(dataPoint.element);
                        let tmpLeaveListen = () => {
                            if (hoveredCount > 0) return;
                            dataPoint.remove();
                            data.element._node.removeEventListener("mouseleave", tmpLeaveListen)
                        };
                        dataPoint.element.addEventListener("mouseleave", tmpLeaveListen);
                    });
                    data.element._node.addEventListener("mouseover", tmpOverListen);
                };
                return data;
            });
        };
    }, [internalRef.current]);

    React.useEffect(() => {
        if (!curChart.current) return;
        let shouldUpdate = false;
        if (props.series.length !== curChart.current.data.series.length) shouldUpdate = true;
        props.series.forEach((seriesItem, seriesIndex) => {
            if (shouldUpdate) return;
            if (!curChart.current.data.series[seriesIndex]) {
                shouldUpdate = true;
                return;
            };
            if (seriesItem !== curChart.current.data.series[seriesIndex]) {
                shouldUpdate = true;
                return;
            };
        });
        if (shouldUpdate) {
            let curPromises = [];
            let allSlices = curChart.current.svg?._node?.querySelectorAll(".ct-slice-donut") ?? [];
            let animTime = 500 / allSlices.length;
            allSlices.forEach((elem, index) => {
                curPromises.push(new Promise(r => {
                    if (!elem) return r();
                    elem.animate([
                        {strokeDashoffset: getComputedStyle(elem).strokeDashoffset}, {strokeDashoffset: getComputedStyle(elem).strokeDasharray}
                    ],{duration: animTime, iterations: 1, fill: "both", easing: "linear", delay: animTime * index}).onfinish = r;
                }));
            });
            Promise.allSettled(curPromises).then(() => {
                curChart.current.update({
                    labels: props.labels ? props.labels : [],
                    series: props.series ? props.series : []
                });
            });
        };
    }, [props.data, props.labels]);

    return <div className={`customComponents__chart customComponents__chart--pie ${props.className ? props.className : ""}`} style={{
        ...props.style
    }}>
        {props.name && <h3>{props.name}</h3>}
        <div ref={internalRef}></div>
    </div>
};

export default {
    LineChart,
    BarChart,
    PieChart
};