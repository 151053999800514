import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as backendModule from './backendModule'

let openModals = [];

export const animateBox = (box, childElem = null) => {
    return new Promise(resolve => {
        let animID = Date.now();
        let cloned = box.currentTarget.cloneNode(true)
        box.currentTarget.style.opacity = 0;
        cloned.style.position = 'fixed'
        cloned.classList.add("cloned-element")
        cloned.style.top = box.currentTarget.getBoundingClientRect().y + 'px'
        cloned.style.left = box.currentTarget.getBoundingClientRect().x + 'px'
        cloned.style.width = box.currentTarget.getBoundingClientRect().width + 'px'
        cloned.style.margin = 0
        cloned.style.overflow = 'hidden'
        cloned.style.borderRadius = '0'
        cloned.style.transition = 'all 0.5s ease'
        cloned.style.transformOrigin = "center"
        cloned.style.zIndex = "999999";
        box.currentTarget.setAttribute("data-anim", String(animID));
        document.querySelector('body').appendChild(cloned)
        let cmp = getComputedStyle(cloned);
        let bnd = box.currentTarget.getBoundingClientRect();

        let offsetX = Math.floor((document.body.clientWidth / 2) - Math.round(document.body.clientWidth / 2));
        let offsetY = Math.floor((document.body.clientHeight / 2) - Math.round(document.body.clientHeight / 2));
        
        let cmpCloned = {
            top: cmp.top,
            left: cmp.left,
            width: `${bnd.width}px`,
            height: `${bnd.height}px`,
            backgroundColor: cmp.backgroundColor,
            padding: cmp.padding,
            offsetX,
            offsetY
        };

        cloned.animate([
            {
                top: cmp.top,
                left: cmp.left,
                transform: 'none',
                padding: cmp.padding,
                width: `${bnd.width}px`,
                height: `${bnd.height}px`,
            }, {
                top: `50%`,
                left: `50%`,
                padding: 0,
                transform: `translate(-50%, -50%) translateX(${offsetX}px) translateY(${offsetY}px)`,
                width: `${bnd.width}px`,
                height: `${bnd.height}px`,
            }
        ], {
            duration: 150,
            iterations: 1,
            fill: 'both',
            easing: 'ease-in-out'
        }).onfinish = () => {
            let clonedHTML = cloned.innerHTML
            cloned.innerHTML = ''
            if (childElem) {
                openModals.push({
                    id: animID,
                    close: () => closeModal(clonedHTML, cloned, cmpCloned, animID)
                });
                ReactDOM.render(<Provider store={backendModule.getStore()}>
                    {React.cloneElement(childElem, {
                        onClose: () => closeModal(clonedHTML, cloned, cmpCloned, animID)
                    })}
                </Provider>, cloned)
            }
            cloned.animate([
                {
                    width: cmp.width,
                    height: cmp.height,
                    backgroundColor: cmp.backgroundColor,
                }, {
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }
            ], {
                duration: 150,
                iterations: 1,
                fill: 'both',
                easing: 'ease-in-out'
            }).onfinish = () => {
                resolve()
            };
        }
    })
}
export const closeModal = (original, clone, cmp, animID) => {
    openModals = openModals.filter(t => t.id !== animID);

    clone.animate([
        {
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }, {
            width: cmp.width,
            height: cmp.height,
            backgroundColor: cmp.backgroundColor,
        }
    ], {
        duration: 150,
        iterations: 1,
        fill: 'both',
        easing: 'ease-in-out'
    }).onfinish = () => {
        clone.innerHTML = original
        clone.animate([
            {
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%) translateX(${cmp.offsetX}px) translateY(${cmp.offsetY}px)`,
                padding: getComputedStyle(clone).padding
            }, {
                top: cmp.top,
                left: cmp.left,
                transform: 'none',
                padding: cmp.padding
            }
        ], {
            duration: 150,
            delay: 100,
            iterations: 1,
            fill: 'both',
            easing: 'ease-in-out'
        }).onfinish = () => {
            let origElem = document.querySelector(`*[data-anim='${animID}']`);
            if (origElem) {
                origElem.style.opacity = null;
                origElem.style.position = null;
                origElem.style.top = null;
                origElem.style.left = null;
                //origElem.style.margin = null;
                origElem.style.overflow = null;
                origElem.style.borderRadius = null;
                origElem.style.transition = null;
                origElem.removeAttribute("data-anim");
            };

            clone.remove()
        }
    }
}

export const closeAllModals = () => {
    return new Promise(resolve => {
        while (openModals.length > 0) {
            openModals.pop().close();
        };
        return resolve();
    });
};