import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "../../../../components/customComponents/Radio";
import { useSelector } from "react-redux";
import { getLeadColor } from "../../../../modules/leadModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";


const AdvertiserStats = () => {
    let userInfo = useSelector((state) => state.userData);
    const [spinnerVisible, setSpinnerVisible] = React.useState(false);
    const [publisherData, setPublisherData] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [addFilter, setAddFilter] = React.useState(() => null);

    const advertiserSelector = useSelector(state => state?.userData?.userData?.AdvertiserInfo);
    const timestampSelector = useSelector(state => state?.timeStamp?.timeStamp);
    const userData = useSelector((state) => state.userData);

    const [statusCodes, setStatusCodes] = React.useState()
    const getLeadStatusCodes = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getStatusCodes`,
            data: {
                BoundAdvertiser: userInfo.userData.AdvertiserInfo.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setStatusCodes(res.data.data)
            }
        }).catch(() => {
        })
    };
    const getData = async (silent = false) => {
        !silent && setSpinnerVisible(true);
        await new Promise(r2 => setTimeout(() => r2(), 300));
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForAdvertiserDashboard`,
            data: {
                BoundAdvertiser: advertiserSelector?.ID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPublisherData(res.data);
            } else {
                !silent && setPublisherData({ status: "error", data: "Can't fetch data.\nTry again later." });
            };
        }).catch(() => {
            !silent && setPublisherData({ status: "error", data: "Server timed out" });
        }).finally(() => {
            !silent && setSpinnerVisible(false);
        });
    };







    React.useEffect(() => {
        getData()
    }, [])



    if (userInfo.userData?.AdvertiserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="route__statistic">
        <FilteredCustomTable
        headers={['Date', "Total", "Confirmed", 'Waiting', 'Spent' ]}
        data={(()=>{
            let tmp=publisherData?.data?.leads.map((item, index)=>{
                return [
                    { keyID: index, type: "text", text: item.LeadDate },
                    { keyID: index, type: "text", text: item.LeadCount },
                    { keyID: index, type: "text", text: `${item.ConvertedLeads} (${item.ConvertedLeads/item.LeadCount*100}%) ` },
                    { keyID: index, type: "text", text: item.LeadWaiting },
                    { keyID: index, type: "text", text: item.LeadEarnings  }
                ]
            })
            return tmp
        })()}
        
        />
        

    </div>
};

export default AdvertiserStats;