import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import {getParamsFromURL} from "../../../../modules/urlModule";
import {useLocation, useNavigate} from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import {useSelector} from "react-redux";

import {FilteredCustomTable} from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomButton from "../../../../components/customComponents/Button";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";

const AdminDomains = () => {
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [canPaginate, setCanPaginate] = React.useState(false);

    let [addDomainVisible, setAddDomainVisible] = React.useState(false);
    let [addDomainError, setAddDomainError] = React.useState("");
    let addDomainRef = React.useRef();

    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();
    let curUser = useSelector(state => state.userData?.userData?.UserInfo?.ID);

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const continueDomain = () => {
        if (curPagination.current === -1) return;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getInternalDomains`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({status: "ok", data: [...userData.data, ...res.data.data.DBData]});
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const getDomains = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getInternalDomains`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({status: "ok", data: res.data.data.DBData});
            } else {
                setUserData({status: "error", data: "There was an error while getting internal domains"});
            };
        }).catch(() => {
            setUserData({status: "error", data: "Server timed out!"});
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const PaginationDomain = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try{observer.unobserve(tmpRef.current);} catch{};
                        canPaginate && setTimeout(continueDomain, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try{observer.unobserve(tmpRef.current);} catch{};
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    const addDomain = (DomainName) => {
        setAddDomainError("");
        if (!curUser) {
            setAddDomainError("Something happened... are you logged in?!");
            return;
        }
        if (!DomainName) {
            setAddDomainError("Domain name can't be empty!");
            return;
        };
        try {
            new URL(`http://${DomainName}`);
        } catch {
            setAddDomainError("Domain is not valid!");
            return;
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/addDomain`,
            data: {
                DomainName: `http://${DomainName}`,
                BoundUser: curUser,
                isInternal: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAddDomainVisible(false);
                getDomains();
            } else {
                setAddDomainError("There was an error while adding domain!");
            };
        }).catch(() => {
            setAddDomainError("A server error occured while trying to add a domain!");
        });
    };

    const DeleteDomain = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/deleteDomain`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to delete domain!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminDomains__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminDomains__verifyPublisher">
            <p style={{fontFamily: 'Montserrat'}} >This will delete the internal domain</p>
            <p style={{fontFamily: 'Montserrat'}}>It cant be used anymoe, this might cause issues for people who are using it to get links.</p>
            <p style={{fontFamily: 'Montserrat'}}>Existing links will still work!</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{backgroundColor: "#00A3FF", color: 'white'}} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const EditDomain = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        let [oldData, setOldData] = React.useState({});

        let domainNameRef = React.useRef();

        const getData = () => {
            setSpinner(true);
            axios({
                method: "POST",
                url:`${backendModule.backendURL}/domains/getDomainByID`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(async res => {
                if (res.data.status === "ok") {
                    setSpinner(false);
                    setOldData(res.data.data);
                    while (!domainNameRef.current) {
                        await new Promise(r2 => setTimeout(() => r2(), 50));
                    };
                    domainNameRef.current.value = res.data.data.DomainName
                } else {
                    setFinalData("Failed to get domain data!");
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
            }).finally(() => {
                setSpinner(false);
            });
        };


        const performVerify = () => {
            let DomainName = domainNameRef.current.value;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/editDomain`,
                data: {
                    ...oldData,
                    DomainName: `http://${DomainName}`
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/domains/getDomainByID`,
                        data: {
                            ID: props.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.status === "error") {
                            setFinalData("There was an error while getting the new domain!");
                            setSpinner(false);
                        } else {
                            props.c().then(() => {
                                if (props.onUpdate) props.onUpdate(final.data.data);
                                setSpinner(false);
                            });
                        }
                    }).catch(() => {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to edit domain!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        React.useEffect(() => {
            setImmediate(getData);
        }, [domainNameRef]);

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminDomains__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(props.user);
                    });
                }} />
            </div>
        }
        return <div className="route__adminDomains__addUser">
            <div className="route__adminDomains__addUser__userInfo">
                <CustomInput placeholder1="Domain name" ref={domainNameRef} />
            </div>
            <ButtonSmall value="Save" onClick={performVerify} style={{backgroundColor: "#00A3FF", color: 'white', marginRight: "10px"}} />
            <ButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };


    React.useEffect(() => {
        getDomains();
    }, [filters]);
    
    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    return <div className="route__adminDomains">
        <p style={{fontFamily: 'Montserrat'}}>NOTE: Domains added here will be available to ANYONE.</p>
        <p style={{marginBottom: "30px", fontFamily: 'Montserrat' }}>Its more of a way to add some domains that can be used from the beginning.</p>
        <button  className="route__adminDomains__addDomainBtn" onClick={() => setAddDomainVisible(!addDomainVisible)} >Add domain <img src="/images/plusImageWhite.png"/></button>
        <div className={`route__adminDomains__addDomain ${addDomainVisible ? "route__adminDomains__addDomain--active" : ""}`}>
            <p style={{fontFamily: 'Montserrat'}}>Add a new domain</p>
            <p style={{fontFamily: 'Montserrat'}}>Don't start the domain with HTTP://, that is automatically added.</p>
            <CustomInput placeholder1="Domain name" ref={addDomainRef} />
            <ButtonSmall value="Add" style={{display: "inline"}} onClick={() => addDomain(addDomainRef.current?.value)} />
            <p className="route__adminDomains__addDomainError">{addDomainError}</p>
        </div>
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return <p>{userData.data}</p>
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Domain name"]} data={(()=>{
                    let tmp = userData.data.map((item, index) => {
                        return [
                            {keyID: item.ID, type: "text", text:item.DomainName},
                            {keyID: item.ID, type: "groupNewline", group: [
                                {keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                    return <EditDomain ID={item.ID} user={item} c={c} onUpdate={(usr) => {
                                        if (usr) {
                                            setUserData({status: "ok", data: [
                                                ...userData.data.filter((_, index2) => index2 < index),
                                                usr,
                                                ...userData.data.filter((_, index2) => index2 > index)
                                            ]});
                                        };
                                    }} />
                                }},
                                {keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => {
                                    return <DeleteDomain ID={item.ID} c={c} onUpdate={() => {
                                        setUserData({status: "ok", data: [
                                            ...userData.data.filter((_, index2) => index2 < index),
                                            ...userData.data.filter((_, index2) => index2 > index)
                                        ]});
                                    }} />
                                }},
                            ]}
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{keyID: "customSpinner", type: "custom", data: <Spinner />}]);
                    if (curPagination.current !== -1) tmp.push([{keyID: "customPagination", type: "custom", data: <PaginationDomain />}])

                    return tmp;
                })()} filters={[
                    {name: "DomainName", friendlyName: "Domain name", type: "string"},
                ]}/>
            };
        })()}
    </div>
};

export default AdminDomains;