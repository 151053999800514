import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";


import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import LandingPage from "./routes/LandingPage";
import Login from "./routes/Login";
import AboutUs from "./routes/AboutUs";
import Publishers from "./routes/Publishers";
import Advertisers from "./routes/Advertisers";
import Container from './components/customComponents/Container'
import Dashboard from "./routes/Dashboard";
import Domains from "./routes/Dashboard/Publisher/Domains";
import Register from './routes/Register';
import Profile from "./routes/Profile";
import CRM from "./routes/Dashboard/Advertiser/CRM";
import OffersPublisher from "./routes/Dashboard/Publisher/Offers";
import AdvertiserOffers from "./routes/Dashboard/Advertiser/Offers/advertiserOffers";
import Article from "./routes/Dashboard/Publisher/SingleArticle";
import PublisherLeads from "./routes/Dashboard/Publisher/Leads";
import AdvertiserLeads from "./routes/Dashboard/Advertiser/Leads/leadsAdvertisert";
import Previous from "./routes/previousConference";

import SideBar from "./components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import axios from "axios";
import * as backendModule from './modules/backendModule'
import * as userDataActions from './actions/userDataActions'
import AnimateModule from './modules/animateModule'
import ResetPassword from "./routes/ResetPassword";
import TermsAndConditions from "./routes/TermsAndConditions";
import PrivacyPolicy from "./routes/PrivacyPolicy";


const App = () => {
  let timestamp = useSelector((state) => state.timeStamp);
  let userData = useSelector((state) => state.userData)
  let userDataDispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      data: {
        BoundUser: userData.userData?.UserInfo?.ID
      },
      ...backendModule.axiosConfig
    }).then((e) => {
      if (e.data.status === "ok") {
        userDataDispatch(userDataActions.setUserData(e.data.data));
        userDataDispatch(userDataActions.setLoginStatus(true));
      } else {
        userDataDispatch(userDataActions.setUserData(null));
        userDataDispatch(userDataActions.setLoginStatus(false));
      };
    }).catch((e) => {
      userDataDispatch(userDataActions.setUserData(null));
      userDataDispatch(userDataActions.setLoginStatus(false));
    });
  }, [timestamp])

 React.useEffect(()=>{
   if(window.location.href.includes('dashboard')===false){
     document.body.classList.remove('dark')
   }
 })
  

  return <React.Fragment>

    <Header />
    <SideBar />
    <ContentWrapper>
      <Routes>

        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword/>} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/publishers" element={<Publishers/>} />
        <Route path="/advertisers" element={<Advertisers/>} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="conference" element={<Previous/>}/>
        <Route path="/terms" element={<TermsAndConditions/>}/>
        <Route path="/privacy" element={<PrivacyPolicy/>}/>


      </Routes>
    </ContentWrapper>
  </React.Fragment>
};

export default App;