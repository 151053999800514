import React from "react";
import "./index.scss";
import axios from "axios";
import moment from "moment";

import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { getIntFromMonthName, spliceDateForCharts } from "../../../modules/dateModule";

import Spinner from "../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import Chart from "../../../components/customComponents/Chart";
import FilteredCustomTable from "../../../components/customComponents/Table";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import KPI from "../../../components/customComponents/KPI";

const ReferralOverview = () => {
    const [publisherData, setPublisherData] = React.useState();
    const [advertiserData, setAdvertiserData] = React.useState();
    const [referralRegisterData, setReferralRegisterData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [addFilter, setAddFilter] = React.useState(() => null);
    const curUserID = useSelector(state => state?.userData?.serData?.UserInfo?.ID);
    const referralLinkID = useSelector(state =>state?.userData?.userData?.RefferalInfo?.RefferalLink);
    const userData = useSelector((state) => state.userData);


    const addNewFilter = (type, value) => {
        switch (type){
            case "date":
                let nd = value.split(".");
                let finalD = new Date(`${nd[2]}-${getIntFromMonthName(nd[1])+1}-${nd[0]}`);
                addFilter({
                    name: "createdAt",
                    filterType: "date",
                    varType: "string",
                    friendlyName: "Date",
                    value: finalD.toISOString(),
                    type: "deq",
                    friendlyValue: finalD.toLocaleDateString()
                });
                return;
            case "offer":
                addFilter({
                    name: "OfferName",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "Offer name",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            case "city":
                addFilter({
                    name: "PurchaseLocation:city",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "City",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            case "domain":
                addFilter({
                    name: "PurchaseDomain",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "Domain",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            default: return;
        };
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/referral/getAllPublisherReferralsForUser`,
            data: {
                BoundUser: curUserID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPublisherData({status: "ok", data: res.data.data, timestamp: Date.now()});
            } else {
                setPublisherData({status: "error", data: null});
            };
        }).catch(() => setPublisherData({status: "error", data: null}));;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/referral/getAllAdvertiserReferralsForUser`,
            data: {
                BoundUser: curUserID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAdvertiserData({status: "ok", data: res.data.data, timestamp: Date.now()});
            } else {
                setAdvertiserData({status: "error", data: null});
            };
        }).catch(() => setAdvertiserData({status: "error", data: null}));
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/referral/getAllReferralRegisters`,
            data: {
                BoundUser: curUserID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setReferralRegisterData({status: "ok", data: res.data.data, timestamp: Date.now()});
            } else {
                setReferralRegisterData({status: "error", data: null});
            };
        }).catch(() => setReferralRegisterData({status: "error", data: null}));
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    if (userData.userData?.UserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="referral__main">{(() => {
        if (!publisherData || !advertiserData || !referralRegisterData) return <Spinner />
        if (publisherData?.status === "error" || advertiserData.status === "error" || referralRegisterData.status === "error") return <div className="referral__main__err">
            <p>There was an error while getting data</p>
            <CustomButtonSmall value="Retry" onCLick={() => getData()} />
        </div>
        return (() => {
            let finalData = [];
            let labels = [];
            [...Object.keys(publisherData.data.overtime), ...Object.keys(advertiserData.data.overtime)].forEach(key => {
                if (!labels.includes(key)) labels.push(key);
            });
            labels.sort();
            labels.forEach(label => {
                let curDate = moment(label).format("DD.MM.YYYY");
                finalData.push({
                    Date: spliceDateForCharts(curDate),
                    Publisher: publisherData.data.overtime[label] ?? 0,
                    Advertiser: advertiserData.data.overtime[label] ?? 0
                });
            });
            return <>
                <FilterPanel filterCB={data => setFilters(data)} addFilter={cb => setAddFilter(cb)} className="referral__main__spanAll" filters={[
                    {name: "createdAt", type: "date", friendlyName: "Date"},
                    {name: "ReferralEarnings", friendlyName: "Earned amount (per lead)", type: "number"},
                    {name: "ReferralHasPaidOut", friendlyName: "Has paid out (money withdrawn)", type: "boolean"}
                ]} />
                <KPI name="Unique publishers" value={`${String(publisherData.data.unique)}`} />
                <KPI name="Total earned from publishers" value={`${String(Number(Object.keys(publisherData.data.overtime).map(item => publisherData.data.overtime[item]).reduce((acc,val) => acc+val,0)).toFixed(2)) ?? String(Number(0).toFixed(2))} $`} />
                <KPI name="Unique advertisers" value={`${String(advertiserData.data.unique)}`} />
                <KPI name="Total earned from advertisers" value={`${String(Number(Object.keys(advertiserData.data.overtime).map(item => advertiserData.data.overtime[item]).reduce((acc,val) => acc+val,0)).toFixed(2)) ?? String(Number(0).toFixed(2))} $`} />
                <KPI name="Total referral registers" value={`${referralRegisterData.data}`} />
                <KPI name="Total combined earnings" value={`${String(Number(Object.keys(advertiserData.data.overtime).map(item => advertiserData.data.overtime[item]).reduce((acc,val) => acc+val,0)+Object.keys(publisherData.data.overtime).map(item => publisherData.data.overtime[item]).reduce((acc,val) => acc+val,0)).toFixed(2)) ?? String(Number(0).toFixed(2))} $`} />
                { referralLinkID && <div className="referral__main__refLink referral__main__spanAll">
                    <p>Your referral link: <span style={{
                        color: "#00A3FF"
                    }}>{`https://scale-lead.com/#/?ref=${referralLinkID}`}</span></p>
                </div>}
                <Chart.LineChart className="referral__main__spanAll" onFilter={d => addNewFilter("date", d)} filterText="Filter by date" name="Referral earnings over time" labels={finalData.map(item => item.Date)} series={[finalData.map(item => item.Advertiser), finalData.map(item => item.Publisher)]} style={{height: "300px"}} legend={["Advertisers", "Publishers"]} suffix="$" />
            </>;
        })();
    })()}</div>
};

export default ReferralOverview;