import "./index.scss";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTimestamp } from "../../../../actions/timestampActions";
import { spliceDateForCharts, getIntFromMonthName } from "../../../../modules/dateModule";
import { countries } from "../../../../modules/countryModule";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import AnimateModule from "../../../../modules/animateModule";

import Chart from "../../../../components/customComponents/Chart";
import FilterPanel from "../../../../components/customComponents/FilterPanel";
import Spinner from "../../../../components/customComponents/Spinner";
import KPI from "../../../../components/customComponents/KPI";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import CustomTabs from "../../../../components/customComponents/CustomTabs";

const DashboardPublisher = () => {
    const [spinnerVisible, setSpinnerVisible] = React.useState(false);
    const [publisherData, setPublisherData] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [addFilter, setAddFilter] = React.useState(() => null);

    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo);
    const timestampSelector = useSelector(state => state?.timeStamp?.timeStamp);
    const userData = useSelector((state) => state.userData);
    const publisherDispatch = useDispatch();

    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const addNewFilter = (type, value) => {
        switch (type){
            case "date":
                let nd = value.split(".");
                let finalD = new Date(`${nd[2]}-${getIntFromMonthName(nd[1])+1}-${nd[0]}`);
                addFilter({
                    name: "createdAt",
                    filterType: "date",
                    varType: "string",
                    friendlyName: "Date",
                    value: finalD.toISOString(),
                    type: "deq",
                    friendlyValue: finalD.toLocaleDateString()
                });
                return;
            case "offer":
                addFilter({
                    name: "OfferName",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "Offer name",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            case "city":
                addFilter({
                    name: "PurchaseLocation:city",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "City",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            case "country":
                addFilter({
                    name: "PurchaseLocation:countryCode",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "Country",
                    value,
                    type: "eq",
                    friendlyValue: countries.find(item => item.code === value)?.name ?? "Unknown"
                });
                return;
            case "domain":
                addFilter({
                    name: "PurchaseDomain",
                    filterType: "string",
                    varType: "string",
                    friendlyName: "Domain",
                    value,
                    type: "eq",
                    friendlyValue: value
                });
                return;
            default: return;
        };
    };

    const getData = async (silent = false) => {
        if (!userSelector?.ID) return animateNavigate("/dashboard");
        !silent && setSpinnerVisible(true);
        await new Promise(r2 => setTimeout(() => r2(), 300));
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadsForPublisherDashboard`,
            data: {
                BoundUser: userSelector?.ID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPublisherData(res.data);
            } else {
                !silent && setPublisherData({status: "error", data: "Can't fetch data.\nTry again later."});
            };
        }).catch(() => {
            !silent && setPublisherData({status: "error", data: "Server timed out"});
        }).finally(() => {
            !silent && setSpinnerVisible(false);
        });
    };

    React.useEffect(() => {
        getData();
    }, [filters, timestampSelector]);

    React.useEffect(() => {
        let ct = null;
        let cb = () => {
            clearTimeout(ct);
            ct = setTimeout(() => {
                getData(true);
                cb();
            }, 60000);
        };
        cb();
        for (let event of ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']) {
            window.addEventListener(event, cb, true);
        };
        return () => {
            for (let event of ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']) {
                window.addEventListener(event, cb, true);
            };
        };
    }, []);
    
    if (userData.userData?.UserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };
    return <div className="dashboardPublisher" key="mainKey">
        {publisherData.status === "error" && <div className="dashboardPublisher__error">
            <p>There was an error while getting data</p>
            <p>{publisherData.data}</p>
            <CustomButtonSmall onClick={() => {
                setPublisherData({});
                publisherDispatch(updateTimestamp());
            }} value="Retry" style={{marginTop: "10px"}} />
        </div>}
        <div className="dashboardPublisher__spinner" style={{opacity: spinnerVisible ? 1 : 0, position: "absolute", height: '100vh', width:'100%', left: 0}}>
            <Spinner />
            <p>Loading...</p>
        </div>
        <FilterPanel filterCB={data => setFilters(data)} addFilter={cb => setAddFilter(cb)} key="filterpanelKey" filters={[
            {name: "OfferName", type: "string", friendlyName: "Offer name"},
            {name: "FriendlyName", type: "string", friendlyName: "Custom name"},
            {name: "ProductName", type: "string", friendlyName: "Product name"},
            {name: "PurchaseIsPhone", type: "boolean", friendlyName: "Is mobile device"},
            {name: "PurchaseDomain", type: "string", friendlyName: "Domain name"},
            {name: "PurchaseLocation:countryCode", type: "custom", data: countries.map(elem => {
                return {value: elem.code, text: elem.name}
            }), varType: "string", friendlyName: "Country"},
            {name: "PurchaseLocation:city", type: "string", friendlyName: "City"},
            {name: "PurchaseLeadType", type: "custom", varType: "string", data: [
                {text: "Cost per action (CPA)", value: "CPA"},
                {text: "Cost per lead (CPL)", value: "CPL"}
            ], friendlyName: "Lead type"},
            {name: "PublisherPaid", type: "boolean", friendlyName: "Lead has been paid"},
            {name: "createdAt", type: "date", friendlyName: "Date"}
        ]} className="dashboardPublisher--spanAll" />
        {(() => {
            if (!(publisherData?.data?.leads instanceof Array)) return null;
            let leadLabels = [];
            let allSuccededLeadsPerDate = [];
            let allFailedLeadsPerDate = [];
            let allLeads = [];
            let leadEarnings = [];
            let leadUniqueIP = [];
            let waitingLeads = [];
            for (let elem of publisherData.data.leads) {
                allSuccededLeadsPerDate.push(elem.ConvertedLeads);
                allFailedLeadsPerDate.push(elem.LeadCount - elem.ConvertedLeads - elem.LeadWaiting);
                allLeads.push(elem.LeadCount);
                waitingLeads.push(elem.LeadWaiting);
                leadLabels.push(spliceDateForCharts(elem.LeadDate));
                leadEarnings.push(Number(elem.LeadEarnings).toFixed(2));
                leadUniqueIP.push(elem.UniqueIPAddresses);
            };
            let uniqCityNames = [];
            let uniqCityCounts = [];

            let uniqContryNames = [];
            let uniqContryCounts = [];

            for (let i = 0; i<=publisherData.data.uniqueCities.length-1; i++) {
                if (i >= 10) break;
                uniqCityCounts.push(publisherData.data.uniqueCities[i].count);
                uniqCityNames.push(publisherData.data.uniqueCities[i].name);
            };
            for (let i = 0; i<=publisherData.data.uniqueCountryCodes.length-1; i++) {
                if (i >= 10) break;
                uniqContryCounts.push(publisherData.data.uniqueCountryCodes[i].count);
                uniqContryNames.push(publisherData.data.uniqueCountryCodes[i].name);
            };
            let conversionRatio = Number(100 / (publisherData.data?.totalLeadCount - waitingLeads.reduce((acc, val) => acc+val, 0)) * publisherData.data?.totalConvertedLeadsCount);
            return <>
                <div className="dashboardPublisher--spanAll dashboardPublisher__kpiPanel">
                    <KPI name="Total earned" value={`${Number(publisherData.data?.totalEarned).toFixed(2) ?? "?"} $`} />
                    <KPI name="Total leads" value={Number(publisherData.data?.totalLeadCount).toFixed(0) ?? "?"} />
                    <KPI name="Converted leads" value={Number(publisherData.data?.totalConvertedLeadsCount).toFixed(0) ?? "?"} />
                    <KPI name="Failed leads" value={Number((publisherData.data?.totalLeadCount - publisherData.data?.totalConvertedLeadsCount)).toFixed(0) ?? "?"} />
                    <KPI name="Conversion ratio" value={`${isNaN(conversionRatio) ? "?" : conversionRatio.toFixed(2)} %`} />
                    <KPI name="Total leads from mobile" value={Number(publisherData.data?.totalPhoneCount).toFixed(0)} />
                    <KPI name="Total leads from desktop" value={Number(publisherData.data?.totalLeadCount - publisherData.data?.totalPhoneCount).toFixed(0)} />
                    <KPI name="Active offers" value={Number(publisherData.data.totalLeadOfferNames.length).toFixed(0)} />
                </div>

                {allLeads.length > 1 ?
                <Chart.LineChart onFilter={d => addNewFilter("date", d)} filterText="Filter by date" className="dashboardPublisher--spanAll" name="Lead conversion (convert / fail)" labels={leadLabels} series={[[...allSuccededLeadsPerDate], [...allFailedLeadsPerDate], [...waitingLeads]]} style={{height: "300px"}} legend={["Converted", "Failed", "Waiting"]} suffix="leads" /> :
                <Chart.BarChart onFilter={d => addNewFilter("date", d)} filterText="Filter by date" className="dashboardPublisher--spanAll" name="Lead conversion (convert / fail)" labels={leadLabels} series={[[...allSuccededLeadsPerDate], [...allFailedLeadsPerDate], [...waitingLeads]]} style={{height: "300px"}} legend={["Converted", "Failed", "Waiting"]} suffix="leads" />}

                {allLeads.length > 1 ?
                <Chart.LineChart onFilter={d => addNewFilter("date", d)} filterText="Filter by date" className="dashboardPublisher--spanAll" name="Total leads / conversions" labels={leadLabels} series={[[...allLeads], [...allSuccededLeadsPerDate]]} style={{height: "300px"}} legend={["Total", "Converted"]} suffix="leads" /> :
                <Chart.BarChart onFilter={d => addNewFilter("date", d)} filterText="Filter by date" className="dashboardPublisher--spanAll" name="Total leads / conversions" labels={leadLabels} series={[[...allLeads], [...allSuccededLeadsPerDate]]} style={{height: "300px"}} legend={["Total", "Converted"]} suffix="leads" />}
                <CustomTabs className="dashboardPublisher--spanAll" autoScroll={false} tabs={[
                    {name: "Earnings over time", text: <Chart.BarChart onFilter={d => addNewFilter("date", d)} filterText="Filter by date" className="dashboardPublisher--spanAll" name="Earnings over time" labels={leadLabels} series={[[...leadEarnings]]} style={{height: "300px"}} legend={["Earnings"]} suffix="$" />},
                ]} />
                <CustomTabs className="dashboardPublisher--spanAll" tabs={[
                    {name: "Unique IPs / Total leads", text: <Chart.BarChart onFilter={d => addNewFilter("date", d)} key="0" filterText="Filter by date" name="Unique IPs / Total leads" labels={leadLabels} series={[[...allLeads], [...leadUniqueIP]]} style={{height: "300px"}} suffix="leads" legend={["All leads", "Unique IP"]} />},
                    {name: "Total leads by offers", text: <Chart.BarChart onFilter={d => addNewFilter("offer", d)} key="1" filterText="Filter by offer" name="Total leads by offers" labels={publisherData.data.totalLeadOfferNames.map(elem => elem.name)} series={[publisherData.data.totalLeadOfferNames.map(elem => elem.count)]} style={{height: "300px"}} suffix="leads" legend={["Offer"]} />}
                ]} />
                <Chart.PieChart onFilter={d => addNewFilter("city", d)} filterText="Filter by city" name="TOP 10 cities" labels={uniqCityNames} series={[...uniqCityCounts]} style={{height: "300px"}} />
                <Chart.PieChart onFilter={d => addNewFilter("country", d)} filterText="Filter by country" name="TOP 10 countries (codes)" labels={uniqContryNames} series={[...uniqContryCounts]} style={{height: "300px"}} />
                <CustomTabs className="dashboardPublisher--spanAll" autoScroll={true} tabs={[
                    {name: "Domains", text: <FilteredCustomTable className="dashboardPublisher--spanAll dashboardPublisher--bcg" headers={["Domain name", "Leads received", "Action"]} data={publisherData.data.uniqueDomains.map(elem => {
                        return [
                            {type: "text", text: elem.name},
                            {type: "text", text: elem.count},
                            {type: "button", text: "Filter by domain", onClick: () => addNewFilter("domain", elem.name)}
                        ];
                    })} /> },
                    {name: "Countries", text: <FilteredCustomTable className="dashboardPublisher--spanAll dashboardPublisher--bcg" headers={["Country name", "Leads received", "Action"]} data={publisherData.data.uniqueCountryCodes.map(elem => {
                        let tmp = countries.find(item => item.code === elem.name);
                        return [
                            {type: "text", text: tmp ? tmp.name : "Unknown"},
                            {type: "text", text: elem.count},
                            {type: "button", text: "Filter by country", onClick: () => addNewFilter("country", elem.name)}
                        ];
                    })} /> },
                    {name: "Cities", text: <FilteredCustomTable className="dashboardPublisher--spanAll dashboardPublisher--bcg" headers={["City name", "Leads received", "Action"]} data={publisherData.data.uniqueCities.map(elem => {
                        return [
                            {type: "text", text: elem.name},
                            {type: "text", text: elem.count},
                            {type: "button", text: "Filter by city", onClick: () => addNewFilter("city", elem.name)}
                        ];
                    })} />}
                ]} /> 
            </>
        })()}
    </div>
};

export default DashboardPublisher;