import "./index.scss";
import React from "react";
import CustomInput from '../../../../components/customComponents/CustomInput'
import Spinner from '../../../../components/customComponents/Spinner'
import Button from '../../../../components/customComponents/Button'
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import axios from "axios";
import * as backendModule from '../../../../modules/backendModule'
import { useDispatch, useSelector } from "react-redux";
import * as userDataActions from '../../../../actions/userDataActions'
import AnimateModule from '../../../../modules/animateModule'
import { useNavigate } from "react-router-dom";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation } from "react-router-dom";
const Domains = () => {
    let location = useLocation();
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let [canPaginate, setCanPaginate] = React.useState(false);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let curPagination = React.useRef();
    let [filters, updateFilters] = React.useState([]);
    let [userData, setUserData] = React.useState(null);
    let [firstTime, setFirstTime] = React.useState(true);
    let userInfo = useSelector((state) => state.userData);
    const [domains, setDomains] = React.useState([])
    const openAddContainer = () => {
        let container = document.querySelector('.domains__addDomain')
        if (container.offsetHeight === 0) {
            if (window.innerWidth > 450) {
                container.style.height = '100px'
            } else {
                container.style.height = '150px'
            }
            container.style.padding = '20px'
        } else {
            container.style.height = '0'
            container.style.padding = '0'
        }
        document.querySelector('.domains__addDomain__msg').style.display = 'none'
    }
    const addDomain = () => {
        let input = document.querySelector('.domains__addDomain__input')
        if (input.value !== '' && input.value.includes('.')) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/addDomain`,
                data: {
                    BoundUser: userInfo.userData.UserInfo.ID,
                    DomainName: 'http://' + input.value,
                    BoundUser: userInfo.userData?.UserInfo?.ID,
                },
                ...backendModule.axiosConfig
            }).then((e) => {
                getLeads()
                if (e.data.status === 'ok') {
                    document.querySelector('.domains__addDomain__msg').innerHTML = 'Domain successfully added'
                    document.querySelector('.domains__addDomain__msg').style.color = 'green'
                    document.querySelector('.domains__addDomain__msg').style.display = 'block'
                    setTimeout(() => {
                        openAddContainer()
                    }, 500)
                } else {
                    document.querySelector('.domains__addDomain__msg').innerHTML = 'Domain add failed '
                    document.querySelector('.domains__addDomain__msg').style.color = 'red'
                    document.querySelector('.domains__addDomain__msg').style.display = 'block'
                }
            }).catch((e) => {
            })
        } else {
            document.querySelector('.domains__addDomain__msg').innerHTML = 'Please write your domain correctly 2'
            document.querySelector('.domains__addDomain__msg').style.color = 'red'
            document.querySelector('.domains__addDomain__msg').style.display = 'block'
        }
    }
    const openEditContainer = (e) => {
        if (window.innerWidth > 600) {
            e.target.parentNode.querySelector('.domains__table__data__edit').style.height = '100px'
        } else {
            e.target.parentNode.querySelector('.domains__table__data__edit').style.height = '200px'
        }

        e.target.parentNode.querySelector('.domains__table__data__edit').style.padding = '20px 0'
    }
    const closeEditContainer = (e) => {
        e.target.parentNode.parentNode.querySelector('.domains__table__data__edit').style.height = '0'
        e.target.parentNode.parentNode.querySelector('.domains__table__data__edit').style.padding = '0'
    }
    const editDomain = (e, ID) => {
        let input = e.target.parentNode.querySelector('input')
        if (input.value !== '') {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/editDomain`,
                data: {
                    BoundUser: userInfo.userData.UserInfo.ID,
                    DomainName: 'http://' + input.value,
                    ID: ID,
                    BoundUser: userInfo.userData?.UserInfo?.ID,
                },
                ...backendModule.axiosConfig
            }).then(() => {
                getLeads()
                input.value = ''
                closeEditContainer(e)
            }).catch((e) => {
            })
        }

    }

    const deleteDomain = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/deleteDomain`,
            data: {
                BoundUser: userInfo.userData.UserInfo.ID,
                ID: ID,
                BoundUser: userInfo.userData?.UserInfo?.ID,
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            getLeads()
        }).catch((e) => {
        })
    }




    const getLeads = () => {
        curPagination.current = 0;
        setLoadingSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getDomainsForUser`,
            data: {
                paginationOffset: curPagination.current,
                ID: userInfo.userData?.UserInfo?.ID,
                showInternal: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {


                setUserData({ status: "ok", data: res.data.data, timestamp: Date.now() });

            } else {
                setUserData({ status: "error", data: "There was an error while getting domains" });
            };
        }).catch((e) => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
        });
    };


    React.useEffect(() => {
        getLeads();
    }, [filters]);

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    return <>{userInfo.userData?.UserInfo?.Flags?.isVerified === true ? <div className="domains">
        <button className="domains__button" onClick={() => openAddContainer()} >Add domain <img src="/images/plusImageWhite.png" /></button>
        <div className="domains__addDomain">
            <p>http://</p>
            <input className="domains__addDomain__input" placeholder="Type your domain here..." />
            <CustomButtonSmall value={'Add domain'} onClick={() => { addDomain() }} />
        </div>
        <p className="domains__addDomain__msg"></p>
        <div className="route__leads">
            {(() => {
                if (!userData) return <Spinner />
                if (userData.status === "error") {
                    return null
                } else {
                    return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} 
                    headers={['Domain name', '', '']}
                     data={(() => {
                        let tmp = userData.data.map((item, index) => {
                            return [
                                { keyID: item.ID, type: 'text', text: item.DomainName },
                                { keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: (c) => {
                                            return <div className="offersDelete" >
                                                <CustomInput placeholder1={' Enter new domain'} />
                                                <div>
                                                    <CustomButtonSmall value='Confirm' style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} onClick={(e) => editDomain(e, item.ID)} />
                                                    <CustomButtonSmall value='Cancel' onClick={() => c()} />
                                                </div>
                                            </div>
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: 'button', text: 'Delete', triggerDropdown: true, triggerData: (c) => {
                                            return <div className="offersDelete">
                                                <p>Are you sure you want to delete this offer?</p>
                                                <div>
                                                    <CustomButtonSmall value='Confirm' style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} onClick={() => deleteDomain(item.ID)} />
                                                    <CustomButtonSmall value='Cancel' onClick={() => c()} />
                                                </div>
                                            </div>
                                        }
                                    }
                                ]}
                            ];
                        });
                        if (userData.data.length === 0) {
                            tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])
    
                        }
                        return tmp
    
                    })()} />
                    
                };
            })()}
        </div>

    </div> : <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
        <p>Your account has not been verified</p>

    </div></div>}</>
};

export default Domains;