export const getParamsFromURL = (url) => {
    let firstPart = url.split("?");
    if (firstPart.length !== 2) return [];
    let secondPart = firstPart[1].split("&");
    let final = [];
    for (let item of secondPart) {
        let tmp = item.split("=").filter(tmp2 => tmp2);
        if (tmp.length === 2) {
            final.push({name: decodeURI(tmp[0]), value: decodeURI(tmp[1])});
        };
    };
    return final;
};