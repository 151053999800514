import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { updateTimestamp } from "../../actions/timestampActions";
import { useLocation } from "react-router-dom";

import ButtonSmall from "../customComponents/ButtonSmall"
import Hamburger from "../Hamburger";


const Header = () => {
    const internalRef = React.useRef();
    const headerMobileRef = React.useRef();
    const [activePage, setActivePage] = React.useState();
    const [assignedManagerVisible, setAssignedManagerVisible] = React.useState(false);

    const navigate = useNavigate();
    const curLocation = useLocation();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    let userData = useSelector((state) => state.userData);

    React.useEffect(() => {
        let temp = curLocation.pathname

        if (temp.includes('offer') || temp.includes('article')) {
            setActivePage('Offers')
        } else if (temp.includes('domains')) {
            setActivePage('Domains')
        } else if (temp.includes('admin-blockedLeads')) {
            setActivePage('Blocked Leads')
        } else if (temp.includes('leads')) {
            setActivePage('Leads')
        } else if (temp.includes('payments')) {
            setActivePage('Payments')
        } else if (temp.includes('crm')) {
            setActivePage('CRM')
        } else if (temp.includes('admin-users')) {
            setActivePage('Users')
        } else if (temp.includes('admin-advertisers')) {
            setActivePage('Advertisers')
        } else if (temp.includes('websites')) {
            setActivePage('Websites')
        } else if (temp.includes('profile')) {
            setActivePage('Settings')
        } else if (temp.endsWith("/postback")) {
            setActivePage("Postback")
        } else if (temp.endsWith("/referral-overview")) {
            setActivePage("Overview");
        } else if (temp.endsWith("/referral-publisher")) {
            setActivePage("Publisher referrals");
        } else if (temp.endsWith("/referral-advertiser")) {
            setActivePage("Advertiser referrals");
        } else if (temp.endsWith("/admin-invoices")) {
            setActivePage("Invoices");
        } else if (temp.endsWith("/admin-apitokens")) {
            setActivePage("API Tokens");
        } else if (temp.endsWith("/admin-customPayouts")) {
            setActivePage("Custom payouts");
        } else if (temp.endsWith("/admin-publisherStats")) {
            setActivePage("Publisher Statistics");
        } else {
            setActivePage('Dashboard')
        }
    })

    React.useEffect(() => {
        if (!internalRef?.current) return;

        let animWrap = internalRef.current.querySelector(".component__headerLoged__user__modal__animWrapInfo");
        let managerWrap = internalRef.current.querySelector(".component__headerLoged__user__modal__animWrapManager");
        if (!animWrap || !managerWrap) return;

        animWrap.animate(assignedManagerVisible ? [
            { maxHeight: getComputedStyle(animWrap).height },
            { maxHeight: 0 }
        ] : [
            { maxHeight: getComputedStyle(animWrap).height },
            { maxHeight: "250px" }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        });
        managerWrap.animate(!assignedManagerVisible ? [
            { maxHeight: getComputedStyle(managerWrap).height, marginTop: getComputedStyle(managerWrap).marginTop },
            { maxHeight: 0, marginTop: 0 }
        ] : [
            { maxHeight: getComputedStyle(managerWrap).height, marginTop: getComputedStyle(managerWrap).marginTop },
            { maxHeight: "250px", marginTop: "20px" }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        });
    }, [assignedManagerVisible]);

    React.useEffect(() => {
        if (assignedManagerVisible) setAssignedManagerVisible(false);
    }, [userData.userData]);

    React.useEffect(() => {
        if (!headerMobileRef.current) return;
        headerMobileRef.current.style.backgroundImage = "linear-gradient(90.04deg, #4462E7 0.04%, #5173FF 44.83%, #C06AF2 99.98%)";
        headerMobileRef.current.style.backgroundRepeat = "no-repeat";
        headerMobileRef.current.style.backgroundSize = "100% 0%";
        headerMobileRef.current.style.boxShadow = "none";
        headerMobileRef.current.style.transition = "background-size 0.3s ease, box-shadow 0.3s ease";
        
        const handler = e => {
            if (!headerMobileRef.current) return;
            if (window.scrollY > 20) {
                headerMobileRef.current.style.transition = "background-size 0.3s ease, box-shadow 0.3s 0.2s ease";
                headerMobileRef.current.style.boxShadow = "1px 2px 5px 0px rgba(0,0,0,0.75)";
                headerMobileRef.current.style.backgroundSize = "100% 100%";
            } else {
                headerMobileRef.current.style.transition = "background-size 0.3s 0.2s ease, box-shadow 0.3s ease";
                headerMobileRef.current.style.boxShadow = "none";
                headerMobileRef.current.style.backgroundSize = "100% 0%";
            };
        };

        window.addEventListener("scroll", handler);

        return () => window.removeEventListener("scroll", handler);
    }, [headerMobileRef]);

    React.useEffect(() => {
        try {
            let clickListen = () => {
                if (!internalRef.current) return;
                let modalDialog = internalRef.current.querySelector(".component__headerLoged__user--active");
                if (modalDialog) modalDialog.click();
            };
            document.body.addEventListener("click", clickListen);
            return () => document.body.removeEventListener("click", clickListen);
        } catch { };
    }, []);

    let hamburger = false
    const openHamburger = () => {
        let hamburgerMenu = document.querySelector('.component__headerMobile__menu')
        if (hamburger === false) {
            hamburgerMenu.style.height = '500px'
            hamburgerMenu.style.padding = '20px'
            hamburger = true
        } else {
            hamburgerMenu.style.height = '0px'
            hamburgerMenu.style.padding = '0px'
            hamburger = false
        }
    }

    return <>{(window.location.href.includes('dashboard') && userData.userData !== null) ? <div ref={internalRef} className="component__headerLoged">
        <Hamburger />
        <div className="component__headerLoged__manager"><p style={{ textTransform: 'capitalize', marginRight: '30px' }} >{activePage}</p></div>
        <div className="component__headerLoged__user" onClick={e => {
            e.stopPropagation();
            if (e.currentTarget.classList.contains("component__headerLoged__user--active")) {
                if (assignedManagerVisible) setAssignedManagerVisible(!assignedManagerVisible);
            };
            e.currentTarget.classList.toggle("component__headerLoged__user--active")
        }}>
            <div className="component__headerLoged__user__circle">
                {userData?.userData?.UserInfo?.FirstName?.charAt(0) ?? "N"}
                {userData?.userData?.UserInfo?.LastName?.charAt(0) ?? "A"}
            </div>
            <p className="component__headerLoged__user__name">{userData?.userData?.UserInfo?.FirstName ?? ""} {userData?.userData?.UserInfo?.LastName ?? ""}</p>
            <img src="/images/settingsArrowWhite.png" className="component__headerLoged__user__dropIcon" />
            <div className="component__headerLoged__user__modal" onClick={e => e.stopPropagation()}>
                {userData?.userData?.AssignedManager ? <div className={`component__headerLoged__user__modal__assignedManager ${assignedManagerVisible ? "component__headerLoged__user__modal__assignedManager--active" : ""}`} onClick={e => {
                    setAssignedManagerVisible(!assignedManagerVisible);
                }}>
                    <div className="component__headerLoged__user__modal__assignedManager__circle">
                        {userData?.userData?.AssignedManager?.FirstName?.charAt(0) ?? "N"}
                        {userData?.userData?.AssignedManager?.LastName?.charAt(0) ?? "A"}
                    </div>
                    <div className="component__headerLoged__user__modal__assignedManager__name">
                        <span>{userData?.userData?.AssignedManager.FirstName ?? "NA"} </span>
                        <span>{userData?.userData?.AssignedManager.LastName ?? "NA"}</span>
                        <p>Your manager</p>
                    </div>
                    <img src="/images/settingsArrowWhite.png" className="component__headerLoged__user__modal__assignedManager__dropIcon" />
                </div> : null}

                <div className="component__headerLoged__user__modal__animWrapManager">

                    {userData?.userData?.AssignedManager?.ContactSkype ? <p className="component__headerLoged__user__modal__animWrapManager__icon" onClick={() => {
                        window.location = `skype:${userData?.userData?.AssignedManager?.ContactSkype}?chat`;
                    }}>
                        <img src="/images/headerManager_skype.png" />
                        <span>{userData?.userData?.AssignedManager?.ContactSkype}</span>
                    </p> : null}

                    {userData?.userData?.AssignedManager?.ContactTelegram ? <p className="component__headerLoged__user__modal__animWrapManager__icon" onClick={() => {
                        window.open(`https://telegram.me/${userData?.userData?.AssignedManager?.ContactTelegram}`);
                    }}>
                        <img src="/images/headerManager_telegram.png" />
                        <span>{userData?.userData?.AssignedManager?.ContactTelegram}</span>
                    </p> : null}

                    {userData?.userData?.AssignedManager?.ContactWhatsapp ? <p className="component__headerLoged__user__modal__animWrapManager__icon" onClick={() => {
                        window.open(`https://wa.me/${userData?.userData?.AssignedManager?.ContactWhatsapp}`);
                    }}>
                        <img src="/images/headerManager_whatsapp.png" />
                        <span>{userData?.userData?.AssignedManager?.ContactWhatsapp}</span>
                    </p> : null}

                    {userData?.userData?.AssignedManager?.Email ? <p className="component__headerLoged__user__modal__animWrapManager__icon" onClick={() => {
                        window.location = `mailto:${userData?.userData?.AssignedManager?.Email}?chat`;
                    }}>
                        <img src="/images/headerManager_mail.png" />
                        <span>{userData?.userData?.AssignedManager?.Email}</span>
                    </p> : null}

                </div>

                <div className="component__headerLoged__user__modal__animWrapInfo">
                    {userData?.userData?.UserInfo?.EstimatedEarnings ? <p className="component__headerLoged__user__modal__animWrapInfo__earnings">
                        <span>Earnings:</span>
                        <span className="component__headerLoged__user__modal__animWrapInfo__earnings__bold">{Number(userData?.userData?.UserInfo?.EstimatedEarnings).toFixed(2)} $</span>
                    </p> : null}
                    {userData?.userData?.RefferalInfo ? <p className="component__headerLoged__user__modal__animWrapInfo__earnings">
                        <span>Referral earnings:</span>
                        <span className="component__headerLoged__user__modal__animWrapInfo__earnings__bold">{Number(userData?.userData?.RefferalInfo?.RefferalEarnings).toFixed(2)} $</span>
                    </p> : null}
                    {userData?.userData?.AdvertiserInfo?.Credits ? <p className="component__headerLoged__user__modal__animWrapInfo__earnings">
                        <span>Credits:</span>
                        <span className="component__headerLoged__user__modal__animWrapInfo__earnings__bold">{Number(userData?.userData?.AdvertiserInfo?.Credits).toFixed(2)} $</span>
                    </p> : null}
                    <p className="component__headerLoged__user__modal__animWrapInfo__buttons component__headerLoged__user__modal__animWrapInfo__buttons--first" onClick={() => {
                        try {
                            document.querySelector(".component__sidebar__logout--settings").click();
                            document.querySelector(".component__headerLoged__user").classList.remove("component__headerLoged__user--active");
                        } catch { };
                    }}>
                        <img src="/images/settingsWhite.png" />
                        <span>Settings</span>
                    </p>
                    <p className="component__headerLoged__user__modal__animWrapInfo__buttons" onClick={() => {
                        try {
                            document.querySelector(".component__sidebar__logout--logout").click();
                            document.querySelector(".component__headerLoged__user").classList.remove("component__headerLoged__user--active");
                        } catch { };
                    }}>
                        <img src="/images/logoutWhite.png" />
                        <span>Log out</span>
                    </p>
                </div>
            </div>
        </div>
    </div> : <>
        <div className="component__headerMobile" ref={headerMobileRef} >
            <div className="componentHeader__logo" >
                <img src="/images/logoNew.svg" onClick={() => { animateNavigate('/') }} style={{ 'height': '50px' }} className="logoTablet" />
                <img src="/images/logoNewWhite.svg" onClick={() => { animateNavigate('/') }} className="logoMobile" ></img>
            </div>
            <div className="component__headerMobile__hamburger" >
                <ButtonSmall value='Log in' onClick={() => { animateNavigate("/login"); }} />
                <img src="/images/landing/hamb.svg" className="component__headerMobile__hamburger__hmeni" onClick={() => { openHamburger() }} />
            </div>
            <div className="component__headerMobile__menu" >
                <div className="component__headerMobile__menu__logo" >
                    <img src="/images/logoNewWhite.svg" onClick={() => { animateNavigate('/') }} style={{ 'height': '50px' }}></img>

                    <p onClick={() => { openHamburger() }} >&#10005;</p>
                </div>
                <div className="component__headerMobile__menu__items" >
                    <h2 onClick={() => { openHamburger(); animateNavigate("/"); }} >Home</h2>
                    <h2 onClick={() => { openHamburger(); animateNavigate("/publishers"); }}>Publishers</h2>
                    <h2 onClick={() => { openHamburger(); animateNavigate("/advertisers"); }}>Advertisers</h2>
                    <h2 onClick={() => { openHamburger(); animateNavigate("/aboutus"); }}>Our culture</h2>
                    {userData.userData !== null ?
                        <h2 onClick={() => { openHamburger(); animateNavigate("/dashboard"); }} >Dashboard</h2> :
                        <h2 onClick={() => { openHamburger(); animateNavigate("/login"); }} >Log in</h2>
                    }

                </div>
                <div className="component__headerMobile__menu__social" >
                    <div className="component__footer__logos__social" >
                        <a href="https://www.facebook.com/scalelead" target='_blank' ><img src="/images/landing/fb.png" /></a>
                        <a href="https://www.linkedin.com/company/scalelead" target='_blank'><img src="/images/landing/ln.png" /></a>
                        <a href="https://www.instagram.com/scalelead/" target='_blank'><img src="/images/landing/insta.png" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="component__header">


            <div className="componentHeader__logo" >
                <img src="/images/logoNew.svg" onClick={() => { animateNavigate('/') }} style={{ 'height': '50px' }} />
            </div>
            <div className="component__header__text">
                <h2 onClick={() => { animateNavigate("/"); }} >Home</h2>
                <h2 onClick={() => { animateNavigate("/publishers"); }}>Publishers</h2>
                <h2 onClick={() => { animateNavigate("/advertisers"); }}>Advertisers</h2>
                <h2 onClick={() => { animateNavigate("/aboutus"); }}>Our culture</h2>
                {userData.userData !== null ?
                    <ButtonSmall value='Dashboard' onClick={() => { animateNavigate("/dashboard"); }} /> :
                    <>
                        <ButtonSmall value='Log in' onClick={() => { animateNavigate("/login"); }} />
                        <ButtonSmall value='Sign up' onClick={() => { animateNavigate("/register"); }} />
                    </>

                }

                {/* <ButtonSmall value='Register' onClick={() => { animateNavigate("/register"); }} /> */}
            </div>

        </div></>}</>
};

export default Header;