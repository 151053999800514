import React, { useState } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";
import { getParamsFromURL } from "../../../../modules/urlModule";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateModule from "../../../../modules/animateModule";
import Radio from "../../../../components/customComponents/Radio";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import ButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";


const DashboardAdvertiserManager = () => {
    let [userData, setUserData] = React.useState(null);
    let [loadingSpinner, setLoadingSpinner] = React.useState(false);
    let [filters, updateFilters] = React.useState([]);
    let [firstTime, setFirstTime] = React.useState(true);
    let [canPaginate, setCanPaginate] = React.useState(false);
    let [addFilterItem, setAddFilterItem] = React.useState(null);
    let location = useLocation();
    let navigate = useNavigate();

    let curPagination = React.useRef();

    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };

    const continueAdvertisers = () => {
        if (curPagination.current === -1) return;
        setCanPaginate(false);
        setLoadingSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/getAllAdvertisers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {

                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                };
                setUserData({ status: "ok", data: [...userData.data, ...res.data.data.DBData] });
            } else {
                curPagination.current = -1;
            };
        }).catch(() => {
            curPagination.current = -1;
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };

    const getAdvertisers = () => {
        curPagination.current = 0;
        setCanPaginate(false);
        setLoadingSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/getAllAdvertisers`,
            data: {
                paginationOffset: curPagination.current,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.DBData.length === 0) {
                    curPagination.current = -1;
                } else {
                    curPagination.current += res.data.data.DBData.length;
                    if (curPagination.current < 20) curPagination.current = -1;
                };
                setUserData({ status: "ok", data: res.data.data.DBData });

            } else {
                setUserData({ status: "error", data: "There was an error while getting advertisers" });
            };
        }).catch(() => {
            setUserData({ status: "error", data: "Server timed out!" });
        }).finally(() => {
            setLoadingSpinner(false);
            setCanPaginate(true);
        });
    };
    const DisplayAdvertiserInfo = (props) => {
        const [moreInfo, setMoreInfo]=React.useState()
        const getMoreInfo=()=>{
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/leads/getLeadsForAdvertiserDashboard`,
                data: {
                    BoundAdvertiser: props.user.ID
                },

                ...backendModule.axiosConfig
            }).then(res => {
                if(res.data.status==='ok'){
                   setMoreInfo(res.data.data)
                }
            })
        }
        React.useEffect(()=>{
            getMoreInfo()
        }, [])
        return <div className="route__adminAdvertisers__userInfo">
            <p>AdvertiserID: {props.user.ID}</p>
            <p>Company name: {props.user.CompanyName}</p>
            <p>Credit balance: {props.user.isInfiniteCredits ? <spam style={{ color: 'yellow' }} >Infinite</spam> : `${props.user.creditBalance} $`}</p>
            <p>Minimum allowed balance: {props.user.minBalance} $</p>
            <p>Company address: {props.user.CompanyAddress}</p>
            <p>Company City: {props.user.CompanyCity}</p>
            <p>Company ZIP: {props.user.CompanyZipCode}</p>
            <p>Tax / VAT number: {props.user.TaxVatNumber}</p>
            <p>Has infinite credits: {props.user.isInfiniteCredits ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>}</p>
            <p>Total number of leads: {moreInfo?.totalLeadCount}</p>
            <p>Total number of converted Leads: {moreInfo?.totalConvertedLeadsCount}</p>
            <p>Total spent: {moreInfo?.totalEarned}</p>
                
            <br />
           
            <p>Flags:<hr /> {(() => {
                return Object.keys(props.user.Flags).map(elem => {
                    return <><span>
                        {elem}: <span style={{
                            color: (props.user.Flags[elem] === true || props.user.Flags[elem] === false) ? (props.user.Flags[elem] ? "green" : "red") : "white"
                        }}>{(() => {
                            if (props.user.Flags[elem] === true || props.user.Flags[elem] === false) {
                                return props.user.Flags[elem] ? "Yes" : "No"
                            } else return props.user.Flags[elem];
                        })()}</span>
                    </span><br /></>
                });
            })()}</p>
        </div>
    };

    const PaginationAdvertiser = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        canPaginate && setTimeout(continueAdvertisers, 200);
                    };
                });
            });
            observer.observe(tmpRef.current);
            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, tmpRef);

        return <div ref={tmpRef}>

        </div>
    };

    const VerifyAdvertiser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/verifyAdvertiser`,
                data: {
                    ID: props.ID,
                    verifyStatus: true
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to verify advertiser!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminAdvertiser__verifyAdvertiser">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminUsers__verifyPublisher">
            <p>This will verify the advertiser</p>
            <p>Are you sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const DeleteAdvertiser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        const performVerify = () => {
            if (!props.ID) return;
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/deleteAdvertiser`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to delete advertiser!");
                    setSpinner(false);
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminAdvertisers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                }} />
            </div>
        }
        return <div className="route__adminAdvertisers__verifyPublisher">
            <p>This will delete the advertiser and ALL OF HIS DATA</p>
            <p>(Advertiser offers, taken offers from other users, CRMs...etc)</p>
            <p>Are you REALLY sure?</p>
            <ButtonSmall value="Yes" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white' }} />
            <ButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const SetCreditBalance = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);
        let [countAsRef, setCountAsRef] = React.useState(true);
        let [balanceType, setBalanceType] = React.useState(props.user.isInfiniteCredits);
        let newBalanceRef = React.useRef();

        const performVerify = () => {
            let calculatedBalance = 0;
            if (!props.user.ID) return;
            if (!balanceType) {
                if (isNaN(Number(newBalanceRef.current?.value))) {
                    setFinalData("New credit balance must be a number");
                    setSpinner(false);
                    return;
                } else {
                    calculatedBalance = Number(newBalanceRef.current.value);
                };
            };
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/getAdvertiserByID`,
                data: {
                    ID: props.user.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/partners/${(res.data.data.isInfiniteCredits || balanceType) ? "setCreditBalance" : "setCreditBalanceDynamic"}`,
                        data: {
                            advertiserID: res.data.data.ID,
                            balance: balanceType ? "infinite" : calculatedBalance,
                            countAsReferral: countAsRef
                        },
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.data.status === "ok") {
                            props.c().then(() => {
                                let finalUser = props.user;
                                if (balanceType) {
                                    finalUser.creditBalance = 0;
                                    finalUser.isInfiniteCredits = true;
                                } else {
                                    finalUser.creditBalance = Number(res.data.data.creditBalance) + calculatedBalance;
                                    finalUser.isInfiniteCredits = false;
                                };
                                setSpinner(false);
                                if (props.onUpdate) props.onUpdate(finalUser ? finalUser : null);
                            })
                        } else {
                            setFinalData("Failed to set new balance!");
                        };
                    }).catch(() => {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to find advertiser!");
                    setSpinner(false);
                }
            }).catch((e) => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminAdvertisers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(props.user ? props.user : null);
                    });;
                }} />
            </div>
        }
        return <div className="route__adminAdvertisers__setBalance">
            <p>Enter the amount of credits to add or remove</p>
            <p>for instance -200 to remove 200 credits from advertiser...etc</p>
            <p>Or set the balance as infinite</p>
            <p>NOTE: Setting the balance as infinite will internally reset the balance to 0 !!!!!</p>
            <div className="route__adminAdvertisers__setBalance__data">
                <Radio selected={balanceType} className="route__adminAdvertisers__setBalance__data__radio" valueyes={<p>Infinite</p>} valueno={<p>Not infinite</p>} functionYes={() => setBalanceType(true)} functionNo={() => setBalanceType(false)} />
                <Radio selected={countAsRef} className="route__adminAdvertisers__setBalance__data__radio" style={{ display: "inline-block", width: "300px", margin: "20px 0px" }} valueyes={<p>Count as referral</p>} valueno={<p>DO NOT count as referral</p>} functionYes={() => setCountAsRef(true)} functionNo={() => setCountAsRef(false)} />
                {!balanceType && <>
                    <CustomInput ref={newBalanceRef} placeholder1="Credits to add / remove" />
                </>}
            </div>
            <ButtonSmall value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: "white", marginRight: "10px" }} />
            <ButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };

    const EditAdvertiser = (props) => {
        let [spinner, setSpinner] = React.useState(false);
        let [finalData, setFinalData] = React.useState(null);

        let companyNameRef = React.useRef();
        let companyAddressRef = React.useRef();
        let companyCityRef = React.useRef();
        let companyZipCodeRef = React.useRef();
        let TaxVatNumberRef = React.useRef();
        let minBalanceRef = React.useRef();
        let creditBalanceRef = React.useRef();

        const getData = () => {
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/getAdvertiserByID`,
                data: {
                    ID: props.ID
                },
                ...backendModule.axiosConfig
            }).then(async res => {
                if (res.data.status === "ok") {
                    setSpinner(false);
                    while (!companyNameRef.current) {
                        await new Promise(r2 => setTimeout(() => r2(), 50));
                    };
                    companyNameRef.current.value = res.data.data.CompanyName;
                    companyAddressRef.current.value = res.data.data.CompanyAddress;
                    companyCityRef.current.value = res.data.data.CompanyCity;
                    companyZipCodeRef.current.value = res.data.data.CompanyZipCode;
                    TaxVatNumberRef.current.value = res.data.data.TaxVatNumber;
                    minBalanceRef.current.value = res.data.data.minBalance;
                    creditBalanceRef.current.value = res.data.data.creditBalance;
                } else {
                    setFinalData("Failed to get user data!");
                };
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
            }).finally(() => {
                setSpinner(false);
            });
        };


        const performVerify = () => {
            let CompanyName = companyNameRef.current.value;
            let CompanyAddress = companyAddressRef.current.value;
            let CompanyCity = companyCityRef.current.value;
            let CompanyZipCode = companyZipCodeRef.current.value;
            let TaxVatNumber = TaxVatNumberRef.current.value;
            let minBalance = minBalanceRef.current.value;
            let creditBalance = creditBalanceRef.current.value;

            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/editAdvertiser`,
                data: {
                    ID: props.ID,
                    CompanyName: CompanyName ? CompanyName : null,
                    CompanyAddress: CompanyAddress ? CompanyAddress : null,
                    CompanyCity: CompanyCity ? CompanyCity : null,
                    CompanyZipCode: CompanyZipCode ? Number(CompanyZipCode) : null,
                    TaxVatNumber: TaxVatNumber ? TaxVatNumber : null,
                    minBalance: minBalance ? Number(minBalance) : null,
                    creditBalance: creditBalance ? Number(creditBalance) : null
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/partners/getAdvertiserByID`,
                        data: {
                            ID: props.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.status === "error") {
                            setFinalData("There was an error while getting the new advertiser!");
                            setSpinner(false);
                        } else {
                            props.c().then(() => {
                                if (props.onUpdate) props.onUpdate(final.data.data);
                                setSpinner(false);
                            });
                        }
                    }).catch(() => {
                        setFinalData("There was an error while connecting to the server!");
                        setSpinner(false);
                    });
                } else {
                    setFinalData("Failed to edit advertiser!");
                    setSpinner(false);
                }
            }).catch(() => {
                setFinalData("There was an error while connecting to the server!");
                setSpinner(false);
            });
        };

        React.useEffect(() => {
            setImmediate(getData);
        }, [companyNameRef]);

        if (spinner) return <Spinner />
        if (finalData) {
            return <div className="route__adminAdvertisers__verifyPublisher">
                <p>{finalData}</p>
                <ButtonSmall value="Close" onClick={e => {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate(props.user);
                    });
                }} />
            </div>
        }
        return <div className="route__adminAdvertisers__addUser">
            <div className="route__adminAdvertisers__addUser__userInfo">
                <CustomInput placeholder1="Company name" ref={companyNameRef} />
                <CustomInput placeholder1="Company address" ref={companyAddressRef} />
                <CustomInput placeholder1="Company city" ref={companyCityRef} />
                <CustomInput placeholder1="Company ZIP code" ref={companyZipCodeRef} />
                <CustomInput placeholder1="TAX / VAT number" ref={TaxVatNumberRef} />
                <CustomInput placeholder1="Credit balance" ref={creditBalanceRef} />
                <CustomInput placeholder1="Minimum balance" ref={minBalanceRef} />
            </div>
            <ButtonSmall value="Save" onClick={performVerify} style={{ backgroundColor: "#00A3FF", color: 'white', marginRight: "10px" }} />
            <ButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };



    React.useEffect(() => {
        getAdvertisers();
    }, [filters]);

    React.useEffect(() => {
        if (!addFilterItem) return;
        if (!firstTime) return;
        if (!userData) return;
        if (userData.status === "error") return;
        if (location.search) {
            let parsedSearch = getParamsFromURL(location.search);
            parsedSearch.forEach(elem => {
                if (elem.name === "ID") {
                    addFilterItem({
                        name: "ID",
                        filterType: "string",
                        varType: "string",
                        friendlyName: "User ID",
                        value: elem.value,
                        type: "eq"
                    });
                };
            });
            setFirstTime(false);
        };
    }, [userData, addFilterItem]);

    return <div className="route__adminAdvertisers">
        {(() => {
            if (!userData) return <Spinner />
            if (userData.status === "error") {
                return <p>{userData.data}</p>
            } else {
                return <FilteredCustomTable addFilter={fn => setAddFilterItem(fn)} filterCB={(data) => updateFilters(data)} headers={["Company name", "Username", "Verified"]} data={(() => {
                    let tmp = userData.data.map((item, index) => {
                        return [
                            { keyID: item.ID, type: "text", text: item.CompanyName },
                            { keyID: item.ID, type: "text", text: item.Username },
                            {
                                keyID: item.ID, type: "group", group: [
                                    {
                                        keyID: item.ID, type: "text", text: (() => {
                                            let final = [];
                                            final.push(<p><span style={{ color: item.Flags.isVerified ? "green" : "red" }}>{item.Flags.isVerified ? "Yes" : "No"}</span></p>);
                                            return final;
                                        })()
                                    },
                                ]
                            },
                            {
                                keyID: item.ID, type: "groupNewline", group: [
                                    {
                                        keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => {
                                            return <EditAdvertiser ID={item.ID} user={item} c={c} onUpdate={(usr) => {
                                                if (usr) {
                                                    usr.Flags = JSON.parse(usr.Flags);
                                                    setUserData({
                                                        status: "ok", data: [
                                                            ...userData.data.filter((_, index2) => index2 < index),
                                                            usr,
                                                            ...userData.data.filter((_, index2) => index2 > index)
                                                        ]
                                                    });
                                                };
                                            }} />
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: "button", text: "Add balance", triggerDropdown: true, triggerData: c => {
                                            return <SetCreditBalance user={item} c={c} onUpdate={(usr => {
                                                if (!usr) return;
                                                setUserData({
                                                    status: "ok", data: [
                                                        ...userData.data.filter((_, index2) => index2 < index),
                                                        usr,
                                                        ...userData.data.filter((_, index2) => index2 > index)
                                                    ]
                                                });
                                            })} />
                                        }
                                    },
                                    {
                                        keyID: item.ID, type: "button", text: "More info", triggerDropdown: true, triggerData: () => {
                                            return <DisplayAdvertiserInfo user={item} />
                                        }
                                    },
                                    { keyID: item.ID, type: "button", text: "Go to user account", onClick: () => animateNavigate(`/dashboard/admin-users?Username=${encodeURI(item.Username)}`) },
                                    (item.Flags.isVerified) ? null : {
                                        keyID: item.ID, type: "button", text: "Verify advertiser", triggerDropdown: true, triggerData: c => {
                                            return <VerifyAdvertiser ID={item.ID} c={c} onUpdate={() => {
                                                item.Flags.isVerified = true;
                                                setUserData({
                                                    status: "ok", data: [
                                                        ...userData.data.filter((_, index2) => index2 < index),
                                                        item,
                                                        ...userData.data.filter((_, index2) => index2 > index)
                                                    ]
                                                });
                                            }} />
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                    if (userData.data.length === 0) {
                        tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])

                    }
                    if (loadingSpinner) tmp.push([{ keyID: "customSpinner", type: "custom", data: <Spinner /> }]);
                    if (curPagination.current !== -1) tmp.push([{ keyID: "customPagination", type: "custom", data: <PaginationAdvertiser /> }])

                    return tmp;
                })()} filters={[
                    { name: "CompanyName", friendlyName: "Company name", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "CompanyAddress", friendlyName: "Company address", type: "string" },
                    { name: "CompanyCity", friendlyName: "Company city", type: "string" },
                    { name: "CompanyZipCode", friendlyName: "Company ZIP code", type: "number" },
                    { name: "TaxVatNumber", friendlyName: "TAX / VAT number", type: "string" },
                    { name: "isInfiniteCredits", friendlyName: "Infinite credits", type: "boolean" },
                    { name: "creditBalance", friendlyName: "Credit Balance", type: "number" },
                    { name: "minBalance", friendlyName: "Minimum balance", type: "number" },
                    { name: "isInfiniteCredits", friendlyName: "Has infinite balance", type: "boolean" },
                    { name: "Flags:isVerified", friendlyName: "Is verified", type: "boolean" }
                ]} />
            };
        })()}
    </div>
};


export default DashboardAdvertiserManager;