
import "./index.scss";
import React from "react";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as backendModule from '../../modules/backendModule'

const Previous = () => {

    const search = useLocation().search
    const name = new URLSearchParams(search).get('city');
    const [data, setData] = React.useState()
    const previous = React.useRef();
    const prepareData = () => {
        if (name === 'dubai') {
            setData({
                background: '/images/dubai.jpg',
                date: '30 Nov - 1 Dec 2022',
                head1: 'Affiliate world GLOBAL',
                head2: ' DUBAI',
                text1: 'For the first time ever, two Affiliate World events will collide for the largest gathering of the world’s top affiliate marketers and ecommerce entrepreneurs.',
                text2: 'Absorb mastermind level content on stage where experts present new insights and data-driven strategies while being exposed to an exhibition floor overflowing with opportunities to push your business forward. End each day with happy hours, rooftop sunsets and irresistible industry nightlife.            ',
                text3: 'Affiliate World Global is a two-day conference taking place in Dubai on 28 February - 1 March 2022.',
                image: '/images/dubaikonf.jpg',
                order: ''
            })
        } else if (name === 'moscow') {
            setData({
                background: '/images/moscow.jpg',
                date: '5-6 October 2021',
                head1: 'MOSCOW AFFILIATE CONFERENCE',
                head2: ' MOSCOW',
                text1: 'Moscow Affiliate Conference or MAC is the biggest marketing event in CIS, with more than 50 countries participating this year. It is a grand opportunity to network and learn new ways to monetize content with 3000 participants and 30 reports to boost yields.',
                text2: 'Absorb mastermind level content on stage where experts present new insights and data-driven strategies while being exposed to an exhibition floor overflowing with opportunities to push your business forward. End each day with happy hours, rooftop sunsets and irresistible industry nightlife.            ',
                text3: '',
                image: '/images/moscowkonf.jpg',
                order: ''
            })
        } else if (name === 'barcelona') {
            setData({
                background: '/images/barcelona.jpg',
                date: '6-7 July 2022',
                head1: 'AFFILIATE WORLD EUROPE',
                head2: ' BARCELONA',
                headsuper1: 'EUROPE',
                headsuper2: ' BARCELONA, SPAIN',
                text1: 'You will network with the industry’s brightest minds, absorb mastermind-level content on stage, and be exposed to an exhibition floor overflowing with opportunities to push your career forward. Experts will take the stage and present new insights, data-driven strategies, and innovative ideas on affiliate and ecommerce marketing.',
                text2: 'After soaking up the knowledge, look out for some extra perks at the end of each day: patio happy hours, rooftop sunsets, and an irresistible industry nightlife.',
                text3: 'Affiliate World Europe is a two-day conference event taking place in Barcelona on 6-7 July 2022.',
                order: {
                    image: '/images/spainBooth.png',
                    head: 'Schedule a meeting with us',
                    text: 'You are always welcome to discuss your ideas and future cooperation with us while attending the conference. Please fill in the form and we will arrange the best time for you to meet with us.',
                    date: ['6 JULY 2022', '7 JULY 2022'],

                }
            })
        } else if (name === 'bangkok') {
            setData({
                background: '/images/bangkok.jpg',
                date: '30 Nov - 1 Dec 2022',
                head1: 'AFFILIATE WORLD ASIA',
                head2: ' BANGKOK',
                headsuper1: 'ASIA',
                headsuper2: ' BANGKOK, THAILAND',
                text1: "Affiliate World is the world’s premier gathering of influential performance and ecommerce marketers. You will network with the industry's brightest minds, absorb mastermind-level content on stage, and be exposed to an exhibition floor overflowing with opportunities to push your career forward.",
                text2: 'Our upcoming event in Bangkok is Affiliate World Asia, a two-day conference on 4-5 December.',
                text3: '',
                order: {
                    image: '/images/booth.png',
                    head: 'Schedule a meeting with us',
                    text: 'You are always welcome to discuss your ideas and future cooperation with us while attending the conference. Please fill in the form and we will arrange the best time for you to meet with us.',
                    date: ['30 NOVEMBER 2022', '1 DECEMBER 2022'],

                }
            })
        }
    }
    React.useEffect(() => {
        prepareData()
       
    }, [])
    React.useEffect(() => {
        if (previous) {
            document.querySelector('.component__header').scrollIntoView()
        }
    }, [previous])
    


    return <div className="previous" ref={previous}>
        <img className="login__image" src='/images/landing/logibimage.png' />
        <img className="login__maskMobile" src="/images/landing/mobileimageup.png" />
        <div className="previous__container">
            <div className="previous__container__top" style={{ 'backgroundImage': `url(${data?.background})` }}>
                <div className="previous__container__top__content" >
                    <h2>{data?.headsuper1 && data?.headsuper1}</h2>
                    <p>{data?.headsuper1 && data?.date}</p>
                    <p>{data?.headsuper2 && data?.headsuper2}</p>
                    <img src="/images/logoNewWhite.svg" />
                </div>
            </div>
            <div className="aboutus__affiliate__upcoming__single__line">

            </div>
            <div className="previous__container__bottom">
                <h3>{data?.head1}</h3>
                <h2>{data?.head2}</h2>
                <p>{data?.text1}</p>
                <p>{data?.text2}</p>
                <p>{data?.text3}</p>
                {data?.image && <img  src={data?.image} alt='' />}
            </div>
        </div>
        {data?.order !== '' && <Meeting data={data} />}
        <Footer />

    </div>
};
const Meeting = (props) => {
    const [date, setDate] = React.useState()
    const dropbox = React.useRef()
    const sendData = (e) => {
        e.preventDefault()

        let inputs = e.target.querySelectorAll('input')
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/email/contact`,
            data: {
                Name: inputs[0].value,
                EMail: inputs[1].value,
                Message: `Meeting schedule: ${date}-- ${inputs[2].value}`,
                date: date
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            document.querySelector('.component__footer__contact__form__msg').style.opacity = '1'
            inputs[0].value = ''
            inputs[1].value = ''
            e.target.querySelector('textarea').value = ''
        }).catch((e) => {
        })
    }
    React.useEffect(() => {
        let footer = document.querySelector('.component__footer')
        let contact = document.querySelector('.component__footer__contact')
        contact.style.display = 'none'
        setDate(props?.data?.order?.date[0])


    }, [props])
    return <div className="component__footer__contactMeeting" >
        <div className="component__footer__contactMeeting__content" >
            <img src={props?.data?.order?.image} />


        </div>
        <form className="component__footer__contactMeeting__form" onSubmit={(e) => { sendData(e) }} >
            <h2>{props?.data?.order?.head}</h2>
            <p>{props?.data?.order?.text}</p>
            <div className="component__footer__contactMeeting__form__inputs">
                <input type='text' required placeholder="Your name" />
                <input type='email' required placeholder="Your email" />
                <div className=" component__footer__contactMeeting__form__inputs__dropDown" >
                    <p style={{ 'width': '100%' }} onClick={() => {
                        dropbox.current.classList.toggle('optionsActive')
                    }}>{date}</p>
                    <div ref={dropbox} className=" component__footer__contactMeeting__form__inputs__dropDown__options">
                        {props?.data?.order?.date.map((item, index) => {
                            return <p key={index}
                                onClick={() => {
                                    dropbox.current.classList.toggle('optionsActive')
                                    setDate(item)
                                }}
                            > {item}</p>
                        })}
                    </div>
                </div>
                <input type='time' />
            </div>
            <button type="submit" >Order meeting</button>
            <p className="component__footer__contact__form__msg"  > Your form has been successfully submitted </p>
        </form>
    </div>
}

export default Previous;