
import "./index.scss";
import React from "react";
import Footer from "../../components/Footer";

const LandingPage = () => {
    React.useEffect(() => {
        document.querySelector('body').classList.remove('dark')
    }, [])
    const findOut = () => {
        document.querySelector('.landingPage__table').scrollIntoView({ behavior: "smooth", block: "start" });
    }
    return <div className="landingPage">
        <div className="landingPage__landing">
            <img className="landingPage__landing__mask" src="/images/landing/maskFirst.png" />
            <img className="landingPage__landing__maskMobile" src="/images/landing/mobileimageup.png" />
            <div className="landingPage__landing__text" >
                <h1>
                    Tailored CPA Network Experience
                </h1>
                <p>
                    The secret of successful affiliate marketers is implementing a plan of action that supports your strengths.</p>
                    <p> Level up your affiliate marketing strategy with guaranteed the best conversion rates and CPA supported by customizable payouts and high-speed lead processing.
                </p>
                <button onClick={() => { findOut() }} >Find out how</button>
            </div>
            <div className="landingPage__landing__image" >
                <img src="/images/landing/vectorLanding.png" />
            </div>
        </div>
        <div className="landingPage__vision" >
            <h1>Scale Lead</h1>
            <h2>Our company has everything you need </h2>
            <div className="landingPage__vision__content" >
                <div className="landingPage__vision__content__single">
                    <img src="/images/landing/vision.png" />
                    <h3>Our vision</h3>
                    <p>
                        Creating triple win-win-win network excellence that connects great advertisers, publishers & target audiences.
                    </p>
                </div>
                <div className="landingPage__vision__content__single">
                    <img src="/images/landing/mision.png" />
                    <h3>Our mission</h3>
                    <p>
                        We provide the best matchmaking service for advertisers and publishers.
                    </p>
                </div>
                <div className="landingPage__vision__content__single">
                    <img src="/images/landing/choice.png" />
                    <h3>Why choose us</h3>
                    <p>
                        We've done it all. We started with e-commerce, became publishers, and ended as software developers and advertisers.
                    </p>
                </div>
            </div>
        </div>
        <div className="landingPage__table" >
            <img className="landingPage__table__vektor" src="/images/landing/tableVektor.png" />
            <div className="landingPage__table__content" >
                <h1>What we bring to your digital table
                </h1>
                <div className="landingPage__table__content__box" >
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>Extra offers</h2>
                            <p>Best CPA offers on the market. As simple as that. If you find a better one, we'll match it.
                            </p>
                        </div>
                    </div>
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>Vetted publishers</h2>
                            <p>Our programming team and anti-fraud AI scanning system make sure fraudulent web pages are off the menu
                            </p>
                        </div>
                    </div>
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>Vetted advertisers</h2>
                            <p>Publishers depend on high-quality advertisers. There is nothing worse than spending your time and money acquiring leads that don't get proper attention. Most affiliate marketers settle up with a conversion rate of 60%. We like to keep that number closer to 80%
                            </p>
                        </div>
                    </div>
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>High-speed lead processing</h2>
                            <p>Most of our advertisers have a 7 day 08 AM - 10PM local time sales support team that quickly processes the leads. That allows you to align your strategy fast according to feedback and make more money.
                            </p>
                        </div>
                    </div>
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>Net 30/ net 15/ payouts</h2>
                            <p>Choose an option that matches your cash flow strategy
                            </p>
                        </div>
                    </div>
                    <div className="landingPage__table__content__box__single" >
                        <img src="/images/landing/tableCheck.png" />
                        <div className="landingPage__table__content__box__single__div">
                            <h2>Dedicated affiliate manager</h2>
                            <p>We prefer a human touch. That's why you get your dedicated affiliate manager equipped with top-notch analytical tools to develop the best strategy for you
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="landingPage__partners" >
            <h1>Our Partners</h1>
            <p>Respected colleagues within the spheres of Perfomance Based Marketing</p>
            <div className="landingPage__partners__images" >
                <img src="/images/landing/partner1.png" />
                <img src="/images/landing/partner2.png" />
                <img src="/images/landing/partner3.png" />
                <img src="/images/landing/partner4.png" />
                <img src="/images/landing/partner5.png" />
                <img src="/images/landing/partner6.png" />
                <img src="/images/landing/partner7.png" />
                <img src="/images/landing/partner8.png" />
            </div>
        </div>
        <Footer />
    </div>
};

export default LandingPage;