
import "./index.scss";
import CustomInput from '../../components/customComponents/CustomInput'
import Spinner from '../../components/customComponents/Spinner'
import Button from '../../components/customComponents/Button'
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import { useDispatch, useSelector } from "react-redux";
import * as timestampActions from "../../actions/timestampActions";
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from "react-router-dom";
import React from "react";


const ResetPassword = () => {
    let userDataDispatch = useDispatch();
    let userData = useSelector((state) => state.userData);
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const [spinner, setSpinner] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const sendData = (e) => {
        setSpinner(true)
        e.preventDefault()
        let inputs = e.target.querySelectorAll('input')
        if (inputs[1].value !== inputs[2].value) {
            document.querySelector('.login__form__errorMsg').style.display = 'block'
            setSpinner(false)
        } else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/auth/resetPassword`,
                data: {
                    Token: inputs[0].value,
                    NewPassword: inputs[1].value,
                    Email: email
                },
                ...backendModule.axiosConfig
            }).then((e) => {
                if (e.data.status === 'ok') {
                    userDataDispatch(timestampActions.updateTimestamp());
                    setSpinner(false)
                    animateNavigate('/login')
                } else {

                }
            }).catch((e) => {
            }).finally(() => {
                setSpinner(false)

            })
        }

    }
    const sendMail = (e) => {
        e.preventDefault()
        setEmail(e.target.querySelector('input').value)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/sendUniqueToken`,
            data: {
                Email: e.target.querySelector('input').value
            },
            ...backendModule.axiosConfig
        }).then((e) => {
        }).catch((e) => {
        }).finally(() => {
            setSpinner(false)

        })

    }
    const resendMail = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/sendUniqueToken`,
            data: {
                Email: email
            },
            ...backendModule.axiosConfig
        }).then((e) => {
        }).catch((e) => {
        }).finally(() => {
            setSpinner(false)

        })
    }
    React.useEffect(()=>{
        document.querySelector('body').classList.remove('dark')
    }, [])
    React.useEffect(() => {
        if (userData.isLoggedIn === true) animateNavigate('/dashboard/publisher');
    }, [userData]);
    return <>
        {email !== '' ? <div className="login">
            {spinner ? <Spinner /> : ''}
            <img className="login__image" src='/images/landing/logibimage.png' />
            <img className="login__imageDown" src='/images/landing/Footer.png' />
            <img className="login__imageDownTablet" src='/images/landing/footerTablety.png' />
            <img className="login__imageDownMobile" src='/images/landing/FooterMobile.png' />
            <div className="login__container loginContainerFirst loginContainerFirstReset">
                <div className="login__container__left" >
                    <img src="/images/landing/containerImage.png" />
                </div>
                <div className="login__container__right" >
                    <h1 className="login__head">
                        Reset password
                    </h1>
                    <p className="login__text">
                        Check your mail for code <br />
                        Your code will be valid for 15 minutes
                    </p>
                    <form className="login__form" onSubmit={(e) => { sendData(e) }} autoComplete="off">
                        <input placeholder='Enter your code' type='text' required />
                        <input placeholder='Enter new password' type='password' required />
                        <input placeholder='Confirm your password' type='password' required />

                        <p className="login__form__errorMsg">Please enter two same passwords</p>
                        <button type='submit' >Change password</button>
                    </form>
                </div>
            </div>

        </div>
            :
            <div className="login">
                {spinner ? <Spinner /> : ''}
                <img className="login__image" src='/images/landing/logibimage.png' />
                <img className="login__maskMobile" src="/images/landing/mobileimageup.png" />
                <img className="login__imageDown" src='/images/landing/Footer.png' />
                <img className="login__imageDownTablet" src='/images/landing/footerTablety.png' />
                <img className="login__imageDownMobile" src='/images/landing/FooterMobile.png' />
                <div className="login__container loginContainerFirst">
                    <div className="login__container__left" >
                        <img src="/images/landing/containerImage.png" />
                    </div>
                    <div className="login__container__right" >
                        <h1 className="login__head">
                            Enter your mail to get code
                        </h1>
                        <form className="login__form" onSubmit={(e) => { sendMail(e) }}>

                            <CustomInput placeholder1='Enter your e-mail' type='text' required />
                            <Button value='Send code' type='submit' />
                        </form>

                    </div>
                </div>

            </div>

        }
    </>


};

export default ResetPassword;