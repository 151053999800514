import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { useSelector } from "react-redux";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";

const ReferralAdvertiser = () => {
    const [advertiserData, setAdvertiserData] = React.useState(null);
    const [filters, setFilters] = React.useState([]);

    const curUserID = useSelector(state => state?.userData?.serData?.UserInfo?.ID);
    const userData = useSelector((state) => state.userData);

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/referral/getAllAdvertiserReferralsForUser`,
            data: {
                BoundUser: curUserID,
                Filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAdvertiserData({status: "ok", data: res.data.data, timestamp: Date.now()});
            } else {
                setAdvertiserData({status: "error", data: null});
            };
        }).catch(() => setAdvertiserData({status: "error", data: null}));;
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    if (userData.userData?.UserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="referral__main">{(() => {
        if (!advertiserData) return <Spinner />
        if (advertiserData?.status === "error") return <div className="referral__main__err">
            <p>There was an error while getting data</p>
            <CustomButtonSmall value="Retry" onClick={() => getData()} />
        </div>
        return <FilteredCustomTable headers={["Date", "Username", "Earned amount"]} data={(()=>{
            if (advertiserData.data.table.length > 0) {
                return advertiserData.data.table.map((item, index) => {
                    return [
                        {keyID: index, type: "text", text: new Date(item.Date).toLocaleDateString()},
                        {keyID: index, type: "text", text: item.Username},
                        {keyID: index, type: "text", text: `${Number(item.EarnedAmount).toFixed(2)} $`},
                    ];
                })
            } else {
                return [[{keyID: "customText", type: "custom", data: <div className="noData">There are no referalls to show </div>}]]
            };
        })()} className="referral__main__spanAll" filterCB={(data) => setFilters(data)} filters={[
            {name: "createdAt", friendlyName: "Date", type: "date"},
            {name: "ReferralEarnings", friendlyName: "Earned amount (per lead)", type: "number"},
            {name: "ReferralHasPaidOut", friendlyName: "Has paid out (money withdrawn)", type: "boolean"}
        ]} />
    })()}</div>
};

export default ReferralAdvertiser;