import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../modules/backendModule"; 

import Spinner from "../../../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropbox from "../../../../components/customComponents/DropBox";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";

const Postbacks = () => {
    const internalRef = React.useRef();
    const gTimeout = React.useRef();
    const [postbackData, setPostbackData] = React.useState();
    const [addPostback, setAddPostback] = React.useState(false);
    const [addPostbackSpinner, setAddPostbackSpinner] = React.useState(false);
    const [addPostbackError, setAddPostbackError] = React.useState("");
    const [showHelp, setShowHelp] = React.useState(false);

    const postbackNameRef = React.useRef();
    const postbackURLRef = React.useRef();
    const postbackTypeBox = CustomDropbox();
    const postbackEvent = CustomDropbox();

    const curTheme = useSelector(state => state?.userData?.userData?.UserInfo?.Flags?.theme);
    const userData = useSelector((state) => state.userData);

    const addPostbackFn = () => {
        setAddPostbackError("");
        if (!postbackNameRef.current?.value) {
            setAddPostbackError("Postback name missing");
            return;
        };
        if (!postbackURLRef.current.value) {
            setAddPostbackError("Postback URL missing");
            return;
        };
        
        let name = postbackNameRef.current.value;
        let url = postbackURLRef.current.value;
        let type = postbackTypeBox.getSelectedItem().value;
        let event = postbackEvent.getSelectedItem().value;

        setAddPostbackSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback/addPostback`,
            data: {
                postbackName: name,
                postbackURL: url,
                postbackType: type,
                postbackTrigger: event
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "error") {
                setAddPostbackError("There was an error while adding postback, check your info and try again");
            } else {
                postbackNameRef.current.value = "";
                postbackURLRef.current.value = "";
                setAddPostback(false);
                getPostbacks();
            };
        }).catch(() => {
            setAddPostbackError("Server timed out!");
        }).finally(() => {
            setAddPostbackSpinner(false);
        });
    };

    const getPostbacks = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/postback/getAllPostbacks`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPostbackData({ status: "ok", data: res.data.data, timestamp: Date.now() });
            } else {
                setPostbackData({ status: "error", data: "There was an error while getting postbacks" });
            };
        }).catch(() => {
            setPostbackData({ status: "error", data: "Server timed out!" });
        });
    };

    const EditPostback = (props) => {
        const [spinner, setSpinner] = React.useState(false);
        const [error, setError] = React.useState("");

        const addPostbackNameRef = React.useRef();
        const addPostbackURLRef = React.useRef();
        const addPostbackTypeBox = CustomDropbox();
        const addPostbackEvent = CustomDropbox();

        const verifyData = () => {
            setError("");
            if (!addPostbackNameRef.current?.value) {
                setError("Postback name missing");
                return;
            };
            if (!addPostbackURLRef.current.value) {
                setError("Postback URL missing");
                return;
            };
            
            let name = addPostbackNameRef.current.value;
            let url = addPostbackURLRef.current.value;
            let type = addPostbackTypeBox.getSelectedItem().value;
            let event = addPostbackEvent.getSelectedItem().value;

            setSpinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/postback/editPostback`,
                data: {
                    postbackID: props.id,
                    postbackName: name,
                    postbackURL: url,
                    postbackType: type,
                    postbackTrigger: event
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === "error") {
                    setAddPostbackError("There was an error while adding postback, check your info and try again");
                } else {
                    postbackNameRef.current.value = "";
                    postbackURLRef.current.value = "";
                    if (props.onClose) props.onClose().then(() => {
                        getPostbacks();
                    });
                };
            }).catch(() => {
                setAddPostbackError("Server timed out!");
            }).finally(() => {
                setSpinner(false);
            });
        };

        return <div className={`dashboard__postbacks__addPostback dashboard__postbacks__addPostback--active`} style={{overflow: "initial"}}>
            {spinner && <Spinner />}
            <p>Postback name: <CustomInput ref={addPostbackNameRef} placeholder1="Postback name" value={props.data.postbackName} /></p>
            <p>Postback URL: <CustomInput ref={addPostbackURLRef} placeholder1="Postback URL" value={props.data.postbackURL} /></p>
            <p className="dashboard__postbacks__addPostback__dropbox">Postback type: <addPostbackTypeBox.DropBox data={[
                {name: "Default", value: "default"}
            ]} selected={(()=>{
                switch (props.data.postbackType) {
                    case "default": return 0;
                    default: return 0;
                };
            })()} /></p>
            <p className="dashboard__postbacks__addPostback__dropbox">Postback trigger: <addPostbackEvent.DropBox data={[
                {value: "Any", name: "On any (All)"},
                {value: "AnyPublisher", name: "On any (Publisher offers)"},
                {value: "AnyAdvertiser", name: "On any (Advertiser offers)"},

                {value: "OnCreate", name: "On lead created (All)"},
                {value: "OnCreatePublisher", name: "On lead created (Publisher offers)"},
                {value: "OnCreateAdvertiser", name: "On lead created (Advertiser offers)"},

                {value: "OnApproved", name: "On lead approved"},
                {value: "OnApprovedPublisher", name: "On lead approved (Publisher offers)"},
                {value: "OnApprovedAdvertiser", name: "On lead approved (Advertiser offers)"},

                {value: "OnDeclined", name: "On lead declined (All)"},
                {value: "OnDeclinedPublisher", name: "On lead declined (Publisher offers)"},
                {value: "OnDeclinedAdvertiser", name: "On lead declined (Advertiser offers)"}
            ]} selected={(()=>{
                switch (props.data.postbackTrigger) {
                    case "Any": return 0;
                    case "AnyPublisher": return 1;
                    case "AnyAdvertiser": return 2;

                    case "OnCreate": return 3;
                    case "OnCreatePublisher": return 4;
                    case "OnCreateAdvertiser": return 5;

                    case "OnApproved": return 6;
                    case "OnApprovedPublisher": return 7;
                    case "OnApprovedAdvertiser": return 8;
                    
                    case "OnDeclined": return 9;
                    case "OnDeclinedPublisher": return 10;
                    case "OnDeclinedAdvertiser": return 11;
                    default: return 0;
                };
            })()} /></p>
            <CustomButtonSmall value="Save" className="dashboard__postbacks__addPostback__addBtn" onClick={verifyData} />
            <CustomButtonSmall value="Cancel" onClick={props.onClose} />
            {error && <p className="dashboard__postbacks__addPostback__error">{error}</p>}
        </div>
    };

    const DeletePostback = (props) => {
        const [spinner, setSpinner] = React.useState(false);
        const [error, setError] = React.useState("");

        const verifyData = () => {
            setError("");
            setSpinner(true);
            
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/postback/deletePostback`,
                data: {
                    postbackID: props.id
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === "error") {
                    setError("There was an error while deleting postback.");
                } else {
                    if (props.onClose) props.onClose().then(() => {
                        getPostbacks();
                    });
                };
            }).catch(() => {
                setError("Server timed out!");
            }).finally(() => {
                setSpinner(false);
            });
        };
        
        return <div className={`dashboard__postbacks__addPostback dashboard__postbacks__addPostback--active`} style={{overflow: "initial"}}>
            {spinner && <Spinner />}
            <p>This will permanently delete the postback</p>
            <p style={{marginBottom: "20px"}}>Are you sure?</p>
            <CustomButtonSmall value="Delete" className="dashboard__postbacks__addPostback__addBtn" onClick={verifyData} />
            <CustomButtonSmall value="Cancel" onClick={props.onClose} />
            {error && <p className="dashboard__postbacks__addPostback__error">{error}</p>}
        </div>
    }

    React.useEffect(() => {
        getPostbacks();
    }, []);

    React.useEffect(() => {
        if (!internalRef.current) return;
        clearTimeout(gTimeout.current);
        let curDiv = internalRef.current.querySelector(".dashboard__postbacks__addPostback");
        if (addPostback) {
            gTimeout.current = setTimeout(() => {
                curDiv.style.overflow = "initial";
            }, 200);
        } else {
            gTimeout.current = setTimeout(() => {
                curDiv.style.overflow = "hidden";
            }, 0);
        };
    }, [addPostback]);

    if (userData.userData?.UserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };

    return <div className="dashboard__postbacks" ref={internalRef}>
        <button className="domains__button" onClick={() => setAddPostback(!addPostback)} >Add postback <img src="/images/plusImageWhite.png" /></button>
        <button className="domains__button" onClick={() => setShowHelp(!showHelp)} >Show help <span style={{border: `1px solid ${curTheme === "dark" ? "white" : "black"}`, borderRadius: "50%", width: "20px", height: "20px", marginLeft: "10px"}}>?</span></button>
        <div className={`dashboard__postbacks__addPostback ${addPostback ? "dashboard__postbacks__addPostback--active" : ""}`}>
            {addPostbackSpinner && <Spinner />}
            <p>Postback name: <CustomInput ref={postbackNameRef} placeholder1="Postback name" /></p>
            <p>Postback URL: <CustomInput ref={postbackURLRef} placeholder1="Postback URL" /></p>
            <p className="dashboard__postbacks__addPostback__dropbox">Postback type: <postbackTypeBox.DropBox data={[
                {name: "Default", value: "default"}
            ]} selected={0} /></p>
            <p className="dashboard__postbacks__addPostback__dropbox">Postback trigger: <postbackEvent.DropBox data={[
                {value: "Any", name: "On any (All)"},
                {value: "AnyPublisher", name: "On any (Publisher offers)"},
                {value: "AnyAdvertiser", name: "On any (Advertiser offers)"},

                {value: "OnCreate", name: "On lead created (All)"},
                {value: "OnCreatePublisher", name: "On lead created (Publisher offers)"},
                {value: "OnCreateAdvertiser", name: "On lead created (Advertiser offers)"},

                {value: "OnApproved", name: "On lead approved"},
                {value: "OnApprovedPublisher", name: "On lead approved (Publisher offers)"},
                {value: "OnApprovedAdvertiser", name: "On lead approved (Advertiser offers)"},

                {value: "OnDeclined", name: "On lead declined (All)"},
                {value: "OnDeclinedPublisher", name: "On lead declined (Publisher offers)"},
                {value: "OnDeclinedAdvertiser", name: "On lead declined (Advertiser offers)"}
            ]} selected={0} /></p>
            <CustomButtonSmall value="Save" className="dashboard__postbacks__addPostback__addBtn" onClick={addPostbackFn} />
            <CustomButtonSmall value="Cancel" onClick={() => setAddPostback(false)} />
            {addPostbackError && <p className="dashboard__postbacks__addPostback__error">{addPostbackError}</p>}
        </div>
        <div className={`dashboard__postbacks__usageInfo ${showHelp ? "dashboard__postbacks__usageInfo--active" : ""}`}>
            <p>Postbacks will notify your server when a lead is added or changed</p>
            <p>Parameters are created by using <span className="hint">{"{ }"}</span> characters</p>
            <p>Example:</p>
            <p>https://your-postback-domain.com/postbackLocation?status=<span className="hint">{"{lead_status}"}</span>&amp;leadID=<span className="hint">{"{lead_id}"}</span></p>
            <br />
            <p>List of possible parameters:</p>
            <table>
                <tr>
                    <th>Code</th><th>Description</th>
                </tr>
                <tr><td>{"{lead_id}"}</td><td>ID of the lead</td></tr>
                <tr><td>{"{lead_status}"}</td><td>Status of the lead represented as a number (see table below)</td></tr>
                <tr><td>{"{lead_price}"}</td><td>Price of the lead (in USD)</td></tr>
                <tr><td>{"{offer_id}"}</td><td>ID of the offer</td></tr>
                <tr><td>{"{offer_name}"}</td><td>Name of the offer</td></tr>
                <tr><td>{"{product_name}"}</td><td>Name of the product</td></tr>
                <tr><td>{"{domain}"}</td><td>Domain from which the lead was received</td></tr>
                <tr><td>{"{lead_type}"}</td><td>Type of the lead (CPA, CPL...etc)</td></tr>
                <tr><td>{"{is_phone}"}</td><td>Is the lead comming from the mobile phone</td></tr>
                <tr><td>{"{scalelead-name}"}</td><td>Buyers name</td></tr>
                <tr><td>{"{scalelead-phone}"}</td><td>Buyers phone</td></tr>
                <tr><td>{"{scalelead-quantity}"}</td><td>Buyers quantity</td></tr>
                <tr><td>{"{scalelead-uid}"}</td><td><span className="hint">(Optional)</span> ID provided by other networks</td></tr>
                <tr><td>{"{scalelead-c1}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
                <tr><td>{"{scalelead-c2}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
                <tr><td>{"{scalelead-c3}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
                <tr><td>{"{scalelead-c4}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
                <tr><td>{"{scalelead-c5}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
                <tr><td>{"{scalelead-c6}"}</td><td><span className="hint">(Optional)</span> Field for custom data provided by other networks</td></tr>
            </table>

            <br />

            <p>List of lead status codes:</p>

            <table>
                <tr><th>Code</th><th>Description</th></tr>
                <tr><td>-2</td><td>Retrying... (failed to send lead)</td></tr>
                <tr><td>-1</td><td>Lead processing (Preparing the lead for CRM)</td></tr>
                <tr><td>0</td><td>Waiting (waiting for CRM to add lead)</td></tr>
                <tr><td>1</td><td>Lead in progress</td></tr>
                <tr><td>2</td><td>Lead confirmed</td></tr>
                <tr><td>3</td><td>Lead rejected</td></tr>
                <tr><td>4</td><td>Bad lead</td></tr>
                <tr><td>5</td><td>Unknown CRM error</td></tr>
                <tr><td>6</td><td>Processing lead</td></tr>
            </table>
        </div>

        {(()=>{
            if (!postbackData) return <Spinner />
            if (postbackData.status === "error") {
                return <p>{postbackData.data}</p>
            } else {
                return <>
                <FilteredCustomTable headers={["Name", "Type", "Trigger"]} data={(()=>{
                    let tmpData = postbackData.data.map(item => {
                        return [
                            {keyID: item.postbackID, type: "text", text: item.postbackName},
                            {keyID: item.postbackID, type: "text", text: item.postbackType},
                            {keyID: item.postbackID, type: "text", text: item.postbackTrigger},
                            {keyID: item.postbackID, type: "groupNewline", group: [
                                {keyID: item.postbackID, type: "button", text: "Edit", triggerDropdown: true, triggerData: (c) => {
                                    return <EditPostback id={item.postbackID} data={item} onClose={() => c()} />
                                }},
                                {keyID: item.postbackID, type: "button", text: "Delete", triggerDropdown: true, triggerData: (c) => {
                                    return <DeletePostback id={item.postbackID} onClose={() => c()} />
                                }}
                            ]}
                        ];
                    });
                    if (tmpData.length === 0) tmpData.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])
                    return tmpData;
                })()} />
                </>
            };
        })()}
    </div>
};

export default Postbacks;