import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as backendModule from "../../../../modules/backendModule";
import animateModule from "../../../../modules/animateModule"

import { FilteredCustomTable } from "../../../../components/customComponents/Table";

const PublisherStats = () => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const [offers, setOffers] = React.useState();

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".dashboard"));

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getLeadStatsPerUser`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(stats => {
            if (stats.data.status === "ok") {
                return setData({status: "ok", data: stats.data.data});
            };
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getUsers = () => {
        setUsers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUsers({
                    status: "ok", data: res.data.data.DBData
                });
            } else {
                setUsers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setUsers({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getOffers = () => {
        setOffers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers({
                    status: "ok", data: res.data.data.DBData
                });
            } else {
                setOffers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setOffers({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    React.useEffect(() => {
        getUsers();
        getOffers();
    }, []);

    return <div className="rotue__adminPanel__publisherStats">
        <FilteredCustomTable
            filterCB={fi => setFilters(fi)}
            filters={[
                {name: "BoundUser", friendlyName: "User", type: "string"},
                {name: "BoundOffer", friendlyName: "Offer", type: "custom", varType: "string", data: (Array.isArray(offers?.data) ? offers?.data : []).map(offer => {
                    return {text: `${offer.OfferName} (${offer.OfferProductName})`, value: offer.ID}
                })},
                {name: "OfferName", friendlyName: "Offer name", type: "string"},
                {name: "ProductName", friendlyName: "Product name", type: "string"},
                {name: "HasPaidOut", friendlyName: "Is lead Paid out", type: "boolean"},
                {name: "createdAt", friendlyName: "Date created", type: "date"},
                {name: "updatedAt", friendlyName: "Date updated", type: "date"}
            ]}
            headers={["Username", "Total leads", "Confirmed leads", 'Ammount for payment']}
            data={(()=>{
                if (!data || !users || !offers) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "data-error", type: "custom", data: <p>There was an error while getting data!</p>}]];
                if (users.status === "error") return [[{keyID: "data-error", type: "custom", data: <p>There was an error while getting users!</p>}]];
                if (offers.status === "error") return [[{keyID: "data-error", type: "custom", data: <p>There was an error while getting offers!</p>}]];

                if (data.data.length === 0) return [[{keyID: "noData-none", type: "custom", data: <p>No data to show.</p>}]];

                return data.data.map(stat => {
                    return [
                        {keyID: stat.BoundUser, type: "text", text: stat.User.Username},
                        {keyID: stat.BoundUser, type: "text", text: stat.Total},
                        {keyID: stat.BoundUser, type: "text", text: `${stat.Good} (${stat.PercentGood} %)`},
                        {keyID: stat.BoundUser, type: "text", text: `${stat.LeadEarnings}`},
                        {keyID: stat.BoundUser, type: "groupNewline", group: [
                            {keyID: stat.BoundUser, type: "button", text: "View dashboard", onClick: () => {
                                animateNavigate(`/dashboard/admin-dashboard?p=${stat.BoundUser}`);
                            }}
                        ]}
                    ];
                });
            })()}
        />
    </div>
};

export default PublisherStats;